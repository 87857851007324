import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA
import { sendContactForm } from "../utils/apiServices"; // Import the service
import useBackToTop from "../utils/useBackToTop";

const Contact = () => {
  useBackToTop(500, true);
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    captchaToken: "", // Add captchaToken to state
    site: "", // Add site as optional field
  });
  // Ref for reCAPTCHA
  const recaptchaRef = useRef(null);

  // State to handle the form submission status
  const [formStatus, setFormStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Add isSubmitting state

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle reCAPTCHA change
  const handleCaptchaChange = (token) => {
    setFormData({
      ...formData,
      captchaToken: token, // Update state with captcha token
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    if (!formData.captchaToken) {
      setFormStatus("Please complete the CAPTCHA.");
      return;
    }
  
    setIsSubmitting(true); // Set loading state to true
    setFormStatus(""); // Clear previous status messages
  
    try {
      const response = await sendContactForm(formData);
      setFormStatus("Message sent successfully!");
  
      // Trigger Google Ads conversion tracking event
      window.gtag("event", "ads_conversion_Submit_lead_form_1", {
        // Add any additional parameters if required
      });
  
      // Reset form data after successful submission
      setFormData({
        name: "",
        email: "",
        message: "",
        captchaToken: "",
        site: "",
      });
  
      // Safely reset reCAPTCHA to require fresh token for next submission
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } catch (error) {
      setFormStatus(
        "There was an issue sending your message. Please try again."
      );
      console.error("Error during form submission:", error);
    } finally {
      setIsSubmitting(false); // Reset loading state after submission
    }
  };
  

  return (
    <div>
      <Helmet>
        <title>Contact - TORIS Technologies</title>
        <meta
          name="description"
          content="Get in touch with TORIS Technologies for all your custom software and digital solution needs. We are here to answer your questions and assist you with our services."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies contact, software solutions, custom software, web development, technology services, get in touch"
        />
        <link rel="canonical" href="https://toris.co.za/contact" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Contact - TORIS Technologies" />
        <meta
          property="og:description"
          content="Get in touch with TORIS Technologies for all your custom software and digital solution needs. We are here to answer your questions and assist you with our services."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/toris-contact-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/contact" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Get in touch with TORIS Technologies for all your custom software and digital solution needs. We are here to answer your questions and assist you with our services."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/toris-contact-preview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Contact</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="contact-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-left">
                  <div className="ot-heading">
                    <span>contact details</span>
                    <h2 className="main-heading">Contact us</h2>
                  </div>
                  <div className="space-5"></div>
                  <p>
                    Give us a call or drop by anytime, we endeavour to answer
                    all enquiries within 24 hours on business days. We will be
                    happy to answer your questions.
                  </p>
                  <div className="contact-info box-style1">
                    <i className="flaticon-world-globe"></i>
                    <div className="info-text">
                      <h6>Our Address:</h6>
                      <p>8 Jonker Street, Alberton, South Africa</p>
                    </div>
                  </div>
                  <div className="contact-info box-style1">
                    <i className="flaticon-envelope"></i>
                    <div className="info-text">
                      <h6>Our Mailbox:</h6>
                      <p>info@toris.co.za</p>
                    </div>
                  </div>
                  <div className="contact-info box-style1">
                    <i className="flaticon-phone-1"></i>
                    <div className="info-text">
                      <h6>Our Phone:</h6>
                      <p>+27 81 297 6016</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <form
                  id="ajax-form"
                  name="ajax-form"
                  className="wpcf7"
                  onSubmit={handleSubmit} // Add submit handler
                >
                  <div className="main-form">
                    <h2>Ready to Get Started?</h2>
                    <p className="font14">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <p>
                      <label htmlFor="name">
                        <span className="error" id="err-name">
                          please enter name
                        </span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name} // Controlled input
                        onChange={handleInputChange} // Handle input change
                        size="40"
                        placeholder="Your Name *"
                        required=""
                      />
                    </p>
                    <p>
                      <label htmlFor="email">
                        <span className="error" id="err-email">
                          please enter e-mail
                        </span>
                        <span className="error" id="err-emailvld">
                          e-mail is not a valid format
                        </span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email} // Controlled input
                        onChange={handleInputChange} // Handle input change
                        size="40"
                        placeholder="Your Email *"
                        required=""
                      />
                    </p>
                    <p>
                      <label htmlFor="site"></label>
                      <input
                        type="text"
                        name="site"
                        value={formData.site} // Controlled input for site
                        onChange={handleInputChange} // Handle input change
                        size="40"
                        placeholder="Your Site (Optional)"
                      />
                    </p>
                    <p>
                      <label htmlFor="message"></label>
                      <textarea
                        name="message"
                        value={formData.message} // Controlled textarea
                        onChange={handleInputChange} // Handle input change
                        cols="40"
                        rows="10"
                        placeholder="Message..."
                        required=""
                      ></textarea>
                    </p>

                    {/* Google reCAPTCHA */}
                    <ReCAPTCHA
                      sitekey="6LcvFEAqAAAAADhYksuiu-WC50qNV6Tz32LvkWxm" // Replace with your Google reCAPTCHA site key
                      onChange={handleCaptchaChange} // Handle CAPTCHA change
                    />

                    <p>
                      <button
                        type="submit"
                        id="send"
                        className="octf-btn octf-btn-light"
                        disabled={isSubmitting} // Disable button when submitting
                      >
                        {isSubmitting ? "Processing..." : "Send Message"}{" "}
                        {/* Toggle button text */}
                      </button>
                    </p>
                    <div className="clear"></div>
                    {formStatus && <p>{formStatus}</p>}
                    <div className="error" id="err-form">
                      There was a problem validating the form please check!
                    </div>
                    <div className="error" id="err-timedout">
                      The connection to the server timed out!
                    </div>
                    <div className="error" id="err-state"></div>
                  </div>
                </form>
                <div className="clear"></div>
                <div id="ajaxsuccess">Successfully sent!!</div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="no-padding">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.4526188665623!2d28.144665899999996!3d-26.2794232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e951a919d283439%3A0x46229e6ac429db3e!2s8%20Jonker%20St%2C%20Verwoerdpark%2C%20Alberton%2C%201449%2C%20South%20Africa!5e0!3m2!1sen!2sth!4v1715709613163!5m2!1sen!2sth"
              height="500"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Location map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { fetchBlogs, fetchBlogCategories } from "../../utils/blogService";
import "./BlogSidebar.css";

const BlogSidebar = ({ onFilterByCategory, scrollToTop }) => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const loadRecentPosts = async () => {
      try {
        const data = await fetchBlogs(1, 5);
        setRecentPosts(data.blogs);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    const loadCategories = async () => {
      try {
        const data = await fetchBlogCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    loadRecentPosts();
    loadCategories();
  }, []);

  const handleCategoryClick = (category) => {
    onFilterByCategory(category);
    scrollToTop();
  };

  return (
    <div className="widget-area primary-sidebar col-lg-3 col-md-12 col-sm-12 col-xs-12">
      <aside className="widget widget_recent_posts">
        <h3 className="widget-title">Most Recent Posts</h3>
        <ul className="recent-posts-list">
          {recentPosts.map((post) => (
            <li key={post.id}>
              <Link to={`/post/${post.id}`}>{post.title}</Link>
              <p className="recent-post-date">
                {new Date(post.post_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </li>
          ))}
        </ul>
      </aside>

      <aside className="widget widget_categories">
        <h3 className="widget-title">Categories</h3>
        <ul className="categories-list">
          {/* All Categories Button */}
          <li className="sidebar-menu-item">
            <button
              className="sidebar-link"
              onClick={() => onFilterByCategory("All Categories")}
            >
              All Categories
            </button>
          </li>
          {categories.map((category) => (
            <li className="sidebar-menu-item" key={category.id}>
              <button
                className="sidebar-link"
                onClick={() => handleCategoryClick(category.name)}
              >
                {category.name}
              </button>
            </li>
          ))}
        </ul>
      </aside>
      {location.pathname !== "/blog" && (
        <div className="back-to-blog">
          <Link to="/blog" className="btn btn-primary">
            Back to Blog
          </Link>
        </div>
      )}
    </div>
  );
};

export default BlogSidebar;

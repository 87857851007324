import React, { useState } from "react";
import "./SectionFAQAccordions.css";

// Split the FAQ data into two separate arrays
const leftFaqData = [
  {
    question: "What types of web applications do you develop?",
    answer:
      "We specialize in building custom web applications tailored to enhance business processes, both internal and external. These applications include CRM systems, task management tools, and more.",
  },
  {
    question: "How can a custom web application benefit my business?",
    answer:
      "A custom web application improves efficiency by automating tasks, simplifying workflows, and offering tailored solutions that align with your business needs, saving time and reducing costs.",
  },
  {
    question: "What industries do you serve?",
    answer:
      "We cater to various industries, including healthcare, finance, logistics, education, and more. Our applications address the unique challenges of each industry while boosting productivity and user satisfaction.",
  },
  {
    question: "Do you provide ongoing support for web applications?",
    answer:
      "Yes, we offer comprehensive support and maintenance services for all web applications we develop. This ensures your system runs smoothly and remains up-to-date with the latest features and security updates.",
  },
  {
    question: "Can you help with application migration?",
    answer:
      "Absolutely! We assist with migrating legacy systems to modern, web-based applications, ensuring data integrity, improved performance, and a seamless transition for users.",
  },
];

const rightFaqData = [
  {
    question: "Do you build static business websites as well?",
    answer:
      "Yes, while our primary focus is on web applications, we also create static business websites to enhance your online presence and improve search engine rankings. These sites are optimized for user engagement and SEO.",
  },
  {
    question:
      "Can you integrate third-party applications with our existing systems?",
    answer:
      "Yes, we offer third-party integration services, provided the external system has the necessary API structure. We ensure seamless integration with your existing processes.",
  },
  {
    question: "How do you ensure the scalability of web applications?",
    answer:
      "We design web applications with scalability in mind, using modular code, efficient database management, and cloud-based architecture to ensure your application can grow with your business demands.",
  },
  {
    question: "What is your approach to project development?",
    answer:
      "We follow a collaborative development process, starting with a thorough analysis of your requirements, followed by design, development, testing, and deployment. We involve you in every step to ensure the final product meets your expectations.",
  },
  {
    question: "How can I start a project with you?",
    answer:
      "Starting a project with us is simple. Contact us for a consultation, where we discuss your requirements, scope, and timeline. We then provide you with a tailored proposal to kickstart the project.",
  },
];

const FaqAccordion = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="ot-accordions">
      {faqData.map((item, index) => (
        <div key={index} className="acc-item">
          <div
            className={`acc-toggle ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="acc-question"><em>{item.question}</em></div>
            <div className="acc-icon">
              {activeIndex === index ? "▲" : "▼"}
            </div>
          </div>
          <div
            className={`acc-content ${activeIndex === index ? "open" : ""}`}
            style={{ display: activeIndex === index ? "block" : "none" }}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const SectionFAQAccordions = () => {
  return (
    <div className="faq-columns-container">
      <div className="faq-column">
        <FaqAccordion faqData={leftFaqData} />
      </div>
      <div className="faq-column">
        <FaqAccordion faqData={rightFaqData} />
      </div>
    </div>
  );
};

export default SectionFAQAccordions;

import React, { useState, useEffect } from 'react';
import {
  fetchSubscribers,
  addSubscriber,
  updateSubscriber,
  deleteSubscriber
} from '../../utils/subscriberService';
import './AdminManageSubscribers.css';

const AdminManageSubscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    segment: 'newsletter'
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  // Load subscribers on component mount
  useEffect(() => {
    const loadSubscribers = async () => {
      try {
        const data = await fetchSubscribers();
        setSubscribers(data);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      }
    };

    loadSubscribers();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submit for adding/updating
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEdit) {
        await updateSubscriber(editId, formData);
      } else {
        await addSubscriber(formData);
      }

      setFormData({ firstName: '', lastName: '', email: '', segment: 'newsletter' });
      setIsEdit(false);
      setEditId(null);

      // Reload subscribers after action
      const updatedSubscribers = await fetchSubscribers();
      setSubscribers(updatedSubscribers);
    } catch (error) {
      console.error('Error adding/updating subscriber:', error);
    }
  };

  // Edit subscriber
  const handleEditClick = (subscriber) => {
    setFormData(subscriber);
    setIsEdit(true);
    setEditId(subscriber.id);
  };

  // Delete subscriber
  const handleDeleteClick = async (id) => {
    try {
      await deleteSubscriber(id);

      // Reload subscribers after deletion
      const updatedSubscribers = await fetchSubscribers();
      setSubscribers(updatedSubscribers);
    } catch (error) {
      console.error('Error deleting subscriber:', error);
    }
  };

  return (
    <div className="admin-manage-subscribers">
      <h2>Manage Subscribers</h2>

      {/* Subscriber Form */}
      <form onSubmit={handleFormSubmit} className="subscriber-form">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder="First Name"
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Last Name"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email"
          required
        />
        <select
          name="segment"
          value={formData.segment}
          onChange={handleInputChange}
        >
          <option value="newsletter">Newsletter</option>
          <option value="event">Event</option>
          <option value="member">Member</option>
        </select>
        <button type="submit">{isEdit ? 'Update' : 'Add'} Subscriber</button>
      </form>

      {/* Subscriber Table */}
      <table className="subscriber-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Segment</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscribers.length > 0 ? (
            subscribers.map((subscriber) => (
              <tr key={subscriber.id}>
                <td>{subscriber.firstName}</td>
                <td>{subscriber.lastName}</td>
                <td>{subscriber.email}</td>
                <td>{subscriber.segment}</td>
                <td>
                  <button onClick={() => handleEditClick(subscriber)} className="edit-btn">
                    Edit
                  </button>
                  <button onClick={() => handleDeleteClick(subscriber.id)} className="delete-btn">
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No subscribers found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminManageSubscribers;

// File: ProfileComponents/Profile.js
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import ProfileSidebar from "../components/ProfileComponents/ProfileSidebar/ProfileSidebar";
import PurchasedProducts from "../components/ProfileComponents/PurchasedProducts/PurchasedProducts";
import AccountInfo from "../components/ProfileComponents/AccountInfo/AccountInfo";
import "./Profile.css";
import useBackToTop from "../utils/useBackToTop";

const Profile = () => {
  useBackToTop(500, true);
  const [selectedComponent, setSelectedComponent] =
    useState("purchasedProducts");
  const userId = sessionStorage.getItem("userId");

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "purchasedProducts":
        return <PurchasedProducts userId={userId} />;
      case "accountInfo":
        return <AccountInfo userId={userId} />;
      default:
        return <PurchasedProducts userId={userId} />;
    }
  };

  return (
    <div className="profile-page">
      <Helmet>
        <title>User Profile | TORIS Technologies</title>
        <meta
          name="description"
          content="Access and manage your account information, view purchased products, and update profile settings at TORIS Technologies."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, user profile, account management, purchased products, account settings"
        />
        <link rel="canonical" href="https://toris.co.za/profile" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="User Profile | TORIS Technologies" />
        <meta
          property="og:description"
          content="Manage your account details and purchased products conveniently on the TORIS Technologies user profile page."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/profile/profile-og-image.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/profile" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="User Profile | TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="View and manage your TORIS Technologies account information, including purchased products and account settings."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/profile/profile-twitter-image.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <ProfileSidebar setSelectedComponent={setSelectedComponent} />
      <div className="profile-content">{renderSelectedComponent()}</div>
    </div>
  );
};

export default Profile;

// File: services/downloadService.js
import axios from "./axiosConfig"; 

export const handleDownloadClick = async (orderId, productId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DOWNLOAD_URL}`,
      { orderId, productId },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    const { downloadToken } = response.data;

    // Redirect to download URL with the token
    window.location.href = `${process.env.REACT_APP_API_URL}/downloads/download/${downloadToken}`;
  } catch (error) {
    console.error("Error generating download link:", error);
    alert("Download could not be completed. Please try again.");
  }
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Sidebar.css";
import { useCart } from "../../context/CartContext";
import { fetchProducts } from "../../utils/productService";
import { useExchangeRate } from "../../context/ExchangeRateContext";
import { FaTrash } from "react-icons/fa";

const Sidebar = ({
  onSearch,
  onCategorySelect,
  onTagSelect,
  onPriceFilter,
  products,
  sortOption, // New prop for current sorting option
  onSortChange, // New prop for sorting handler
  onClearFilter, // New prop for clearing filters
}) => {
  const { cart, removeFromCart } = useCart();
  const { exchangeRates, currencyCode } = useExchangeRate();
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);
  const [fullCart, setFullCart] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Map currency codes to symbols
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ZAR: "R",
    AUD: "A$",
    CAD: "C$",
    JPY: "¥",
    CNY: "¥",
    CHF: "Fr",
    NZD: "$",
    SGD: "S$",
    HKD: "HK$",
    INR: "₹",
    BRL: "R$",
    MXN: "$",
    SEK: "kr",
    NOK: "kr",
    RUB: "₽",
    KRW: "₩",
    PLN: "zł",
    TRY: "₺",
    THB: "฿",
    IDR: "Rp",
    MYR: "RM",
    PHP: "₱",
    DKK: "kr",
    HUF: "Ft",
    CZK: "Kč",
    ILS: "₪",
    AED: "د.إ",
    SAR: "﷼",
    CLP: "$",
    COP: "$",
    PEN: "S/.",
    PKR: "₨",
    VND: "₫",
    BDT: "৳",
    NGN: "₦",
    EGP: "£",
    QAR: "﷼",
    KWD: "د.ك",
  };

  // Helper function to get currency symbol
  const getCurrencySymbol = (code) => {
    if (currencySymbols[code]) {
      return currencySymbols[code];
    }
    try {
      return new Intl.NumberFormat("en", { style: "currency", currency: code })
        .formatToParts(1)
        .find((part) => part.type === "currency").value;
    } catch (error) {
      console.warn(`Symbol for ${code} not found. Defaulting to code.`);
      return code;
    }
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  // Function to convert ZAR to the selected currency
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    return (amountInZAR * conversionRate).toFixed(2);
  };

  useEffect(() => {
    if (products.length > 0) {
      const minPrice = Math.min(
        ...products.map((product) =>
          Math.max(product.salePrice || product.price, 0)
        )
      );
      const maxPrice = Math.max(
        ...products.map((product) =>
          Math.max(product.salePrice || product.price, 0)
        )
      );
      setPriceRange([minPrice, maxPrice]);

      const categories = [
        ...new Set(products.flatMap((product) => product.categories)),
      ];
      setUniqueCategories(categories);

      const tags = [...new Set(products.flatMap((product) => product.tags))];
      setUniqueTags(tags);
    }
  }, [products]);

  // Fetch product details whenever the cart changes
  useEffect(() => {
    const fetchFullCartDetails = async () => {
      if (cart.length > 0) {
        const productIds = cart.map((item) => item.productId);
        try {
          const products = await fetchProducts(productIds);

          const updatedCart = cart.map((item) => {
            const product = products.find((p) => p.id === item.productId);
            return {
              ...item,
              name: product?.name || "Unknown",
              price: product?.sale ? product.salePrice : product.price,
              image: product?.image || "",
              link: `/single-product/${product?.id || item.productId}`,
            };
          });

          setFullCart(updatedCart);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      } else {
        setFullCart([]);
      }
    };

    fetchFullCartDetails();
  }, [cart]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (onSearch) {
      onSearch(term);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  const handleCategorySelect = (category) => {
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  };

  const handleTagSelect = (tag) => {
    if (onTagSelect) {
      onTagSelect(tag);
    }
  };

  const handlePriceChange = (range) => {
    setPriceRange(range);
    if (onPriceFilter) {
      onPriceFilter({ min: range[0], max: range[1] });
    }
  };

  const cartTotal = fullCart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="widget-area primary-sidebar col-lg-3 col-md-12 col-sm-12 modern-sidebar">
      <aside id="search-2" className="widget widget_search">
        <h5 className="widget-title">Search</h5>
        <form
          role="search"
          method="get"
          id="search-form"
          className="search-form"
          onSubmit={handleSearchSubmit}
        >
          <input
            type="search"
            className="search-field"
            placeholder="Search…"
            value={searchTerm}
            name="s"
            onChange={handleSearchChange}
          />
          <button type="submit" className="search-submit">
            <i className="flaticon-search"></i>
          </button>
        </form>
      </aside>

      {/* Sorting Dropdown */}
      <aside className="widget">
        <h5 className="widget-title">Sort by</h5>
        <select
          name="orderby"
          className="orderby"
          aria-label="Shop order"
          onChange={onSortChange}
          value={sortOption}
        >
          <option value="menu_order">Default sorting</option>
          <option value="popularity">Sort by popularity</option>
          <option value="rating">Sort by average rating</option>
          <option value="date">Sort by latest</option>
          <option value="price">Sort by price: low to high</option>
          <option value="price-desc">Sort by price: high to low</option>
        </select>
      </aside>

      <aside className="widget widget_categories">
        <h5 className="widget-title">Categories</h5>
        <ul>
          {uniqueCategories.map((category, index) => (
            <li key={`category-${index}`}>
              <Link to="#" onClick={() => handleCategorySelect(category)}>
                {category}
              </Link>
            </li>
          ))}
        </ul>
      </aside>

      <aside className="widget woocommerce widget_price_filter">
        <h5 className="widget-title">Filter by price</h5>
        <form method="get">
          <div className="price_slider_wrapper">
            <div className="price_slider_label">
              <span className="price_slider_min">
                {currencySymbol} {convertToCurrency(priceRange[0])}
              </span>
              <span className="price_slider_max">
                {currencySymbol} {convertToCurrency(priceRange[1])}
              </span>
            </div>
            <Slider
              range
              min={Math.min(
                ...products.map((product) =>
                  Math.max(product.price, product.oldPrice || 0)
                )
              )}
              max={Math.max(
                ...products.map((product) =>
                  Math.max(product.price, product.oldPrice || 0)
                )
              )}
              value={priceRange}
              onChange={handlePriceChange}
            />
          </div>
        </form>
      </aside>

      <aside className="widget widget_tag_cloud">
        <h5 className="widget-title">Tags</h5>
        <div className="tagcloud">
          {uniqueTags.map((tag, index) => (
            <Link
              to="#"
              key={`tag-${index}`}
              onClick={() => handleTagSelect(tag)}
            >
              {tag}
            </Link>
          ))}
        </div>
      </aside>

      {/* Clear Search Button */}
      <aside>
        <button
          type="button"
          onClick={onClearFilter}
          className="clear-filter-btn"
        >
          Clear Search
        </button>
      </aside>

      <aside className="widget widget_products">
        <h5 className="widget-title">Popular</h5>
        <ul className="popular-product clearfix">
          {products
            .filter((product) => product.popular)
            .map((product) => (
              <li key={product.id} className="clearfix">
                <div className="thumb">
                  <Link to={product.link}>
                    <img src={product.image} alt={product.name} />
                  </Link>
                </div>
                <div className="entry-header">
                  <h6>
                    <Link to={product.link}>{product.name}</Link>
                  </h6>
                  <span className="price-product">
                    {product.sale === true ? (
                      <>
                        <del>
                          <span className="amount">
                            <span>{currencySymbol} </span>
                            {convertToCurrency(product.price)}
                          </span>
                        </del>
                        <ins>
                          <span className="amount">
                            <span>{currencySymbol} </span>
                            {convertToCurrency(product.salePrice)}
                          </span>
                        </ins>
                      </>
                    ) : (
                      <span className="amount">
                        <span>{currencySymbol} </span>
                        {convertToCurrency(product.price)}
                      </span>
                    )}
                  </span>
                </div>
              </li>
            ))}
        </ul>
      </aside>

      <aside className="widget woocommerce widget_shopping_cart">
        <h5 className="widget-title">Shopping Cart</h5>
        <div className="widget_shopping_cart_content">
          <ul className="cart_list">
            {fullCart.map((item) => (
              <li key={item.productId} className="clearfix">
                <button
                  onClick={() => removeFromCart(item.productId)}
                  className="remove remove_from_cart_button"
                >
                  <FaTrash className="trashcan-icon" />
                </button>
                <div className="thumb">
                  <Link to={item.link}>
                    <img src={item.image} alt={item.name} />
                  </Link>
                </div>
                <div className="entry-header">
                  <h6>
                    <Link to={item.link}>{item.name}</Link>
                  </h6>
                  <span className="price-product">
                    <span className="quantity">{item.quantity} × </span>
                    <span className="amount">
                      <span>{currencySymbol} </span>
                      {convertToCurrency(item.price)}
                    </span>
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <p className="woocommerce-mini-cart__total total">
            <strong>Subtotal:</strong>{" "}
            <span className="amount">
              <span>{currencySymbol} </span>
              {convertToCurrency(cartTotal)}
            </span>
          </p>
          <p className="woocommerce-mini-cart__buttons buttons">
            <Link
              to="/cart"
              className="octf-btn octf-btn-primary button wc-forward"
            >
              View cart
            </Link>
            <Link
              to="/checkout"
              className="octf-btn octf-btn-primary checkout wc-forward"
            >
              Checkout
            </Link>
          </p>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;

import React from "react";
import NewsLetterCTA from "../components/NewsletterCTA/NewsLetterCTA";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./About.css";
import SectionWhatWeActuallyDo from "../components/SectionWhatWeActuallyDo/SectionWhatWeActuallyDo";
import useBackToTop from "../utils/useBackToTop";

const About = () => {
  useBackToTop(500, true);
  return (
    <div id="content" className="site-content">
      <Helmet>
        <title>About Us - TORIS Technologies</title>
        <meta
          name="description"
          content="Learn more about TORIS Technologies, a trusted partner for custom software development, web applications, and technology innovation. We empower businesses through robust, scalable, and modern solutions."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="About TORIS Technologies, software development, custom software, technology innovation, web applications, mobile development"
        />
        <link rel="canonical" href="https://toris.co.za/about-us" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="About Us - TORIS Technologies" />
        <meta
          property="og:description"
          content="Learn more about TORIS Technologies, a trusted partner for custom software development, web applications, and technology innovation. We empower businesses through robust, scalable, and modern solutions."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/toris-about-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/about-us" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Learn more about TORIS Technologies, a trusted partner for custom software development, web applications, and technology innovation. We empower businesses through robust, scalable, and modern solutions."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/toris-about-preview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div className="page-header flex-middle">
        <div className="container">
          <div className="inner flex-middle">
            <h1 className="page-title">About Us</h1>
            <ul id="breadcrumbs" className="breadcrumbs none-style">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="about-offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center mb-30 mb-lg-0">
              <div className="ot-heading">
                <span>what we offer</span>
                <h2 className="main-heading">
                  Your Partner for <br /> Software Innovation
                </h2>
              </div>
              <div className="space-5"></div>
              <p>
                Toris Technologies is the partner of choice for many of the
                world’s leading enterprises, SMEs and technology challengers. We
                help businesses elevate their value through custom software
                development, product design, QA and consultancy services.
              </p>
              <p>
                <em className="text-dark">
                  <strong>
                    We can help to maintain and modernize your IT infrastructure
                    and solve various infrastructure-specific issues a business
                    may face.
                  </strong>
                </em>
              </p>
              <div className="space-20"></div>
              <div className="video-popup style-2">
                {/* Video should go here once some video content has been created */}
                {/* <div className="btn-inner">
                  <Link
                    className="btn-play"
                    to="https://www.youtube.com/watch?v=lfDZJqSrIuk"
                  >
                    <i className="flaticon-play"></i>
                    <span className="circle-1"></span>
                    <span className="circle-2"></span>
                  </Link>
                </div>
                <span>video showcase</span> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <Link className="ot-image-box v3 st1" to="#">
                    <div className="overlay">
                      <h4>Our Mission</h4>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/image-box1.jpg`}
                      alt="Our Mission"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6">
                  <Link className="ot-image-box v3 st2" to="#">
                    <div className="overlay">
                      <h4>Our Vision</h4>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/image-box2.jpg`}
                      alt="Our Vision"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6">
                  <Link className="ot-image-box v3 st3" to="#">
                    <div className="overlay">
                      <h4>Our Philosophy</h4>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/image-box3.jpg`}
                      alt="Our Philosophy"
                    />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6">
                  <Link className="ot-image-box v3 st4 mb-0" to="#">
                    <div className="overlay">
                      <h4>Our Strategy</h4>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/image-box4.jpg`}
                      alt="Our Strategy"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionWhatWeActuallyDo />

      <section className="about-counter">
        <div className="container">
          <div className="space-105"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="technology-v3-left">
                <div className="ot-heading">
                  <span>technology index</span>
                  <h2 className="main-heading">Web Development Journey</h2>
                </div>
                <div className="space-5"></div>
                <p>
                  The effort that goes into developing your application, from
                  start to finish!
                </p>
                <div className="space-10"></div>
                <div className="et-progress">
                  <div className="ot-progress">
                    <div className="overflow">
                      <span className="pname f-left">Planning</span>
                      <span className="ppercent f-right">30%</span>
                    </div>
                    <div className="iprogress">
                      <div className="progress-bar" data-percent="30%"></div>
                    </div>
                  </div>
                  <div className="ot-progress">
                    <div className="overflow">
                      <span className="pname f-left">Designing</span>
                      <span className="ppercent f-right">20%</span>
                    </div>
                    <div className="iprogress">
                      <div className="progress-bar" data-percent="20%"></div>
                    </div>
                  </div>
                  <div className="ot-progress">
                    <div className="overflow">
                      <span className="pname f-left">Development</span>
                      <span className="ppercent f-right">50%</span>
                    </div>
                    <div className="iprogress">
                      <div className="progress-bar" data-percent="50%"></div>
                    </div>
                  </div>
                  <div className="ot-progress">
                    <div className="overflow">
                      <span className="pname f-left">Satisfaction</span>
                      <span className="ppercent f-right">100%</span>
                    </div>
                    <div className="iprogress">
                      <div className="progress-bar" data-percent="100%"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/image2-about.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="newsletter-container">
        <NewsLetterCTA />
      </section>
    </div>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";
import "./SectionTechnologyIndex.css";

const SectionTechnologyIndex = () => {
  return (
    <div className="section-technology">
      <div className="container">
        <div className="row tech-box-section">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="technology-left">
              <div className="ot-heading">
                <span className="text-primary-light">technology index</span>
                <h2 className="main-heading text-white">
                  Improve and Innovate with the Tech Trends
                </h2>
              </div>
              <p>
                Our team can assist you in transforming your business through
                latest tech capabilities to stay ahead of the curve.
              </p>
              <ul className="style-none text-white">
                <li>
                  <i className="fas fa-check"></i> Application Development
                </li>
                <li>
                  <i className="fas fa-check"></i> Mobile App Development
                </li>
                <li>
                  <i className="fas fa-check"></i> 3rd Party App Integrations
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="tech-box-cards">
              <div className="tech-box-card">
                <Link
                  className="tech-box text-center"
                  to="/web-development"
                  target="_blank"
                >
                  <div className="icon-main">
                    <span className="flaticon-code-1"></span>
                  </div>
                  <h3 className="tech-box-title">WEB</h3>
                </Link>
              </div>
              <div className="tech-box-card">
                <Link className="tech-box text-center" to="/mobile-development">
                  <div className="icon-main">
                    <span className="flaticon-android"></span>
                  </div>
                  <h3 className="tech-box-title">Android</h3>
                </Link>
              </div>
              <div className="tech-box-card">
                <Link className="tech-box text-center" to="/mobile-development">
                  <div className="icon-main">
                    <span className="flaticon-apple"></span>
                  </div>
                  <h3 className="tech-box-title">IOS</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTechnologyIndex;

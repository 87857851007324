import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import './ProductTable.css';

const ProductTable = ({ products, onEdit, onDelete }) => {
  return (
    <div className="product-table-container">
      <table className="product-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>On Sale</th>
            <th>Popular</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <img src={product.image} alt={product.name} className="product-image" />
              </td>
              <td>{product.name}</td>
              <td>{product.sale ? 'Yes' : 'No'}</td>
              <td>{product.popular ? 'Yes' : 'No'}</td>
              <td>
                <FaEdit className="action-icon" onClick={() => onEdit(product.id)} />
              </td>
              <td>
                <FaTrash className="action-icon" onClick={() => onDelete(product.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;

import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";
import { useCart } from "../../context/CartContext";
import { useExchangeRate } from "../../context/ExchangeRateContext";

const ProductCard = ({ product, onAddToCartClick, isAuthenticated }) => {
  const { addToCart } = useCart();
  const { exchangeRates, currencyCode } = useExchangeRate();

  // Map currency codes to symbols
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ZAR: "R",
    AUD: "A$",
    CAD: "C$",
    JPY: "¥",
    CNY: "¥",
    CHF: "Fr",
    NZD: "$",
    SGD: "S$",
    HKD: "HK$",
    INR: "₹",
    BRL: "R$",
    MXN: "$",
    SEK: "kr",
    NOK: "kr",
    RUB: "₽",
    KRW: "₩",
    PLN: "zł",
    TRY: "₺",
    THB: "฿",
    IDR: "Rp",
    MYR: "RM",
    PHP: "₱",
    DKK: "kr",
    HUF: "Ft",
    CZK: "Kč",
    ILS: "₪",
    AED: "د.إ",
    SAR: "﷼",
    CLP: "$",
    COP: "$",
    PEN: "S/.",
    PKR: "₨",
    VND: "₫",
    BDT: "৳",
    NGN: "₦",
    EGP: "£",
    QAR: "﷼",
    KWD: "د.ك",
  };

  // Helper function to get currency symbol
  const getCurrencySymbol = (code) => {
    if (currencySymbols[code]) {
      return currencySymbols[code];
    }
    try {
      return new Intl.NumberFormat("en", { style: "currency", currency: code })
        .formatToParts(1)
        .find((part) => part.type === "currency").value;
    } catch (error) {
      console.warn(`Symbol for ${code} not found. Defaulting to code.`);
      return code;
    }
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  const handleAddToCart = () => {
    if (isAuthenticated) {
      addToCart({
        ...product,
        actualPrice: product.sale ? product.salePrice : product.price
      });
    } else {
      onAddToCartClick();
    }
  };
  
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    const amount = parseFloat(amountInZAR) || 0;

    return (amount * conversionRate).toFixed(2);
  };

  return (
    <div className="product-item modern-card">
      <div className="product-card-information">
        <div className="product-media">
          <Link to={`/single-product/${product.id}`}>
            {product.sale && <span className="onsale">Sale!</span>}
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
          </Link>
        </div>
        <h2 className="woocommerce-loop-product__title">
          <Link to={`/single-product/${product.id}`} className="product-title">
            {product.name}
          </Link>
        </h2>
        <p className="product-card-tag-heading">Tags</p>
        <span className="product-card-tags">{product.tags.join(", ")}</span>

        <div className="star-rating">
          {Array(product.rating)
            .fill()
            .map((_, i) => (
              <span key={i}>
                <i className="fa fa-star"></i>
              </span>
            ))}
        </div>
        <span className="price-product">
          {product.sale ? (
            <>
              <del>
                <span className="amount">
                  <span>{currencySymbol} </span>
                  {convertToCurrency(product.price)}
                </span>
              </del>
              <br />
              <ins>
                <span className="amount">
                  <span>{currencySymbol} </span>
                  {convertToCurrency(product.salePrice)}
                </span>
              </ins>
            </>
          ) : (
            <ins>
              <span className="amount">
                <span>{currencySymbol} </span>
                {convertToCurrency(product.price)}
              </span>
            </ins>
          )}
        </span>
      </div>
      <div className="wrapper-add-to-cart">
        <button onClick={handleAddToCart} className="add-to-cart-button">
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default ProductCard;

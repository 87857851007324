import React from "react";
import { Link } from "react-router-dom";
import { HomeBanner } from "../components/HomeBanner/HomeBanner";
import IndustriesSection from "../components/IndustriesSection/IndustriesSection";
import SectionTechnologyIndex from "../components/SectionTechnologyIndex/SectionTechnologyIndex";
import NewsLetterCTA from "../components/NewsletterCTA/NewsLetterCTA";
import { Helmet } from "react-helmet-async";
import "./Home.css";
import SectionProjects from "../components/SectionProjects/SectionProjects";
import CallToActionPricing from "../components/CallToActionPricing/CallToActionPricing";
import SectionConsultation from "../components/SectionConsultation/SectionConsultation";
import SectionServiceCards from "../components/SectionServiceCards/SectionServiceCards";
import useBackToTop from "../utils/useBackToTop";

const Home = () => {
  useBackToTop(500, true);
  return (
    <div>
      <Helmet>
        <title>Home - TORIS Technologies</title>
        <meta
          name="description"
          content="Welcome to TORIS Technologies, your destination for custom software and web applications. We transform businesses by delivering reliable and scalable solutions tailored to the needs of thousands of users."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, custom software, web development, business software, mobile apps"
        />
        <link rel="canonical" href="https://toris.co.za/" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Home - TORIS Technologies" />
        <meta
          property="og:description"
          content="Welcome to TORIS Technologies, your destination for custom software and web applications. We transform businesses by delivering reliable and scalable solutions tailored to the needs of thousands of users."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/toris-preview-image.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Welcome to TORIS Technologies, your destination for custom software and web applications. We transform businesses by delivering reliable and scalable solutions tailored to the needs of thousands of users."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/toris-preview-image.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      {/* Hero Banner */}
      <HomeBanner />

      {/* About Section */}
      <section className="about-section">
        <div className="container">
          <div className="section-inner-about">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12">
                <div className="left-img">
                  <picture>
                    <source
                      media="(max-width: 768px)"
                      srcSet={`${process.env.PUBLIC_URL}/assets/images/image1-home2-small.webp`}
                      width="488"
                      height="260"
                    />

                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/image1-home2.webp`}
                      alt="picture montage"
                      width="977"
                      height="521"
                    />
                  </picture>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12">
                <div className="right-content">
                  <div className="ot-heading">
                    <span>About Company</span>
                    <h2 className="main-heading">
                      Your Partner for <br />
                      Software Innovation
                    </h2>
                  </div>
                  <p>
                    TORIS Technologies is the partner of choice for many leading
                    enterprises, SMEs, and technology challengers. We help
                    businesses elevate their value through custom software
                    development, product design, QA, and consultancy services.
                  </p>
                  <p>
                    <em className="text-dark">
                      <strong>
                        We can help to maintain and modernize your digital
                        infrastructure and solve various process-specific issues
                        your business may face.
                      </strong>
                    </em>
                  </p>
                  <div className="space-50"></div>
                  <div className="learn-more-button">
                    <Link to="/about-us" className="about-learn-more">
                      MORE ABOUT OUR COMPANY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Service Cards */}
      <SectionServiceCards />

      {/* Pricing Call to Action */}
      <CallToActionPricing />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="ot-heading mb-0">
                <span>We transform businesses</span>
                <h2 className="main-heading">
                  For Over 7 Years. <br />
                  For Thousands of Users
                </h2>
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <p className="mb-0">
                The development of reliable and scalable software solutions for
                any OS, browser and device. We bring together deep industry
                expertise and the latest IT advancements to deliver custom
                solutions and products that perfectly fit the needs and behavior
                of their users.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Technology Index */}
      <SectionTechnologyIndex />

      <section className="section-case-study">
        <div className="container">
          <div className="cta-h2">
            <div className="row">
              <div className="col-lg-8 col-md-8 mb-4 mb-md-0">
                <div className="ot-heading">
                  <span>We Carry more Than Just Good Coding Skills</span>
                  <h2 className="main-heading">Let's Build Your Website!</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 align-self-end">
                <div className="ot-button text-center text-md-right">
                  <Link to="/contact" className="octf-btn octf-btn-primary">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="ot-heading mb-0">
                <span>Latest Projects</span>
                <h2 className="main-heading">Some of Our Projects</h2>
              </div>
            </div>
            <div className="col-lg-5 align-self-end">
              <p className="mb-0">
                We’ve excelled our experience in a wide range of industries to
                bring valuable insights and provide our customers.
              </p>
            </div>
            <div className="space-50"></div>
            <div className="col-lg-2 align-self-end">
              <div>
                <Link to="/projects" className="project-details-button">
                  VIEW ALL PROJECTS
                </Link>
              </div>
            </div>
          </div>
          <div className="row hide-projects">
            <SectionProjects />
          </div>
        </div>
      </section>

      {/* Industries Carosouel */}
      <IndustriesSection />

      {/* Consultation Section */}
      <SectionConsultation />

      <section className="newsletter-container">
        <NewsLetterCTA />
      </section>
    </div>
  );
};

export default Home;

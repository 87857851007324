import axios from "../utils/axiosConfig";

// Add item to the cart in the database
export const addItemToCartDB = async (userId, productId, quantity, price, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cart/add`,
      { userId, productId, quantity, price }, // Include price here
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data; // Return response data if successful
  } catch (error) {
    console.error("Error adding item to cart in the database:", error);
    return null; // Return null if there's an error
  }
};


// Remove item from the cart in the database
export const removeItemFromCartDB = async (userId, productId, token) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/cart/${productId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: { userId } // Include userId in the body for the backend to use
      }
    );
    return response.status === 200 || response.status === 204; // Return true if successful
  } catch (error) {
    console.error("Error removing item from the cart in the database:", error);
    return false; // Return false if there's an error
  }
};

// Update cart item quantity in the database
export const updateCartQuantityDB = async (userId, productId, quantity, token) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/cart/update`,
      { userId, productId, quantity },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.status === 200; // Return true if successful
  } catch (error) {
    console.error("Error updating cart item quantity in the database:", error);
    return false; // Return false if there's an error
  }
};

// Fetch cart from the database
export const fetchCartFromDB = async (userId, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cart/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data || []; // Return cart data or an empty array if no data
  } catch (error) {
    console.error("Error fetching cart from the database:", error);
    return []; // Return empty array if there's an error
  }
};

// Clear the entire cart in the database
export const clearCartDB = async (userId, token) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/cart/clear/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.status === 204; // Return true if successful
  } catch (error) {
    console.error("Error clearing the cart in the database:", error);
    return false; // Return false if there's an error
  }
};

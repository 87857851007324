import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchProjects } from "../../utils/projectsService";
import "./SectionProjects.css";

const SectionProjects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  // Load projects from the backend
  useEffect(() => {
    const loadProjects = async () => {
      try {
        const data = await fetchProjects();
        // Load only the first 9 projects
        setProjects(data.slice(0, 9));
      } catch (error) {
        console.error("Error loading projects:", error);
      }
    };

    loadProjects();
  }, []);

  // Function to handle project card click and navigate to details page
  const handleProjectClick = (projectId) => {
    navigate(`/project-details/${projectId}`);
  };

  return (
      <div className="projects-section-container">
        {/* Project Grid */}
        <div className="unique-projects-grid">
          {projects.map((project, index) => (
            <div
              key={index}
              className={`unique-project-card ${project.project_category.toLowerCase()}`}
              onClick={() => handleProjectClick(project.id)}
            >
              <div className="unique-projects-box">
                <div className="unique-projects-thumbnail">
                  <Link to={`/project-details/${project.id}`}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/${project.project_image}`}
                      alt={project.project_title}
                    />
                  </Link>
                </div>
                <div className="unique-portfolio-info">
                  <div className="unique-portfolio-info-inner">
                    <h3>
                      <Link to={`/project-details/${project.id}`}>
                        {project.project_title}
                      </Link>
                    </h3>
                    <p className="unique-portfolio-cates">
                      <Link to="#">{project.project_category}</Link>
                      <span>/</span>
                      <Link to="#">{project.client}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default SectionProjects;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import PartnerCarousel from "../components/PartnerCarousel/PartnerCarousel";
import "./SoftwareIntegrations.css";
import SectionConsultation from "../components/SectionConsultation/SectionConsultation";
import useBackToTop from "../utils/useBackToTop";

const SoftwareIntegrations = () => {
  useBackToTop(500, true);
  return (
    <div>
      <Helmet>
        <title>Software Integrations | TORIS Technologies</title>
        <meta
          name="description"
          content="TORIS Technologies offers a wide range of software integration services, including Web Development, Mobile Development, UI/UX Design, QA & Testing, and more."
        />
        <meta
          name="keywords"
          content="software integrations, web development, mobile development, QA testing, IT consultancy, software solutions, TORIS Technologies"
        />
        <meta name="author" content="TORIS Technologies" />
        <link
          rel="canonical"
          href="https://toris.co.za/software-integrations"
        />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Software Integrations | TORIS Technologies"
        />
        <meta
          property="og:description"
          content="Discover our extensive software integration services, ranging from web and mobile development to IT consultancy and QA testing."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/software-integrations/software-integrations-og.jpg"
        />
        <meta
          property="og:url"
          content="https://toris.co.za/software-integrations"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Software Integrations | TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="Explore TORIS Technologies’ Software Integration services, tailored to meet your business needs and streamline operations."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/software-integrations/software-integrations-twitter.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Software Integrations</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Software Integrations</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="service-page">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-8 mb-4 mb-sm-0">
                <div className="ot-heading mb-0">
                  <span>Our Services</span>
                  <h2 className="main-heading">
                    Comprehensive <br />
                    Software Integration Solutions
                  </h2>
                </div>
              </div>
            </div>
            <div className="space-55"></div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-code"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      Web Integration
                    </h5>
                    <p>
                      Seamlessly connect your website with third-party tools,
                      APIs, and services, enabling smooth data flow and
                      automation across platforms.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-app"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      Mobile Integration
                    </h5>
                    <p>
                      Integrate mobile apps with backend systems, cloud
                      services, and other apps to deliver a cohesive and
                      functional mobile experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-monitor"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      API Development & Integration
                    </h5>
                    <p>
                      Design, build, and implement robust APIs that facilitate
                      secure and efficient communication between various
                      software systems.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 sm-pb-60">
                  <div className="icon-main">
                    <span className="flaticon-browser"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      Custom Software Integration
                    </h5>
                    <p>
                      Integrate custom solutions with existing enterprise
                      systems, enhancing workflows, data management, and user
                      interactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 xs-pb-60">
                  <div className="icon-main">
                    <span className="flaticon-report-1"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      Cloud & SaaS Integration
                    </h5>
                    <p>
                      Connect your applications to cloud-based services or SaaS
                      platforms, ensuring scalability, data synchronization, and
                      improved performance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1">
                  <div className="icon-main">
                    <span className="flaticon-best"></span>
                  </div>
                  <div className="content-box software-integrations-content-box">
                    <h5 className="software-integrations-card-heading">
                      CRM & ERP Integration
                    </h5>
                    <p>
                      Integrate your Customer Relationship Management (CRM) and
                      Enterprise Resource Planning (ERP) systems to streamline
                      operations and data management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <PartnerCarousel />
        </section>
        {/* Displaying Pricing Cards here */}
        {/* <section className="service-pricing">
          <ServicePricingCards />
        </section> */}

        <section className="section-consultation">
          <SectionConsultation />
        </section>

        <section className="service-clients bg-map-dots">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ot-heading text-center">
                  <span>our clients</span>
                  <h2 className="main-heading">
                    We are Trusted <br />
                    15+ Countries Worldwide
                  </h2>
                </div>
              </div>
            </div>
            {/* <div className="space-35"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="ot-testimonials">
                  <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SoftwareIntegrations;

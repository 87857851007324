import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { fetchProjectById } from "../utils/projectsService";
import "./ProjectDetails.css";
import useBackToTop from "../utils/useBackToTop";

const ProjectDetails = () => {
  useBackToTop(500, true);
  const { id } = useParams(); // Get project ID from URL params
  const [project, setProject] = useState(null);

  // Load the project data from the backend
  useEffect(() => {
    const loadProject = async () => {
      try {
        const data = await fetchProjectById(id);
        setProject(data);
      } catch (error) {
        console.error("Error loading project:", error);
      }
    };

    loadProject();
  }, [id]);

  // If no project data, show loading or error message
  if (!project) {
    return <div>Loading project details...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>
          Portfolio Single - {project.project_title} | TORIS Technologies
        </title>
        <meta
          name="description"
          content={`Explore our detailed case study on the '${project.project_title}' by TORIS Technologies. See how our process drives innovation and success.`}
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content={`TORIS Technologies, portfolio, ${project.project_title}, case study, web development, technology, app development`}
        />
        <link
          rel="canonical"
          href={`https://toris.co.za/project-details/${id}`}
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`Portfolio Single - ${project.project_title} | TORIS Technologies`}
        />
        <meta
          property="og:description"
          content={`Dive into our in-depth case study on the '${project.project_title}' project by TORIS Technologies, showcasing our approach to innovation and quality in development.`}
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/projects/project-single.jpg"
        />
        <meta
          property="og:url"
          content={`https://toris.co.za/project-details/${id}`}
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Portfolio Single - ${project.project_title} | TORIS Technologies`}
        />
        <meta
          name="twitter:description"
          content={`Discover our work on the '${project.project_title}' by TORIS Technologies. Learn about our process, results, and approach to high-quality development.`}
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/projects/project-single.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">{project.project_title}</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Portfolio Single</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="portfolio-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{project.project_title}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-lg-0">
                <div className="project-detail">
                  <span>PUBLISHED:</span>
                  <strong>
                    {new Date(project.published_date).toLocaleDateString()}
                  </strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-sm-0">
                <div className="project-detail">
                  <span>CATEGORY:</span>
                  <strong>{project.project_category}</strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-sm-0">
                <div className="project-detail">
                  <span>CLIENT:</span>
                  <strong>{project.client}</strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="otf-social-share clearfix shape-circle">
                  {/* Social Share Links */}
                  <Link
                    className="share-facebook"
                    to="https://www.facebook.com"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    className="share-twitter"
                    to="https://www.twitter.com"
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link
                    className="share-pinterest"
                    to="https://www.pinterest.com"
                    target="_blank"
                  >
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                  <Link
                    className="share-linkedin"
                    to="https://www.linkedin.com"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="space-50"></div>
            <div className="row">
              <div className="col-md-12">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/${project.project_image}`}
                  alt={project.project_title}
                  className="single-project-img"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>How it Works</h3>
                <p>{project.how_it_works}</p>
                <blockquote>
                  <p>
                    {project.quote} <br />
                    <cite>– {project.quote_author}</cite>
                  </p>
                </blockquote>
                <h3>Process & Results</h3>
                <p>{project.process_results}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectDetails;

// File: /src/components/SubscriptionSuccess/SubscriptionSuccess.js
import React from "react";
import { Link } from "react-router-dom";
import "./SubscriptionSuccess.css"; // Optional: for styling
import useBackToTop from "../utils/useBackToTop";

const SubscriptionSuccess = () => {
  useBackToTop(500, true);
  return (
    <div className="subscription-success">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <div className="success-icon">
              <i className="fa fa-check-circle"></i>
            </div>
            <h2>Subscription Successful!</h2>
            <p>
              Thank you for subscribing! Your membership is now active, and you
              can start enjoying the benefits of your selected plan.
            </p>
            <div className="plan-details">
              <h4>Your Plan Details</h4>
              <ul>
                <li>Plan: <strong>Basic/Pro/Exclusive Membership</strong></li>
                <li>Monthly Fee: <strong>{/* Add currency and price here */}</strong></li>
                <li>Next Payment Date: <strong>{/* Add dynamic date here */}</strong></li>
              </ul>
            </div>
            <div className="next-steps">
              <h4>What's Next?</h4>
              <ul>
                <li>Explore our <Link to="/templates">template library</Link>.</li>
                <li>Check out our <Link to="/tutorials">exclusive tutorials</Link> for members.</li>
                <li>Manage your account in the <Link to="/dashboard">user dashboard</Link>.</li>
              </ul>
            </div>
            <Link to="/" className="octf-btn">
              Go to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  fetchMarketingEmails,
  addMarketingEmail,
  updateMarketingEmail,
  deleteMarketingEmail,
  sendTestEmail,
} from "../../utils/marketingEmailService";
import { formatDateForInput } from "../../utils/formatDateInput";
import Modal from "react-modal";
import { Editor } from "@tinymce/tinymce-react";
import "./AdminManageEmails.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Set the root element for Modal (required for accessibility)
Modal.setAppElement("#page");

const AdminManageEmails = () => {
  const [emails, setEmails] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    listSegment: "newsletter",
    status: "draft",
    scheduledDate: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewEmail, setPreviewEmail] = useState("");
  const [testEmail, setTestEmail] = useState("");

  useEffect(() => {
    const loadEmails = async () => {
      try {
        const data = await fetchMarketingEmails();
        setEmails(data);
      } catch (error) {
        console.error("Error fetching marketing emails:", error);
      }
    };

    loadEmails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        scheduledDate: formData.scheduledDate
          ? new Date(formData.scheduledDate).toISOString()
          : null,
      };

      if (isEdit) {
        await updateMarketingEmail(editId, updatedFormData);
      } else {
        await addMarketingEmail(updatedFormData);
      }

      setFormData({
        title: "",
        body: "",
        listSegment: "newsletter",
        status: "draft",
        scheduledDate: "",
      });
      setIsEdit(false);
      setEditId(null);

      const updatedEmails = await fetchMarketingEmails();
      setEmails(updatedEmails);
    } catch (error) {
      console.error("Error adding/updating marketing email:", error);
    }
  };

  const handleEditClick = (email) => {
    setFormData({
      ...email,
      scheduledDate: formatDateForInput(email.scheduledDate),
    });
    setIsEdit(true);
    setEditId(email.id);
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteMarketingEmail(id);
      const updatedEmails = await fetchMarketingEmails();
      setEmails(updatedEmails);
    } catch (error) {
      console.error("Error deleting marketing email:", error);
    }
  };

const handlePreviewClick = (email) => {
  setFormData({
    ...formData,
    title: email.title,
    body: email.body, // Sync body content
    listSegment: email.listSegment,
    status: email.status,
    scheduledDate: email.scheduledDate,
  });
  setPreviewEmail(email); // Keep this for displaying in the modal
  setIsPreviewModalOpen(true);
};


  const handleTestEmailChange = (e) => {
    setTestEmail(e.target.value);
  };

  const handleSendTestEmail = async () => {

    if (!formData.body || !formData.body.trim()) {
      toast.error("Email body cannot be empty");
      return;
    }

    try {
      const emailData = {
        ...formData,
        body: formData.body,
      };

      const response = await sendTestEmail(emailData, testEmail);
      toast.success("Test email sent successfully!");
      setIsPreviewModalOpen(false);
    } catch (error) {
      console.error("Error sending test email:", error);
      toast.error("Failed to send test email.");
    }
  };

  return (
    <div className="admin-manage-marketing-emails">
      <h2>Manage Marketing Emails</h2>

      <form onSubmit={handleFormSubmit} className="email-form">
        <div className="form-row">
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Email Title"
            required
          />
          <select
            name="listSegment"
            value={formData.listSegment}
            onChange={handleInputChange}
          >
            <option value="newsletter">Newsletter</option>
            <option value="event">Event</option>
            <option value="member">Member</option>
          </select>

          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value="draft">Draft</option>
            <option value="scheduled">Scheduled</option>
            <option value="sent">Sent</option>
          </select>

          <input
            type="datetime-local"
            name="scheduledDate"
            value={formData.scheduledDate}
            onChange={handleInputChange}
          />
        </div>

        <Editor
          apiKey="7a8q8c7rihnmcidaf9w373wrcn0vxjda9hwtrob9tyokqhu0" // Replace with your actual TinyMCE API key
          value={formData.body} // Use value prop to control the content
          init={{
            height: 300,
            menubar: false,
            plugins: "link image code",
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | code",
            directionality: "ltr", // Set to left-to-right
            force_br_newlines: true, // Enforce <br> on new lines
          }}
          onEditorChange={(content, editor) => {
            setFormData({ ...formData, body: content });
          }}
        />

        <button type="submit" className="form-submit-btn">
          {isEdit ? "Update" : "Add"} Email
        </button>
      </form>

      <table className="email-list-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Segment</th>
            <th>Status</th>
            <th>Scheduled Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {emails.length > 0 ? (
            emails.map((email) => (
              <tr key={email.id}>
                <td>{email.title}</td>
                <td>{email.listSegment}</td>
                <td>{email.status}</td>
                <td>
                  {email.scheduledDate
                    ? new Date(email.scheduledDate).toLocaleString()
                    : "N/A"}
                </td>
                <td className="action-buttons">
                  <button
                    onClick={() => handleEditClick(email)}
                    className="edit-button"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handlePreviewClick(email)}
                    className="preview-button"
                  >
                    Preview
                  </button>
                  <button
                    onClick={() => handleDeleteClick(email.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No marketing emails found.</td>
            </tr>
          )}
        </tbody>
      </table>

      {ReactDOM.createPortal(
        <Modal
          isOpen={isPreviewModalOpen}
          onRequestClose={() => setIsPreviewModalOpen(false)}
          contentLabel="Preview Email"
          ariaHideApp={false}
          style={{
            content: {
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 1)", // Fully opaque white
              padding: "30px",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "900px",
              zIndex: "1001",
              overflowY: "auto",
              minHeight: "80vh",
              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)", // Add shadow
              opacity: 1,
            },
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: "1000",
            },
          }}
        >
          <h2>Email Preview</h2>
          <div
            className="email-body-preview"
            dangerouslySetInnerHTML={{ __html: previewEmail.body }}
          />

          <div className="test-email-section">
            <input
              type="email"
              value={testEmail}
              onChange={handleTestEmailChange}
              placeholder="Enter your email for a test"
              className="test-email-input"
            />
            <div className="email-preview-buttons-section">
              <button
                onClick={handleSendTestEmail}
                className="send-test-email-btn"
              >
                Send Test
              </button>
              <button
                onClick={() => setIsPreviewModalOpen(false)}
                className="close-modal-btn"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>,
        document.getElementById("modal-root")
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AdminManageEmails;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

// Importing pages
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import Post from "./pages/Post";
import Team from "./pages/Team";
import Shop from "./pages/Shop";
import Cart from "./pages/Cart";
import About from "./pages/About";
import Login from "./pages/Login";
import Event from "./pages/Event";
import Events from "./pages/Events";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Checkout from "./pages/Checkout";
import Projects from "./pages/Projects";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import ComingSoon from "./pages/ComingSoon";
import WhyChooseUs from "./pages/WhyChooseUs";
import SingleProduct from "./pages/SingleProduct";
import NotAuthorized from "./pages/NotAuthorized";
import EmailVerified from "./pages/EmailVerified";
import ProjectDetails from "./pages/ProjectDetails";
import WebDevelopment from "./pages/WebDevelopment";
import AdminDashboard from "./pages/AdminDashboard";
import MobileDevelopment from "./pages/MobileDevelopment";
import SoftwareIntegrations from "./pages/SoftwareIntegrations";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";

// Importing components
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/Navbar/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminProductTags from "./components/AdminProductTags/AdminProductTags";
import AdminProtectedRoute from "./components/AdminProtectedRoutes/AdminProtectedRoutes";
import AdminUsersManagement from "./components/AdminUsersManagement/AdminUsersManagement";
import AdminProductCategories from "./components/AdminProductCategories/AdminProductCategories";

// Importing context
import { AuthProvider } from "./context/AuthContext";
import { ExchangeRateProvider } from "./context/ExchangeRateContext";

function App() {
  return (
    <ExchangeRateProvider>
      <AuthProvider>
        <Router>
          <div className="App">
            <Navbar />
            <MainContent />
          </div>
        </Router>
      </AuthProvider>
    </ExchangeRateProvider>
  );
}

const MainContent = () => {
  const location = useLocation();
  const excludeFooterRoutes = ["/admin", "/login", "/register"];
  const shouldExcludeFooter = excludeFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      <Routes>
        {/* General Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/why-choose-us" element={<WhyChooseUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event/:eventId" element={<Event />} />
        <Route path="/subscription-success" element={<SubscriptionSuccess />} />

        {/* Profile Route */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* Projects Routes */}
        <Route path="/projects" element={<Projects />} />
        <Route path="/project-details/:id" element={<ProjectDetails />} />

        {/* Service Routes */}
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/mobile-development" element={<MobileDevelopment />} />
        <Route
          path="/software-integrations"
          element={<SoftwareIntegrations />}
        />

        {/* Blog Routes */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:postId" element={<Post />} />

        {/* Shop Routes */}
        <Route path="/shop" element={<Shop />} />
        <Route path="/single-product/:productId" element={<SingleProduct />} />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />

        {/* Admin Pages */}
        <Route
          path="/admin"
          element={
            <AdminProtectedRoute>
              <AdminDashboard />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <AdminProtectedRoute>
              <AdminProductCategories />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/tags"
          element={
            <AdminProtectedRoute>
              <AdminProductTags />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminProtectedRoute>
              <AdminUsersManagement />
            </AdminProtectedRoute>
          }
        />

        {/* Auth Pages */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verification-success" element={<EmailVerified />} />

        {/* Invalid Route */}
        <Route path="*" element={<NotFound />} />

        {/* Access denied */}
        <Route path="/not-authorized" element={<NotAuthorized />} />
      </Routes>

      {/* Conditionally render the footer */}
      {!shouldExcludeFooter && <Footer />}
    </>
  );
};

export default App;

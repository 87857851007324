// File: components/ProductsFeatured.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchFeaturedProducts } from '../../utils/productService';
import "./ProductsFeatured.css";

const ProductsFeatured = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    const loadFeaturedProducts = async () => {
      const products = await fetchFeaturedProducts();
      setFeaturedProducts(products);
    };
    loadFeaturedProducts();
  }, []);

  return (
    <div className="featured-products">
      <h2>Featured Products</h2>
      <ul className="product-list">
        {featuredProducts.map((product) => (
          <li key={product.id} className="product-item">
            <Link to={`/single-product/${product.id}`}>
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              <p>{product.sale ? `Sale: R${product.salePrice}` : `Price: R${product.price}`}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductsFeatured;

import React, { useState, useEffect } from "react";
import "./AdminReportedComments.css";
import Modal from "react-modal";
import { FaEye } from "react-icons/fa";
import axios from "../../utils/axiosConfig"; 

const AdminReportedComments = () => {
  const [reportedComments, setReportedComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchReportedComments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/reported-comments`
        );
        setReportedComments(response.data);
      } catch (error) {
        console.error("Error fetching reported comments:", error);
      }
    };
    fetchReportedComments();
  }, []);

  const openModal = (comment) => {
    setSelectedComment(comment);
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedComment(null);
    document.body.classList.remove("modal-open");
  };

  const handleApprove = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/comments/${selectedComment.commentId}/approve`
      );
      setReportedComments(
        reportedComments.filter((c) => c.commentId !== selectedComment.commentId)
      );
      closeModal();
    } catch (error) {
      console.error("Error approving comment:", error);
    }
  };

  const handleReject = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/comments/${selectedComment.commentId}/reject`
      );
      setReportedComments(
        reportedComments.filter((c) => c.commentId !== selectedComment.commentId)
      );
      closeModal();
    } catch (error) {
      console.error("Error rejecting comment:", error);
    }
  };

  return (
    <div className="admin-reported-comments">
      <h2>Comment Moderation</h2>
      <table className="reported-comments-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Reported By User</th>
            <th>Date Reported</th>
            <th>Reason</th>
            <th>Additional Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reportedComments.map((comment) => (
            <tr key={comment.id}>
              <td>{comment.id}</td>
              <td>{comment.reportedByUserId}</td>
              <td>{new Date(comment.createdAt).toLocaleDateString()}</td>
              <td>{comment.reason}</td>
              <td>{comment.additionalReason || 'Not provided'}</td>
              <td>
                <FaEye className="view-icon" onClick={() => openModal(comment)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedComment && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Reported Comment Details"
          className="custom-modal"
          overlayClassName="custom-overlay"
        >
          <h2>Reported Comment Details</h2>
          <div className="modal-row">
            <strong>Comment ID:</strong> <span>{selectedComment.commentId}</span>
          </div>
          <div className="modal-row">
            <strong>Reported By User ID:</strong> <span>{selectedComment.reportedByUserId}</span>
          </div>
          <div className="modal-row">
            <strong>Blog ID:</strong> <span>{selectedComment.blogId || "N/A"}</span>
          </div>
          <div className="modal-row">
            <strong>Reason:</strong> <span>{selectedComment.reason}</span>
          </div>
          {selectedComment.additionalReason && (
            <div className="modal-row">
              <strong>Additional Reason:</strong>{" "}
              <span>{selectedComment.additionalReason}</span>
            </div>
          )}
          <div className="modal-row">
            <strong>Comment:</strong>{" "}
            <span>{selectedComment.comment?.comment || "N/A"}</span>
          </div>
          <div className="modal-row">
            <strong>Likes:</strong> <span>{selectedComment.comment?.likes || 0}</span>
          </div>
          <div className="modal-row">
            <strong>Status:</strong> <span>{selectedComment.comment?.status || "N/A"}</span>
          </div>
          <div className="modal-row">
            <strong>Date Created:</strong>{" "}
            <span>{new Date(selectedComment.createdAt).toLocaleString()}</span>
          </div>
          <div className="modal-row">
            <strong>Last Updated:</strong>{" "}
            <span>{new Date(selectedComment.updatedAt).toLocaleString()}</span>
          </div>

          <div className="modal-actions">
            <button className="approve-button" onClick={handleApprove}>
              Approve
            </button>
            <button className="reject-button" onClick={handleReject}>
              Reject
            </button>
            <button onClick={closeModal}>Close</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdminReportedComments;

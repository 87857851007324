// src/components/ProtectedAdminRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const AdminProtectedRoutes = ({ children }) => {
  const { auth } = useAuth();

  if (!auth.isAuthenticated) {
    // If the user is not authenticated, redirect to login
    return <Navigate to="/login" />;
  }

  // Correct the role check to allow admin, moderator, and guest blogger
  if (auth.role !== 'admin' && auth.role !== 'moderator' && auth.role !== 'guest blogger') {
    // If the user does not have one of the valid roles, redirect
    return <Navigate to="/not-authorized" />;
  }

  // If the user has the appropriate role, allow access to the admin routes
  return children;
};

export default AdminProtectedRoutes;

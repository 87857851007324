import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./WebDevelopment.css";
import PartnerCarousel from "../components/PartnerCarousel/PartnerCarousel";
import SectionNeedAConsultation from "../components/SectionNeedAConsultation/SectionNeedAConsultation";
import ServicePricingCards from "../components/ServicePricingCards/ServicePricingCards";
import CallToActionPricing from "../components/CallToActionPricing/CallToActionPricing";
import SectionConsultation from "../components/SectionConsultation/SectionConsultation";
import useBackToTop from "../utils/useBackToTop";

const WebDevelopment = () => {
  useBackToTop(500, true);
  const [activeTab, setActiveTab] = useState("tab-1518");
  // Function to handle tab click
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Function to render tab content dynamically
  const renderTabContent = () => {
    switch (activeTab) {
      case "tab-1518":
        return (
          <div className="web-dev-tab-content">
            <p>
              <strong>Planning</strong> is the foundation of our development
              process. It begins with a comprehensive understanding of your
              business goals, target audience, and project requirements. During
              this phase, we engage with stakeholders to gather detailed
              requirements, define project objectives, and outline the scope of
              work. We also conduct market research and competitor analysis to
              ensure that our solution is aligned with industry standards and
              your unique needs.
            </p>
            <p>
              We create a detailed project roadmap that includes timelines,
              deliverables, and milestones, giving you clear visibility into the
              development process. This roadmap serves as a guide for the entire
              project, ensuring that we remain on track and adaptable to any
              changes that may arise.
            </p>
          </div>
        );
      case "tab-2518":
        return (
          <div className="web-dev-tab-content">
            <p>
              <strong>Wireframing</strong> is crucial in visualizing the
              structure and flow of the application. We create wireframes to
              outline the layout, functionality, and navigation of the app. This
              stage involves designing the user interface (UI) blueprint that
              provides a clear understanding of how users will interact with the
              system.
            </p>
            <p>
              Wireframes help us identify potential user experience (UX)
              challenges and make necessary adjustments early on. They act as a
              blueprint for the design phase, guiding both designers and
              developers in terms of functionality and layout. We use tools like
              Balsamiq, Sketch, and Figma to create interactive wireframes that
              simulate user interactions, ensuring that the application is
              intuitive and user-friendly.
            </p>
          </div>
        );
      case "tab-3518":
        return (
          <div className="web-dev-tab-content">
            <p>
              The <strong>Design</strong> phase focuses on translating
              wireframes into visually appealing and user-friendly interfaces.
              We ensure that the design aligns with your brand's identity while
              also enhancing the user experience. This phase includes selecting
              color schemes, typography, and imagery that resonate with your
              target audience.
            </p>
            <p>
              Our design team emphasizes accessibility and responsiveness,
              ensuring that the interface works seamlessly across different
              devices and screen sizes. We create interactive prototypes that
              allow stakeholders to experience the look and feel of the
              application before development begins. Feedback is collected and
              incorporated to ensure the final design meets expectations and
              enhances user engagement.
            </p>
          </div>
        );
      case "tab-4518":
        return (
          <div className="web-dev-tab-content">
            <p>
              <strong>Development</strong> is where the actual coding begins,
              translating designs into a functional application. This phase is
              divided into frontend and backend development. Our frontend
              developers build interactive user interfaces using modern
              frameworks like React, Angular, or Vue.js, ensuring smooth
              navigation and responsiveness.
            </p>
            <p>
              Concurrently, backend developers work on creating a robust,
              scalable, and secure server-side architecture. We use technologies
              like Node.js, PHP, and .NET for building the server logic, API
              integrations, and database management. Our developers follow
              coding best practices, ensuring the application is optimized for
              performance and security. Regular code reviews and version control
              are maintained using tools like GitHub or GitLab to keep track of
              changes and ensure code quality.
            </p>
          </div>
        );
      case "tab-5518":
        return (
          <div className="web-dev-tab-content">
            <p>
              During the <strong>Staging & CI/CD</strong> phase, we set up a
              staging environment that mirrors the live production environment.
              This is where all development is tested before being deployed to
              the live server. We implement Continuous Integration and
              Continuous Deployment (CI/CD) pipelines to automate testing,
              building, and deployment processes, ensuring faster and more
              reliable releases.
            </p>
            <p>
              The staging environment allows us to identify and resolve any
              issues in a controlled setting. It also facilitates user
              acceptance testing (UAT), where stakeholders can review the
              application and provide feedback. This phase ensures that the
              application is stable, secure, and ready for deployment, reducing
              the risk of errors when going live.
            </p>
          </div>
        );
      case "tab-6518":
        return (
          <div className="web-dev-tab-content">
            <p>
              <strong>Testing</strong> is a critical phase in our development
              process. It includes comprehensive Quality Assurance (QA) to
              identify and resolve issues before the final product is launched.
              Our testing methods include functional testing, performance
              testing, security testing, and user acceptance testing (UAT).
            </p>
            <p>
              We employ both manual and automated testing techniques to ensure
              the application meets all requirements and performs efficiently
              under various conditions. Our QA team works closely with
              developers to resolve issues quickly, ensuring that the product is
              stable, secure, and delivers a seamless user experience.
            </p>
          </div>
        );
      case "tab-7518":
        return (
          <div className="web-dev-tab-content">
            <p>
              The <strong>Deployment & Launch</strong> phase is where the
              application goes live. We ensure that the final product is
              deployed to a secure, scalable, and reliable production
              environment. This phase involves setting up the live server,
              configuring DNS settings, and ensuring that all security measures
              (like SSL certificates and firewalls) are in place.
            </p>
            <p>
              After deployment, we monitor the application’s performance,
              resolve any post-launch issues, and ensure that the product meets
              user expectations. We also provide training and documentation to
              help users understand the system and make the most of its
              features. Our team remains available for post-launch support and
              maintenance to ensure long-term success.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Web Development Services | TORIS Technologies</title>
        <meta
          name="description"
          content="TORIS Technologies offers top-notch web development services, including Java, PHP, C++, and .NET. Elevate your business with our custom software solutions and reliable tech support."
        />
        <meta
          name="keywords"
          content="web development, TORIS Technologies, Java development, PHP development, C++ development, .NET, custom software solutions"
        />
        <meta name="author" content="TORIS Technologies" />
        <link rel="canonical" href="https://toris.co.za/web-development" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Web Development Services | TORIS Technologies"
        />
        <meta
          property="og:description"
          content="Explore TORIS Technologies' web development services, offering expertise in Java, PHP, C++, and .NET. Partner with us for customized solutions and dedicated support."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/services/web-development.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/web-development" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Web Development Services | TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="Learn about TORIS Technologies' web development services, including Java, PHP, C++, and .NET. We deliver custom solutions to elevate your business."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/services/web-development.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Web Development</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Web Development</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="service-web">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center mb-5 mb-lg-0 align-self-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/pic1-service1.png`}
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="service-process">
                  <div className="ot-heading">
                    <span>about service</span>
                    <h2 className="web-development-main-heading">
                      We Provide The Best
                      <br />
                      Custom Web Solutions
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="process-box">
                        <div className="step">01.</div>
                        <h6 className="mb-0">React Development</h6>
                        <p>
                          We deliver high-performance single-page applications
                          using React, ensuring dynamic user experiences.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="process-box">
                        <div className="step">02.</div>
                        <h6 className="mb-0">PHP Development</h6>
                        <p>
                          We build scalable backend solutions for web
                          applications using PHP, ensuring efficiency and
                          security.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="process-box">
                        <div className="step">03.</div>
                        <h6 className="mb-0">Node.js Development</h6>
                        <p>
                          Our Node.js development services ensure real-time,
                          event-driven applications that are robust and fast.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="process-box">
                        <div className="step">04.</div>
                        <h6 className="mb-0">Full-Stack Solutions</h6>
                        <p>
                          We offer full-stack solutions using popular tech
                          stacks like HTML, CSS, JavaScript, and TypeScript,
                          integrating both frontend and backend seamlessly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="service">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="service-box s-box">
                  <span className="big-number">01</span>
                  <div className="icon-main color-s1">
                    <span className="flaticon-correct"></span>
                  </div>
                  <div className="content-box">
                    <h5>Web Solutions</h5>
                    <p>End-to-End Development</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="service-box s-box">
                  <span className="big-number">02</span>
                  <div className="icon-main color-s2">
                    <span className="flaticon-correct"></span>
                  </div>
                  <div className="content-box">
                    <h5>Backend Integrations</h5>
                    <p>Flexible and Secure APIs</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-box s-box">
                  <span className="big-number">03</span>
                  <div className="icon-main color-s3">
                    <span className="flaticon-correct"></span>
                  </div>
                  <div className="content-box">
                    <h5>Responsive Design</h5>
                    <p>Mobile-First Approach</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Service Web Video Section with Tabs */}
        <section className="service-web-video">
          <div className="container-fluid">
            <div className="process-row">
              <div className="tab-video">
                <div className="ot-heading">
                  <span>technology index</span>
                  <h2 className="main-heading">
                    We Organize Our <br />
                    Development Process
                  </h2>
                </div>
                <div className="space-15"></div>
                <div className="ot-tabs">
                  {/* Horizontal scrolling tabs */}
                  <ul className="web-dev-tabs-heading unstyle tab-gaps">
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-1518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-1518")}
                    >
                      Planning
                    </li>
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-2518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-2518")}
                    >
                      Wireframing
                    </li>
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-3518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-3518")}
                    >
                      Design
                    </li>
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-4518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-4518")}
                    >
                      Development
                    </li>
                  </ul>
                  <ul className="web-dev-tabs-heading unstyle tab-gaps">
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-5518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-5518")}
                    >
                      Staging & CI/CD
                    </li>
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-6518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-6518")}
                    >
                      Testing
                    </li>
                    <li
                      className={`web-dev-tab-link octf-btn ${
                        activeTab === "tab-7518" ? "current" : ""
                      }`}
                      onClick={() => handleTabClick("tab-7518")}
                    >
                      Deployment & Launch
                    </li>
                  </ul>

                  {/* Render Tab Content */}
                  {renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Displaying Pricing Cards here */}
        {/* <section className="service-pricing">
          <ServicePricingCards />
        </section> */}

        <section>
          <PartnerCarousel />
        </section>
        <CallToActionPricing />

        <section className="service-web-clients">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ot-heading text-center">
                  <span>our clients</span>
                  <h2 className="main-heading">
                    We are Trusted <br />
                    15+ Countries Worldwide
                  </h2>
                </div>
              </div>
            </div>
            {/* <div className="space-35"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="ot-testimonials">
                  <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt=""
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt=""
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt=""
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt=""
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
          <SectionConsultation />
      </div>
    </div>
  );
};

export default WebDevelopment;

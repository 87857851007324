import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { useAuth } from "../context/AuthContext";
import { useCart } from "../context/CartContext";
import { useExchangeRate } from "../context/ExchangeRateContext";
import LoginModal from "../components/LoginModal/LoginModal";
import "./SingleProduct.css";
import useBackToTop from "../utils/useBackToTop";

const SingleProduct = () => {
  useBackToTop(500, true);
  const { productId } = useParams();
  const { auth } = useAuth();
  const { dispatch } = useCart();
  const { exchangeRates, currencyCode } = useExchangeRate();
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState("tab-1");
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayImage, setOverlayImage] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isDemoOpen, setIsDemoOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${baseUrl}/products/${productId}`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };
    fetchProduct();
  }, [productId]);

  const handleImageClick = (imgUrl) => {
    setOverlayImage(imgUrl);
    setIsOverlayOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setOverlayImage("");
    document.body.style.overflow = "auto";
  };

  const handleAddToCart = (e) => {
    e.preventDefault();

    if (auth.isAuthenticated) {
      dispatch({ type: "ADD_TO_CART", payload: product });
    } else {
      setShowLoginModal(true);
    }
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  // Function to convert ZAR to the desired currency
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    return (amountInZAR * conversionRate).toFixed(2);
  };

  const handleLiveDemo = () => {
    setIsDemoOpen(true); // Open the live demo modal
  };

  const closeLiveDemo = () => {
    setIsDemoOpen(false); // Close the live demo modal
  };

  if (!product) {
    return <div>Loading product...</div>;
  }

  return (
    <div id="content" className="site-content">
      {product && (
        <Helmet>
          <title>{`${product.name} | TORIS Technologies Shop`}</title>
          <meta
            name="description"
            content={`Explore ${product.name} at TORIS Technologies. ${product.description}`}
          />
          <meta
            name="keywords"
            content={`TORIS Technologies, ${
              product.name
            }, ${product.categories.join(", ")}`}
          />
          <link
            rel="canonical"
            href={`https://toris.co.za/shop/${productId}`}
          />

          {/* Open Graph Tags */}
          <meta
            property="og:title"
            content={`${product.name} | TORIS Technologies Shop`}
          />
          <meta property="og:description" content={`${product.description}`} />
          <meta property="og:image" content={`${product.image}`} />
          <meta
            property="og:url"
            content={`https://toris.co.za/shop/${productId}`}
          />
          <meta property="og:type" content="product" />
          <meta property="og:product:price:amount" content={product.price} />
          <meta property="og:product:price:currency" content={currencyCode} />

          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${product.name} | TORIS Technologies Shop`}
          />
          <meta name="twitter:description" content={`${product.description}`} />
          <meta name="twitter:image" content={`${product.image}`} />
          {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
          </script>
        </Helmet>
      )}

      <div className="page-header flex-middle">
        <div className="container">
          <div className="inner flex-middle">
            <h1 className="page-title">{product.name}</h1>
            <ul id="breadcrumbs" className="breadcrumbs none-style">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/shop">Shop</Link>
              </li>
              <li className="active">{product.name}</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="shop-single">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0 text-center align-self-center">
              <div className="product-slide">
                <img src={product.image} alt={product.name} />
                <div className="single-product-gallery">
                  {product.galleryImages &&
                    product.galleryImages.map((imgUrl, index) => (
                      <img
                        key={index}
                        src={imgUrl}
                        alt={`${product.name} ${index + 1}`}
                        onClick={() => handleImageClick(imgUrl)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="summary entry-summary">
                <h2 className="single-product-title">{product.name}</h2>
                <div className="single-product-rating">
                  {[...Array(product.rating)].map((_, index) => (
                    <span key={index}>
                      <i className="fa fa-star"></i>
                    </span>
                  ))}
                  <span> ({product.rating})</span>
                </div>
                <span className="single-price price-product">
                  {product.sale ? (
                    <>
                      <del>
                        <span className="amount">
                          <span>{currencyCode} </span>
                          {convertToCurrency(product.oldPrice)}
                        </span>
                      </del>
                      <ins>
                        <span className="amount">
                          <span>{currencyCode} </span>
                          {convertToCurrency(product.price)}
                        </span>
                      </ins>
                    </>
                  ) : (
                    <ins>
                      <span className="amount">
                        <span>{currencyCode} </span>
                        {convertToCurrency(product.price)}
                      </span>
                    </ins>
                  )}
                </span>
                <p>{product.description}</p>
                <form className="cart" method="post" onSubmit={handleAddToCart}>
                  <div className="single-quantity">
                    <input
                      type="number"
                      id="quantity"
                      className="input-text qty text"
                      step="1"
                      min="1"
                      name="quantity"
                      defaultValue="1"
                    />
                  </div>
                  <button
                    type="submit"
                    className="octf-btn single_add_to_cart_button button alt"
                  >
                    Add to cart
                  </button>
                </form>
                <div className="product_meta">
                  <em>
                    <span className="sku_wrapper">
                      <strong>SKU:</strong>{" "}
                      <span className="sku">{product.sku}</span>
                    </span>
                    <span className="posted_in">
                      <strong> | Categories:</strong>{" "}
                      {product.categories.join(", ")}
                    </span>
                    <span className="tagged_as">
                      <strong> | Tags:</strong> {product.tags.join(", ")}
                    </span>
                  </em>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs for Description, Information, and Reviews */}
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="ot-tabs specifications-tabs">
                <ul className="tabs-heading unstyle">
                  <li
                    className={`tab-link octf-btn ${
                      activeTab === "tab-1" ? "current" : ""
                    }`}
                    onClick={() => setActiveTab("tab-1")}
                  >
                    Description
                  </li>
                  <li
                    className={`tab-link octf-btn ${
                      activeTab === "tab-2" ? "current" : ""
                    }`}
                    onClick={() => setActiveTab("tab-2")}
                  >
                    Information
                  </li>
                  <li
                    className={`tab-link octf-btn ${
                      activeTab === "tab-3" ? "current" : ""
                    }`}
                    onClick={() => setActiveTab("tab-3")}
                  >
                    Review
                  </li>
                </ul>
                <div className="tab-content-container">
                  {activeTab === "tab-1" && (
                    <div id="tab-1" className="tab-content current">
                      <p>{product.description}</p>
                      <h3>Features</h3>
                      <ul>
                        <li>SEO Optimized</li>
                        <li>Responsive Design</li>
                        <li>Customizable Layout</li>
                        <li>Cross-Browser Compatibility</li>
                      </ul>
                      <div className="live-demo-section">
                        <button
                          onClick={handleLiveDemo}
                          className="octf-btn octf-btn-primary"
                        >
                          View Live Demo
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab-2" && (
                    <div
                      id="tab-2"
                      className="tab-content"
                      style={{
                        display: activeTab === "tab-2" ? "block" : "none",
                      }}
                    >
                      <table className="woocommerce-product-attributes shop_attributes">
                        <tbody>
                          <tr>
                            <th>File Types Included</th>
                            <td>
                              {product.fileTypes
                                ? product.fileTypes.join(", ")
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Framework</th>
                            <td>
                              {product.frameworks
                                ? product.frameworks.join(", ")
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Compatible Browsers</th>
                            <td>
                              {product.compatibleBrowsers
                                ? product.compatibleBrowsers.join(", ")
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Documentation</th>
                            <td>
                              {product.documentation
                                ? "Included"
                                : "Not Included"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {activeTab === "tab-3" && (
                    <div
                      id="tab-3"
                      className="tab-content"
                      style={{
                        display: activeTab === "tab-3" ? "block" : "none",
                      }}
                    >
                      <h2>Customer Reviews</h2>
                      <p>
                        No reviews yet. Be the first to review this product!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Image Overlay for full-screen display */}
          {isOverlayOpen && (
            <div className="image-overlay">
              <img src={overlayImage} alt="Full-screen view" />
              <button className="overlay-close-button" onClick={closeOverlay}>
                ×
              </button>
            </div>
          )}

          {/* Live Demo Modal */}
          {isDemoOpen && (
            <div className="demo-overlay" onClick={closeLiveDemo}>
              <div className="demo-modal-content">
                <button className="demo-close-button" onClick={closeLiveDemo}>
                  ×
                </button>
                <iframe
                  src={`${baseUrl}/templates/preview/${productId}`}
                  title="Live Demo"
                  style={{ width: "100%", height: "90vh", border: "none" }}
                ></iframe>
              </div>
            </div>
          )}

          {/* Login Modal */}
          {showLoginModal && <LoginModal onClose={closeLoginModal} />}
        </div>
      </section>
    </div>
  );
};

export default SingleProduct;

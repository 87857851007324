import axios from "./axiosConfig"; 

const API_URL = process.env.REACT_APP_API_URL;

// Fetch all events
export const fetchEvents = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_URL}/events`, { params: { page, limit } });
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

// Add a new event
export const addEvent = async (eventData) => {
  try {
    const formData = new FormData();
    Object.keys(eventData).forEach(key => {
      formData.append(key, eventData[key]);
    });

    const response = await axios.post(`${API_URL}/events`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding event:', error);
    throw error;
  }
};

// Update an event
export const updateEvent = async (eventId, eventData) => {
  try {
    const formData = new FormData();
    Object.keys(eventData).forEach(key => {
      formData.append(key, eventData[key]);
    });

    const response = await axios.put(`${API_URL}/events/${eventId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

// Delete an event
export const deleteEvent = async (eventId) => {
  try {
    const response = await axios.delete(`${API_URL}/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

export const fetchEventById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/events/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

// Register user for an event
export const registerForEvent = async (eventId, formData) => {
  const response = await axios.post(`${API_URL}/events/${eventId}/register`, formData);
  return response.data;
};

// Fetch events that are yet to happen (upcoming events)
export const fetchUpcomingEvents = async () => {
  const response = await axios.get(`${API_URL}/events?upcoming=true`);
  return response.data.events;
};

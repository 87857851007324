import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import axios from "../utils/axiosConfig";
import ProductCard from "../components/ProductCard/ProductCard";
import Sidebar from "../components/Sidebar/Sidebar";
import { useAuth } from "../context/AuthContext";
import LoginModal from "../components/LoginModal/LoginModal";
import ProductsFeatured from "../components/ProductsFeatured/ProductsFeatured";
import { fetchAvailableProducts } from "../utils/productService";
import "./Shop.css";
import useBackToTop from "../utils/useBackToTop";

const Shop = () => {
  useBackToTop(500, true);
  const { auth } = useAuth();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const productContainerRef = useRef(null);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [sortOption, setSortOption] = useState("menu_order");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loadAvailableProducts = async () => {
      const availableProducts = await fetchAvailableProducts();
      setProducts(availableProducts);
      setFilteredProducts(availableProducts);
    };

    loadAvailableProducts();
  }, []);

  useEffect(() => {
    filterProducts(
      searchTerm,
      selectedCategory,
      selectedTag,
      priceRange,
      sortOption
    );
  }, [searchTerm, selectedCategory, selectedTag, priceRange, sortOption]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    productContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
  };

  const handlePriceFilter = (range) => {
    setPriceRange(range);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const filterProducts = (
    term = "",
    category = "",
    tag = "",
    range = { min: 0, max: 1000 },
    sort = "menu_order"
  ) => {
    const lowercasedTerm = term.toLowerCase();
    let filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedTerm) &&
        (category ? product.categories.includes(category) : true) &&
        (tag ? product.tags.includes(tag) : true) &&
        product.price >= range.min &&
        product.price <= range.max
    );

    switch (sort) {
      case "popularity":
        filtered = filtered.sort((a, b) => b.popularity - a.popularity);
        break;
      case "rating":
        filtered = filtered.sort((a, b) => b.rating - a.rating);
        break;
      case "date":
        filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      case "price":
        filtered = filtered.sort((a, b) => a.price - b.price);
        break;
      case "price-desc":
        filtered = filtered.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }

    setFilteredProducts(filtered);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setSelectedCategory("");
    setSelectedTag("");
    setPriceRange({ min: 0, max: 1000 });
    setSortOption("menu_order");
    setFilteredProducts(products);
  };

  const handleAddToCartClick = () => {
    if (!auth.isAuthenticated) {
      setShowLoginModal(true);
    }
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div>
      <Helmet>
        <title>Shop | TORIS Technologies</title>
        <meta
          name="description"
          content="Browse and purchase from our wide range of technology products. Shop TORIS Technologies for the best products, carefully curated for your business needs."
        />
        <meta
          name="keywords"
          content="TORIS Technologies, shop, tech products, buy online, technology store, business products"
        />
        <meta name="author" content="TORIS Technologies" />
        <link rel="canonical" href="https://toris.co.za/shop" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Shop | TORIS Technologies" />
        <meta
          property="og:description"
          content="Explore the TORIS Technologies Shop for a range of innovative tech products designed for business success."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/shop/shop-og-image.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/shop" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Shop | TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Find the latest technology products in the TORIS Technologies Shop. Perfect for all your business needs."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/shop/shop-twitter-image.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="">
            <div className="inner flex-middle" ref={productContainerRef}>
              <h1 className="page-title">Shop</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Shop</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="shop-catalog">
          <div className="shop-container">
            <div className="products-row">
              {/* Sidebar with additional sorting and clearing functionality */}
              <Sidebar
                onSearch={handleSearch}
                onCategorySelect={handleCategorySelect}
                onTagSelect={handleTagSelect}
                onPriceFilter={handlePriceFilter}
                products={products}
                sortOption={sortOption} // Pass sort option to Sidebar
                onSortChange={handleSortChange} // Pass sorting handler to Sidebar
                onClearFilter={handleClearFilter} // Pass clear filter handler to Sidebar
              />

              {/* Product Cards Section */}
              <div className="product-section col-lg-9 col-md-12">
                <p className="woocommerce-result-count">
                  Showing {indexOfFirstProduct + 1}–
                  {Math.min(indexOfLastProduct, filteredProducts.length)} of{" "}
                  {filteredProducts.length} results
                </p>

                <div className="product-list row">
                  {currentProducts.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      onAddToCartClick={handleAddToCartClick}
                      isAuthenticated={auth.isAuthenticated}
                    />
                  ))}
                </div>

                <ul className="page-pagination text-center mt-3 none-style">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li key={i}>
                      <Link
                        className="page-numbers"
                        to="#"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductsFeatured />
      {showLoginModal && (
        <LoginModal onClose={() => setShowLoginModal(false)} />
      )}
    </div>
  );
};

export default Shop;

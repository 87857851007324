import React, { useState } from "react";
import Modal from "react-modal";
import {
  FaUserPlus,
  FaCartPlus,
  FaList,
  FaTags,
  FaEnvelope,
  FaBook,
  FaComment,
  FaCalendarAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./AdminSidebar.css";
import "../Tooltip/Tooltip.css";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "20px",
    borderRadius: "12px",
    border: "none",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    zIndex: 9999,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 9998,
  },
};

const AdminSidebar = ({ setSelectedComponent }) => {
  const [activeItem, setActiveItem] = useState("usersManagement");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleItemClick = (component) => {
    setActiveItem(component);
    setSelectedComponent(component);
    closeModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="admin-sidebar">
      <ul>
        <li
          className={activeItem === "usersManagement" ? "active" : ""}
          onClick={() => handleItemClick("usersManagement")}
          data-tooltip-id="tooltip-users"
          data-tooltip-content="Manage Users"
        >
          <FaUserPlus />
        </li>
        <li
          className={activeItem === "addProduct" ? "active" : ""}
          onClick={() => handleItemClick("addProduct")}
          data-tooltip-id="tooltip-addProduct"
          data-tooltip-content="Add Product"
        >
          <FaCartPlus />
        </li>
        <li
          className={activeItem === "productCategories" ? "active" : ""}
          onClick={() => handleItemClick("productCategories")}
          data-tooltip-id="tooltip-categories"
          data-tooltip-content="Manage Categories"
        >
          <FaList />
        </li>
        <li
          className={activeItem === "productTags" ? "active" : ""}
          onClick={() => handleItemClick("productTags")}
          data-tooltip-id="tooltip-tags"
          data-tooltip-content="Manage Tags"
        >
          <FaTags />
        </li>
        <li
          className={activeItem === "viewEnquiries" ? "active" : ""}
          onClick={() => handleItemClick("viewEnquiries")}
          data-tooltip-id="tooltip-enquiries"
          data-tooltip-content="View Enquiries"
        >
          <FaEnvelope />
        </li>

        {/* Blogs with Modal */}
        <li
          className={activeItem.startsWith("blog") ? "active" : ""}
          onClick={openModal}
          data-tooltip-id="tooltip-blogs"
          data-tooltip-content="Manage Blogs"
        >
          <FaBook />
        </li>

        {/* Comment Moderation */}
        <li
          className={activeItem === "moderateComments" ? "active" : ""}
          onClick={() => handleItemClick("moderateComments")}
          data-tooltip-id="tooltip-moderateComments"
          data-tooltip-content="Moderate Comments"
        >
          <FaComment />
        </li>

        {/* Manage Events */}
        <li
          className={activeItem === "manageEvents" ? "active" : ""}
          onClick={() => handleItemClick("manageEvents")}
          data-tooltip-id="tooltip-events"
          data-tooltip-content="Manage Events"
        >
          <FaCalendarAlt />
        </li>

        {/* Manage Subscribers */}
        <li
          className={activeItem === "manageSubscribers" ? "active" : ""}
          onClick={() => handleItemClick("manageSubscribers")}
          data-tooltip-id="tooltip-manageSubscribers"
          data-tooltip-content="Manage Subscribers"
        >
          <FaEnvelopeOpenText />
        </li>

        {/* Manage Marketing Emails */}
        <li
          className={activeItem === "manageEmails" ? "active" : ""}
          onClick={() => handleItemClick("manageEmails")}
          data-tooltip-id="tooltip-emails"
          data-tooltip-content="Manage Emails"
        >
          <FaEnvelope />
        </li>
      </ul>

      {/* Single ReactTooltip Initialization */}
      <ReactTooltip
        id="tooltip-users"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-addProduct"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-categories"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-tags"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-enquiries"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-blogs"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-moderateComments"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-events"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-manageSubscribers"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />
      <ReactTooltip
        id="tooltip-emails"
        place="right"
        effect="solid"
        className="custom-tooltip"
      />

      {/* Modal for Blog Options */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Manage Blogs"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-header">
          <h3>Manage Blogs</h3>
        </div>
        <ul className="list-options">
          <li
            className={activeItem === "viewBlogs" ? "active" : ""}
            onClick={() => handleItemClick("viewBlogs")}
          >
            View Blogs
          </li>
          <li
            className={activeItem === "addBlog" ? "active" : ""}
            onClick={() => handleItemClick("addBlog")}
          >
            Add Blog
          </li>
        </ul>
        <div className="modal-footer">
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSidebar;

import React, { useState, useEffect } from 'react';
import axios from "../../utils/axiosConfig"; 
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminProductTags.css';

const AdminProductTags = () => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editTagId, setEditTagId] = useState(null);

  // Base URL for the API from environment variable
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/tags`);
        setTags(response.data);
        toast.success('Tags fetched successfully.');
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast.error('Error fetching tags.');
      }
    };

    fetchTags();
  }, [API_BASE_URL]);

  const handleAddOrUpdateTag = async () => {
    if (newTag.trim() === '') return;

    try {
      if (isEditing) {
        await axios.put(`${API_BASE_URL}/tags/${editTagId}`, { name: newTag });
        setIsEditing(false);
        setEditTagId(null);
        toast.success('Tag updated successfully.');
      } else {
        await axios.post(`${API_BASE_URL}/tags`, { name: newTag });
        toast.success('Tag added successfully.');
      }
      setNewTag('');
      const response = await axios.get(`${API_BASE_URL}/tags`);
      setTags(response.data);
    } catch (error) {
      console.error('Error saving tag:', error);
      toast.error('Error saving tag.');
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tags/${id}`);
      setNewTag(response.data.name);
      setIsEditing(true);
      setEditTagId(id);
      toast.success('Tag fetched successfully for editing.');
    } catch (error) {
      console.error('Error fetching tag:', error);
      toast.error('Error fetching tag.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/tags/${id}`);
      setTags(tags.filter(tag => tag.id !== id));
      toast.success('Tag deleted successfully.');
    } catch (error) {
      console.error('Error deleting tag:', error);
      toast.error('Error deleting tag.');
    }
  };

  return (
    <div className="admin-product-tags">
      <h1>Product Tags</h1>
      <div className="add-tag">
        <input
          type="text"
          placeholder="Add new tag"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
        />
        <button onClick={handleAddOrUpdateTag}>
          {isEditing ? 'Update Tag' : 'Add Tag'}
        </button>
      </div>
      <table className="admin-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tags.map(tag => (
            <tr key={tag.id}>
              <td>{tag.name}</td>
              <td>
                <FaEdit className="action-icon" onClick={() => handleEdit(tag.id)} />
              </td>
              <td>
                <FaTrash className="action-icon" onClick={() => handleDelete(tag.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default AdminProductTags;

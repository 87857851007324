import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PartnerCarousel.css";

const PartnerCarousel = () => {
  const partners = [
    {
      src: "/assets/images/software-platform-logos/microsoft-dynamics-365-logo.webp",
      alt: "Microsfot Dynamics 365 Logo",
    },
    {
      src: "/assets/images/software-platform-logos/pipedrive-logo.webp",
      alt: "Pipedrive CRM Logo",
    },
    {
      src: "/assets/images/software-platform-logos/freshsales-logo.webp",
      alt: "Freshsales CRM Logo",
    },
    {
      src: "/assets/images/software-platform-logos/wordpress-logo.webp",
      alt: "WordPress Logo",
    },
    {
      src: "/assets/images/software-platform-logos/paypal-logo.webp",
      alt: "PayPal Logo",
    },
    {
      src: "/assets/images/software-platform-logos/payfast-logo.webp",
      alt: "Payfast Logo",
    },
    {
      src: "/assets/images/software-platform-logos/stripe-logo.webp",
      alt: "Stripe Logo",
    },
    {
      src: "/assets/images/software-platform-logos/paygate-logo.webp",
      alt: "Paygate Logo",
    },
    {
      src: "/assets/images/software-platform-logos/syspro-logo.webp",
      alt: "Syspro Logo",
    },
    {
      src: "/assets/images/software-platform-logos/zoho-books-logo.webp",
      alt: "Zoho Books Logo",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="partner-carousel">
      <h2 className="partner-carousel-heading">
        Some Platforms <br /><br />That We Have Integrated With
      </h2>
      <Slider {...settings}>
        {partners.map((partner, index) => (
          <div key={index} className="partners-slide">
            <Link to="#" className="client-logo">
              <figure className="partners-slide-inner">
                <img
                  className="partners-slide-image"
                  src={`${process.env.PUBLIC_URL}${partner.src}`}
                  alt={partner.alt}
                />
              </figure>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnerCarousel;

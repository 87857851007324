import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  fetchComments,
  submitComment,
  deleteComment,
  updateComment,
  toggleCommentLike,
  reportComment,
  incrementCommentCount,
  decrementCommentCount,
} from "../../utils/commentService";
import ModalReportComment from "../ModalReportComment/ModalReportComment"; // Import the modal component
import "./Comments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faReply,
  faThumbsUp,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";

const Comments = ({ blogId }) => {
  const { auth } = useAuth();
  const userId = auth?.userId ? String(auth.userId) : null; // Ensure userId is a string
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [editingText, setEditingText] = useState("");
  const [expandedReplies, setExpandedReplies] = useState({}); // Track expanded replies

  // Modal state for reporting
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportData, setReportData] = useState(null); // Stores the comment being reported

  useEffect(() => {
    const getComments = async () => {
      try {
        const data = await fetchComments(blogId);
        // Filter out reported comments and their replies
        const filteredComments = data
          .filter((comment) => comment.status == "active")
          .map((comment) => ({
            ...comment,
            Replies: comment.Replies
              ? comment.Replies.filter((reply) => reply.status == "active")
              : [],
          }));
        setComments(filteredComments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    getComments();
  }, [blogId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newComment.trim() && userId) {
      try {
        const addedComment = await submitComment(blogId, userId, newComment);
        setComments([...comments, addedComment]);
        setNewComment("");
        await incrementCommentCount(blogId); // Increment comment count when a new comment is added
      } catch (error) {
        console.error("Error submitting comment:", error);
      }
    }
  };

  const handleReply = async (commentId, replyText) => {
    if (replyText.trim() && userId) {
      try {
        const addedReply = await submitComment(
          blogId,
          userId,
          replyText,
          commentId // This should be passed as parentCommentId
        );

        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  Replies: [...(comment.Replies || []), addedReply],
                }
              : comment
          )
        );

        setReplyCommentId(null);
        setReplyText("");
        await incrementCommentCount(blogId); // Increment comment count when a reply is added
      } catch (error) {
        console.error("Error submitting reply:", error);
      }
    }
  };

  const handleEdit = async (
    commentId,
    newCommentText,
    isReply = false,
    parentCommentId = null
  ) => {
    try {
      const updatedComment = await updateComment(commentId, newCommentText);

      if (isReply && parentCommentId) {
        // Update a reply in the state
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === parentCommentId
              ? {
                  ...comment,
                  Replies: comment.Replies.map((reply) =>
                    reply.id === commentId ? updatedComment : reply
                  ),
                }
              : comment
          )
        );
      } else {
        // Update a top-level comment in the state
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId ? updatedComment : comment
          )
        );
      }

      setEditingComment(null); // Clear the editing state after saving
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async (
    commentId,
    isReply = false,
    parentCommentId = null
  ) => {
    try {
      await deleteComment(commentId);

      // If deleting a top-level comment, filter it out
      if (!isReply) {
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
      } else {
        // If deleting a reply, filter it out from the correct comment's Replies array
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === parentCommentId
              ? {
                  ...comment,
                  Replies: comment.Replies.filter(
                    (reply) => reply.id !== commentId
                  ),
                }
              : comment
          )
        );
      }

      await decrementCommentCount(blogId); // Decrement comment count when a comment or reply is deleted
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleLike = async (
    commentId,
    isReply = false,
    parentCommentId = null
  ) => {
    try {
      const updatedComment = await toggleCommentLike(commentId, userId);

      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (isReply && comment.id === parentCommentId) {
            return {
              ...comment,
              Replies: comment.Replies.map((reply) =>
                reply.id === commentId ? { ...updatedComment } : reply
              ),
            };
          }

          return comment.id === commentId
            ? {
                ...updatedComment,
                Replies: comment.Replies || [],
              }
            : comment;
        })
      );
    } catch (error) {
      console.error("Error liking/unliking comment/reply:", error);
    }
  };

  const handleOpenReportModal = (commentId, isReply = false, parentCommentId = null) => {
    setReportData({ commentId, isReply, parentCommentId });
    setIsModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setIsModalOpen(false);
    setReportData(null);
  };

  const handleReportSubmit = async (reportDetails) => {
    const { commentId, isReply, parentCommentId } = reportData;
  
    try {
      const reportDataToSend = {
        reportedByUserId: userId, // Pass the current user's ID
        reason: reportDetails.selectedReason, // Reason selected in the modal
        additionalReason: reportDetails.additionalReason || '', // Additional reason if provided
        blogId, // Blog post ID
      };

      const updatedComment = await reportComment(commentId, reportDataToSend); // Send the report data to the backend
  
      if (isReply && parentCommentId) {
        // Remove the reported reply from the state
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === parentCommentId
              ? {
                  ...comment,
                  Replies: comment.Replies.filter(
                    (reply) => reply.id !== commentId
                  ),
                }
              : comment
          )
        );
      } else {
        // Remove the reported top-level comment from the state
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
      }
  
      handleCloseReportModal();
    } catch (error) {
      console.error("Error reporting comment:", error);
    }
  };
  
  
  

  const toggleReplies = (commentId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const renderActions = (item, isComment, parentCommentId = null) => {
    const itemUserId = String(item.User?.id || "");
    const isOwner = itemUserId === userId; // Check if the current user is the owner of the comment/reply
    const isReported = item.status === "reported"; // Check if the comment is reported

    return isOwner ? (
      <>
        {/* Show the like count but disable the button for the owner */}
        <span className="likes-info">
          <FontAwesomeIcon icon={faThumbsUp} /> {item.likes || 0}
        </span>
        <button
          className="icon-button"
          onClick={() => {
            setEditingComment(item.id);
            setEditingText(item.comment);
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> Edit
        </button>
        <button
          className="icon-button"
          onClick={() => handleDelete(item.id, !isComment, parentCommentId)}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </button>
        {isComment && (
          <button
            className="icon-button"
            onClick={() => setReplyCommentId(item.id)}
          >
            <FontAwesomeIcon icon={faReply} /> Add Comment
          </button>
        )}
      </>
    ) : (
      <>
        <button
          className="icon-button"
          onClick={() => handleLike(item.id, !isComment, parentCommentId)}
        >
          <FontAwesomeIcon icon={faThumbsUp} /> {item.likes || 0} {/* Display likes */}
        </button>
        {isComment && (
          <button
            className="icon-button"
            onClick={() => setReplyCommentId(item.id)}
          >
            <FontAwesomeIcon icon={faReply} /> Reply
          </button>
        )}
        {/* Report Button: turns red if already reported */}
        <button
          className="icon-button"
          onClick={() => handleOpenReportModal(item.id, !isComment, parentCommentId)} // Open modal on report click
          style={{ color: isReported ? "red" : "inherit" }}
          disabled={isReported} // Disable the button if the comment is already reported
        >
          <FontAwesomeIcon icon={faFlag} /> Report
        </button>
      </>
    );
  };

  const renderReplies = (replies, parentCommentId) => {
    return (
      <>
        {replies.length > 1 && !expandedReplies[parentCommentId] && (
          <button
            className="read-more-button"
            onClick={() => toggleReplies(parentCommentId)}
          >
            Read more ({replies.length - 1} more replies)
          </button>
        )}
        {replies
          .slice(0, expandedReplies[parentCommentId] ? replies.length : 1)
          .map((reply) => (
            <li key={reply.id} className="reply-item">
              <div className="content-and-date">
                <div className="reply-content">
                  <strong>{reply.User?.username || "Unknown User"}</strong>:{" "}
                  {reply.comment}
                </div>
                <small>{new Date(reply.createdAt).toLocaleDateString()}</small>
              </div>
              <div className="reply-actions">
                {renderActions(reply, false, parentCommentId)}
              </div>

              {/* Edit form for the reply */}
              {editingComment === reply.id && (
                <div className="edit-block">
                  <form
                    className="edit-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEdit(reply.id, editingText, true, parentCommentId); // Pass isReply as true and parentCommentId
                    }}
                  >
                    <textarea
                      value={editingText}
                      onChange={(e) => setEditingText(e.target.value)}
                      required
                    />
                    <button type="submit">Save</button>
                  </form>
                </div>
              )}
            </li>
          ))}
        {replies.length > 1 && expandedReplies[parentCommentId] && (
          <button
            className="read-more-button"
            onClick={() => toggleReplies(parentCommentId)}
          >
            Show less
          </button>
        )}
      </>
    );
  };

  return (
    <div className="comments-section">
      <h3>Comments</h3>
      <ul className="comments-list">
        {comments.map((comment) => (
          <li key={comment.id} className="comment-item">
            <div className="comment-content">
              <p>
                <strong>{comment.User?.username || "Unknown User"}</strong>:{" "}
                {comment.comment}
              </p>
            </div>
            <small>{new Date(comment.createdAt).toLocaleDateString()}</small>
            <div className="comment-actions">
              {renderActions(comment, true)}
            </div>

            {replyCommentId === comment.id && (
              <form
                className="reply-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleReply(comment.id, replyText);
                }}
              >
                <textarea
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  placeholder="Write a reply..."
                  required
                />
                <button type="submit">Submit Reply</button>
              </form>
            )}

            {/* Render nested replies */}
            {comment.Replies && comment.Replies.length > 0 && (
              <ul className="replies-list">
                {renderReplies(comment.Replies, comment.id)}
              </ul>
            )}

            {editingComment === comment.id && (
              <div className="edit-block">
                {" "}
                {/* Wrap in a div to push it below the buttons */}
                <form
                  className="edit-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEdit(comment.id, editingText);
                  }}
                >
                  <textarea
                    value={editingText}
                    onChange={(e) => setEditingText(e.target.value)}
                    required
                  />
                  <button type="submit">Save</button>
                </form>
              </div>
            )}
          </li>
        ))}
      </ul>

      <form className="comment-form" onSubmit={handleSubmit}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a comment..."
          required
        />
        <button type="submit" className="comment-submit-button">
          Submit Comment
        </button>
      </form>

      {/* Report Modal */}
      <ModalReportComment
        isOpen={isModalOpen}
        onClose={handleCloseReportModal}
        onSubmit={handleReportSubmit}
      />
    </div>
  );
};

export default Comments;

// File: AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { clearAuthData } from "../utils/authService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    token: null,
    userId: null,
    role: null,
    username: null,
  });
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUserId = localStorage.getItem("userId");
    const storedRole = localStorage.getItem("role");
    const storedUsername = localStorage.getItem("username");

    if (storedToken && storedUserId && storedRole && storedUsername) {
      setAuth({
        isAuthenticated: true,
        token: storedToken,
        userId: storedUserId,
        role: storedRole,
        username: storedUsername,
      });
    }
    setLoading(false); // Set loading to false after initial check
  }, []);

  const login = (token, userId, role, username) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    localStorage.setItem("role", role);
    localStorage.setItem("username", username);

    setAuth({
      isAuthenticated: true,
      token,
      userId,
      role,
      username,
    });
  };

  const logout = () => {
    clearAuthData();
    setAuth({
      isAuthenticated: false,
      token: null,
      userId: null,
      role: null,
      username: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

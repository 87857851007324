import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Helmet } from "react-helmet-async";
import { useCart } from "../context/CartContext";
import { useExchangeRate } from "../context/ExchangeRateContext";
import {
  saveBillingDetails,
  getBillingDetails,
} from "../utils/billingInfoService";
import { fetchProducts } from "../utils/productService";
import "./Checkout.css";
import useBackToTop from "../utils/useBackToTop";

const Checkout = () => {
  useBackToTop(500, true);
  const { cart, clearCart } = useCart();
  const { exchangeRates, currencyCode } = useExchangeRate();
  const [billingDetails, setBillingDetails] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    country: "",
    street_address: "",
    postcode: "",
    city: "",
    phone: "",
    accounts_email: "",
  });
  const [fullCart, setFullCart] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem("userId");

  // Define currency symbols for each currency code
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ZAR: "R",
    AUD: "A$",
    CAD: "C$",
    JPY: "¥",
    CNY: "¥",
    CHF: "Fr",
    NZD: "$",
    SGD: "S$",
    HKD: "HK$",
    INR: "₹",
    BRL: "R$",
    MXN: "$",
    SEK: "kr",
    NOK: "kr",
    RUB: "₽",
    KRW: "₩",
    PLN: "zł",
    TRY: "₺",
    THB: "฿",
    IDR: "Rp",
    MYR: "RM",
    PHP: "₱",
    DKK: "kr",
    HUF: "Ft",
    CZK: "Kč",
    ILS: "₪",
    AED: "د.إ",
    SAR: "﷼",
    CLP: "$",
    COP: "$",
    PEN: "S/.",
    PKR: "₨",
    VND: "₫",
    BDT: "৳",
    NGN: "₦",
    EGP: "£",
    QAR: "﷼",
    KWD: "د.ك",
  };

  // Helper function to get the currency symbol based on currency code
  const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode] || "$";
  };

  // Fetch billing details on component mount
  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {
        const data = await getBillingDetails(userId);
        if (data) {
          setBillingDetails({
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            company_name: data.company_name || "",
            country: data.country || "",
            street_address: data.street_address || "",
            postcode: data.postcode || "",
            city: data.city || "",
            phone: data.phone || "",
            accounts_email: data.accounts_email || "",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error loading billing details:", error);
        setLoading(false);
      }
    };

    fetchBillingDetails();
  }, [userId]);

  // Fetch product details for the cart
  useEffect(() => {
    const fetchFullCartDetails = async () => {
      if (cart.length > 0) {
        const productIds = cart.map((item) => item.productId);
        try {
          const products = await fetchProducts(productIds);
          const updatedCart = cart.map((item) => {
            const product = products.find((p) => p.id === item.productId);
            return {
              ...item,
              name: product?.name || "Unknown",
              // Use salePrice if the product is on sale, otherwise use regular price
              price: product?.sale ? product.salePrice : product.price,
              image: product?.image || "",
              link: `/single-product/${product?.id || item.productId}`,
            };
          });
          setFullCart(updatedCart);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      } else {
        setFullCart([]);
      }
    };

    fetchFullCartDetails();
  }, [cart]);

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const validateBillingDetails = () => {
    const newErrors = {};

    if (!billingDetails.first_name.trim())
      newErrors.first_name = "First name is required";
    if (!billingDetails.last_name.trim())
      newErrors.last_name = "Last name is required";
    if (!billingDetails.country.trim())
      newErrors.country = "Country is required";
    if (!billingDetails.street_address.trim())
      newErrors.street_address = "Street address is required";
    if (!billingDetails.city.trim()) newErrors.city = "City is required";
    if (!billingDetails.phone.trim())
      newErrors.phone = "Phone number is required";
    if (!billingDetails.accounts_email.trim())
      newErrors.accounts_email = "Email address is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveBilling = async () => {
    if (validateBillingDetails()) {
      try {
        await saveBillingDetails(userId, billingDetails);
        alert("Billing information saved successfully");
      } catch (error) {
        console.error("Error saving billing information:", error);
        alert("Failed to save billing information");
      }
    } else {
      alert("Please fill in all required billing information.");
    }
  };

  const handleCreateOrder = (data, actions) => {
    if (!validateBillingDetails()) {
      alert("Please fill in all required billing information.");
      return actions.reject();
    }

    const usdConversionRate = exchangeRates["USD"] || 1;
    const cartTotalZAR = fullCart.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    const totalUSD = (cartTotalZAR * usdConversionRate).toFixed(2);

    return fetch(`${process.env.REACT_APP_API_URL}/create-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        items: fullCart.map((item) => ({
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
        })),
        total: totalUSD,
        currency: "USD",
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((order) => order.id)
      .catch((err) => {
        console.error("Error in handleCreateOrder:", err);
        alert("Error creating order. Please check console for details.");
        return actions.reject();
      });
  };

  const handleApproveOrder = async (data, actions) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/capture-order/${data.orderID}`,
        { method: "POST" }
      );
      const details = await response.json();
      alert("Transaction completed by " + details.payer.name.given_name);

      await saveBillingDetails(userId, billingDetails);
      clearCart();
    } catch (err) {
      console.error("Error capturing order:", err);
    }
  };

  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    return (amountInZAR * conversionRate).toFixed(2);
  };

  const cartTotal = fullCart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const convertedCartTotal = convertToCurrency(cartTotal);

  if (loading) {
    return <p>Loading billing details...</p>;
  }

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://www.paypal.com" />
      </Helmet>
      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
      >
        <div id="content" className="site-content">
          <div className="page-header flex-middle">
            <div className="container">
              <div className="inner flex-middle">
                <h1 className="page-title">Checkout</h1>
                <ul id="breadcrumbs" className="breadcrumbs none-style">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Checkout</li>
                </ul>
              </div>
            </div>
          </div>
          <section className="checkout-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="billing-details">
                    <h3>Billing Details</h3>
                    <div className="billing-fields">
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <label htmlFor="first_name">First Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            value={billingDetails.first_name}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.first_name && (
                            <p className="error-text">{errors.first_name}</p>
                          )}
                        </div>
                        <div className="col-lg-6 form-group">
                          <label htmlFor="last_name">Last Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            value={billingDetails.last_name}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.last_name && (
                            <p className="error-text">{errors.last_name}</p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="company_name">
                            Company Name (optional)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="company_name"
                            value={billingDetails.company_name}
                            onChange={handleBillingChange}
                          />
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="country">Country / Region *</label>
                          <select
                            name="country"
                            className="form-control select-country"
                            value={billingDetails.country}
                            onChange={handleBillingChange}
                            required
                          >
                            <option value="">Select a country / region…</option>
                            <option value="VN">Vietnam</option>
                            <option value="US">United States</option>
                            <option value="GB">United Kingdom</option>
                          </select>
                          {errors.country && (
                            <p className="error-text">{errors.country}</p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="street_address">
                            Street Address *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="street_address"
                            value={billingDetails.street_address}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.street_address && (
                            <p className="error-text">
                              {errors.street_address}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="postcode">Postcode / ZIP</label>
                          <input
                            type="text"
                            className="form-control"
                            name="postcode"
                            value={billingDetails.postcode}
                            onChange={handleBillingChange}
                          />
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="city">Town / City *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={billingDetails.city}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.city && (
                            <p className="error-text">{errors.city}</p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="phone">Phone *</label>
                          <input
                            type="tel"
                            className="form-control"
                            name="phone"
                            value={billingDetails.phone}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.phone && (
                            <p className="error-text">{errors.phone}</p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <label htmlFor="accounts_email">
                            Email Address *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="accounts_email"
                            value={billingDetails.accounts_email}
                            onChange={handleBillingChange}
                            required
                          />
                          {errors.accounts_email && (
                            <p className="error-text">
                              {errors.accounts_email}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-12 form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleSaveBilling}
                          >
                            Save Billing Information
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <h3 className="order-review-heading">Your Order</h3>
                  <table className="order-review-table">
                    <thead>
                      <tr>
                        <th className="product-name">Product</th>
                        <th className="product-total">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fullCart.map((item) => (
                        <tr className="cart-item" key={item.productId}>
                          <td className="product-name">
                            {item.name}{" "}
                            <strong className="product-quantity">
                              × {item.quantity}
                            </strong>
                          </td>
                          <td className="product-total">
                            <span className="amount">
                              {getCurrencySymbol(currencyCode)}{" "}
                              {convertToCurrency(item.price * item.quantity)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className="cart-subtotal">
                        <th>Subtotal</th>
                        <td>
                          <span className="amount">
                            {getCurrencySymbol(currencyCode)} {convertedCartTotal}
                          </span>
                        </td>
                      </tr>
                      <tr className="order-total">
                        <th>Total</th>
                        <td>
                          <strong>
                            <span className="amount">
                              {getCurrencySymbol(currencyCode)} {convertedCartTotal}
                            </span>
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div id="payment" className="checkout-payment">
                    <PayPalButtons
                      createOrder={(data, actions) =>
                        handleCreateOrder(data, actions)
                      }
                      onApprove={(data, actions) =>
                        handleApproveOrder(data, actions)
                      }
                      onError={(err) => {
                        console.error("PayPal error occurred:", err);
                        alert("There was an issue with PayPal: " + err);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

export default Checkout;

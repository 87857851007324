import React, { useEffect, useState } from 'react';
import './AdminViewEnquiries.css';
import Modal from 'react-modal';
import { fetchEnquiries } from '../../utils/apiServices';

const AdminViewEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getEnquiries = async () => {
      try {
        const data = await fetchEnquiries();
        setEnquiries(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching enquiries:', error);
        setLoading(false);
      }
    };
    getEnquiries();
  }, []);

  // Helper to extract website and clean message from HTML tags
  const parseMessage = (rawMessage) => {
    // Use regex to remove HTML tags and extract website
    const websiteMatch = rawMessage.match(/Website:\s*<\/strong>\s*(\S+)/);
    const website = websiteMatch ? websiteMatch[1] : 'N/A';
  
    // Extract the entire message portion (after "Message:"), capturing multiple paragraphs
    const messageMatch = rawMessage.match(/Message:\s*<\/strong>\s*([\s\S]+)/);
    const message = messageMatch ? messageMatch[1].replace(/<\/?[^>]+(>|$)/g, '') : 'No message provided'; // Strip HTML tags
  
    return { website, message };
  };
  
  

  const openModal = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEnquiry(null);
    setIsModalOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-view-enquiries">
      <h2>Enquiries</h2>
      <table className="enquiries-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Website</th>
            <th>Message</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {enquiries.map((enquiry) => {
            const { website, message } = parseMessage(enquiry.message);

            return (
              <tr key={enquiry.id}>
                <td>{enquiry.name || 'N/A'}</td>
                <td>{typeof enquiry.email === 'string' ? enquiry.email : 'Invalid email'}</td>
                <td>{website}</td>
                <td>{message.slice(0, 100)}...</td> {/* Show a preview of the message */}
                <td>{new Date(enquiry.createdAt).toLocaleString()}</td>
                <td>
                  <button onClick={() => openModal(enquiry)} className="view-icon-btn">
                    <i className="fa fa-eye view-icon"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal for viewing the entire enquiry */}
      {selectedEnquiry && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="custom-modal"
          overlayClassName="custom-overlay"
          contentLabel="Enquiry Details"
        >
          <h2>Enquiry Details</h2>
          <div className="modal-row">
            <p className='modal-label'><strong>Name:</strong></p> <span>{selectedEnquiry.name}</span>
          </div>
          <div className="modal-row">
          <p className='modal-label'><strong>Email:</strong></p> <span>{selectedEnquiry.email}</span>
          </div>
          <div className="modal-row">
          <p className='modal-label'><strong>Website:</strong></p> <span>{parseMessage(selectedEnquiry.message).website}</span>
          </div>
          <div className="modal-row">
            <strong>Message:</strong>
            <div className="modal-message">
              {parseMessage(selectedEnquiry.message).message}
            </div>
          </div>
          <div className="modal-row">
            <strong>Date Submitted:</strong> <span>{new Date(selectedEnquiry.createdAt).toLocaleString()}</span>
          </div>
          <button onClick={closeModal} className="close-button">Close</button>
        </Modal>
      )}
    </div>
  );
};

export default AdminViewEnquiries;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SectionWhatWeActuallyDo.css';

const SectionWhatWeActuallyDo = () => {
  const [activeTech, setActiveTech] = useState('web');

  // Function to render content dynamically based on the selected icon
  const renderTechContent = () => {
    switch (activeTech) {
      case 'web':
        return (
          <>
            <h5>Automate Business Processes</h5>
            <p>
              We create custom solutions that automate repetitive tasks, streamline workflows,
              and improve overall productivity, allowing businesses to focus on strategic growth.
            </p>
          </>
        );
      case 'mobile':
        return (
          <>
            <h5>Improve Internal Process Efficiency</h5>
            <p>
              Our solutions are designed to enhance internal operations by reducing bottlenecks,
              improving communication, and boosting overall efficiency within teams.
            </p>
          </>
        );
      case 'native':
        return (
          <>
            <h5>Generate New Business Leads</h5>
            <p>
              We build lead-generation tools that help attract new customers and retain existing ones,
              driving more sales and increasing brand visibility in your target market.
            </p>
          </>
        );
      case 'integration':
        return (
          <>
            <h5>Create Brand Awareness</h5>
            <p>
              Our digital solutions help establish a strong brand presence online, reaching wider audiences
              and creating meaningful engagement through innovative marketing strategies.
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section className="about-v5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="pabout-left">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/image1-about.png`}
                alt="About Us"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pabout-right">
              <div className="ot-heading">
                <span>Experience. Execution. Excellence.</span>
                <h2 className="main-heading">What We Actually Do</h2>
              </div>

              {/* Icon Selection Section */}
              <div className="about-tech-wrap">
                <div
                  className={`about-tech-box ${activeTech === 'web' ? 'active' : ''}`}
                  data-tech="web"
                  onClick={() => setActiveTech('web')}
                >
                  <div className="icon-main">
                    <span className="flaticon-php"></span>
                  </div>
                </div>
                <div
                  className={`about-tech-box ${activeTech === 'mobile' ? 'active' : ''}`}
                  data-tech="mobile"
                  onClick={() => setActiveTech('mobile')}
                >
                  <div className="icon-main">
                    <span className="flaticon-electron"></span>
                  </div>
                </div>
                <div
                  className={`about-tech-box ${activeTech === 'native' ? 'active' : ''}`}
                  data-tech="native"
                  onClick={() => setActiveTech('native')}
                >
                  <div className="icon-main">
                    <span className="flaticon-java"></span>
                  </div>
                </div>
                <div
                  className={`about-tech-box ${activeTech === 'integration' ? 'active' : ''}`}
                  data-tech="integration"
                  onClick={() => setActiveTech('integration')}
                >
                  <div className="icon-main">
                    <span className="flaticon-css"></span>
                  </div>
                </div>
              </div>

              {/* Dynamic Content Display */}
              <div className="tech-content">
                {renderTechContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWhatWeActuallyDo;

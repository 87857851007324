import React from "react";
import "./SectionConsultation.css";
import { Link } from "react-router-dom";

const SectionConsultation = () => {
  return (
    <section className="section-consultation">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p>Drop us a line! We are here to answer your questions</p>
            <h2>NEED A CONSULTATION?</h2>
            <div className="ot-button">
              <Link to="/contact" className="contact-us-button">
                CONTACT US
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionConsultation;

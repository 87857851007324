// File: context/ExchangeRateContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "../utils/axiosConfig";

const ExchangeRateContext = createContext();

export const ExchangeRateProvider = ({ children }) => {
  const [exchangeRates, setExchangeRates] = useState({});
  const [currencyCode, setCurrencyCode] = useState("ZAR");

  const fetchExchangeRates = async (baseCurrency) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/exchange-rates?base=${baseCurrency}`
      );
      setExchangeRates(response.data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };  

  const detectUserCurrency = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const userCurrency = response.data.currency;
      setCurrencyCode(userCurrency);
      fetchExchangeRates("ZAR");
    } catch (error) {
      console.error("Error fetching user location:", error);
      fetchExchangeRates("ZAR");
    }
  };

  const updateCurrencyCode = (newCurrency) => {
    setCurrencyCode(newCurrency);
  };

  useEffect(() => {
    detectUserCurrency();
  }, []);

  return (
    <ExchangeRateContext.Provider
      value={{ exchangeRates, currencyCode, updateCurrencyCode }}
    >
      {children}
    </ExchangeRateContext.Provider>
  );
};

export const useExchangeRate = () => useContext(ExchangeRateContext);

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchBlogs } from "../utils/blogService";
import BlogSidebar from "../components/BlogSidebar/BlogSidebar";
import useBackToTop from "../utils/useBackToTop";
import "./Blog.css";

const Blog = () => {
  useBackToTop(500, true);
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const blogsPerPage = 12;

  const location = useLocation();
  const navigate = useNavigate();

  // Function to load blogs
  const loadBlogs = async () => {
    try {
      const data = await fetchBlogs(1, 100); // Load all blogs (or as many as needed)
      setBlogs(data.blogs);
      setFilteredBlogs(data.blogs); // Set the filteredBlogs to all blogs initially
      setTotalPages(Math.ceil(data.blogs.length / blogsPerPage)); // Set total pages
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleFilterByCategory = (category = null) => {
    const queryParams = new URLSearchParams(location.search);
    const selectedCategory = category || queryParams.get("category");

    let filtered = blogs;
    if (selectedCategory && selectedCategory !== "All Categories") {
      filtered = blogs.filter(
        (blog) => blog.category.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    setFilteredBlogs(filtered);
    setTotalPages(Math.ceil(filtered.length / blogsPerPage));
    setCurrentPage(1); // Reset to the first page after filtering
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");

    if (blogs.length === 0) {
      loadBlogs(); // Load all blogs when component mounts
    }

    // Apply filtering if there's a category in the URL
    if (category) {
      handleFilterByCategory(category);
    }
  }, [location.search]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Helmet>
        <title>Blog - TORIS Technologies</title>
        <meta
          name="description"
          content="Explore the latest insights, tips, and trends in software development, web applications, and technology innovation. Stay updated with TORIS Technologies' blog posts."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies Blog, software development articles, web applications, tech trends, custom software"
        />
        <link rel="canonical" href="https://toris.co.za/blog" />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Blog Grid</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Blog Grid</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="row">
            {/* Main Blog Content */}
            <div className="col-lg-9 col-md-8 col-sm-12">
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              )}
              <div className="blog-grid pgrid">
                <div className="row">
                  {filteredBlogs
                    .slice(
                      (currentPage - 1) * blogsPerPage,
                      currentPage * blogsPerPage
                    )
                    .map((blog) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 blog-post-card"
                        key={blog.id}
                      >
                        <article className="post-box blog-item">
                          <div className="post-inner">
                            <div className="entry-media">
                              <div className="post-cat">
                                <span className="posted-in">
                                  <Link to="#" rel="category tag">
                                    {blog.category}
                                  </Link>
                                </span>
                              </div>
                              <Link to={`/post/${blog.id}`}>
                                <img
                                  src={`${process.env.REACT_APP_STATIC_BASE_URL}${blog.image_url}`}
                                  alt={blog.title}
                                />
                              </Link>
                            </div>
                            <div className="inner-post">
                              <div className="entry-header">
                                <div className="entry-meta">
                                  <span className="posted-on">
                                    <Link to="#">
                                      {formatDate(blog.post_date)}
                                    </Link>
                                  </span>
                                  <br />
                                  <span className="byline">
                                    <Link className="url fn n" to="#">
                                      {blog.author}
                                    </Link>
                                  </span>
                                  <br />
                                  <span className="comment-num">
                                    <Link to="#">
                                      {blog.comments_count} Comments
                                    </Link>
                                  </span>
                                </div>
                                <h3 className="entry-title">
                                  <Link to={`/post/${blog.id}`}>
                                    {blog.title}
                                  </Link>
                                </h3>
                              </div>
                              <div className="btn-readmore">
                                <Link to={`/post/${blog.id}`}>
                                  <i className="flaticon-right-arrow-1"></i>
                                  READ MORE
                                </Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    ))}
                </div>
              </div>
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {/* Sidebar */}
            <div className="col-lg-3 col-md-4 col-sm-12">
              <BlogSidebar
                onFilterByCategory={handleFilterByCategory}
                scrollToTop={scrollToTop}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;

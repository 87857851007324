// File: utils/fetchProducts.js
import axios from "./axiosConfig";

// Function to fetch all products from the backend
export const fetchProducts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products`
    );
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.warn("Failed to fetch products:", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

// Fetch only available products (used by Shop)
export const fetchAvailableProducts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products`
    );
    if (response.status === 200 && response.data) {
      // Filter products based on availability
      return response.data.filter((product) => product.available);
    } else {
      console.warn("Failed to fetch available products:", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching available products:", error);
    return [];
  }
};

// New function to fetch only featured products
export const fetchFeaturedProducts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/featured`
    );
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.warn("Failed to fetch featured products:", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching featured products:", error);
    return [];
  }
};

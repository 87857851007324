// File: /src/utils/axiosConfig.js
import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Refresh token function
const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token found");
    }

    // Make the refresh token request
    const response = await axiosInstance.post("/auth/refresh", {
      token: refreshToken,
    });

    if (response.data.accessToken) {
      localStorage.setItem("token", response.data.accessToken);
      return response.data.accessToken;
    }

    throw new Error("Failed to obtain new access token");
  } catch (error) {
    console.error("Error refreshing token:", error);
    clearAuthData(); // Clear auth data in case of error
    window.location.href = "/login"; // Redirect to login
    return null;
  }
};

// Clear auth data from localStorage
const clearAuthData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userId");
  localStorage.removeItem("role");
  localStorage.removeItem("username");
};

// Axios request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If a 401 error is encountered, attempt to refresh the token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await refreshToken();
      if (newToken) {
        // Set the new token in headers and retry the original request
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }
    }

    // If the error is not a 401 or token refresh fails, clear auth data
    if (error.response?.status === 401) {
      clearAuthData();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useState } from 'react';
import { addSubscriber } from '../../utils/subscriberService';
import './NewsLetterCTA.css';

const NewsLetterCTA = () => {
  // State for form inputs
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  // State for messages
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Add the segment value before sending formData
    const subscriberData = { ...formData, segment: 'newsletter' };

    try {
      // Call the addSubscriber API with subscriberData
      const response = await addSubscriber(subscriberData);
      if (response) {
        setSuccessMessage('Thank you for signing up for our newsletter!');
        setErrorMessage('');
        setFormData({ firstName: '', lastName: '', email: '' });
      }
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
      setErrorMessage('Failed to sign up. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="newsletter-banner">
      <div className="newsletter-content">
        <form onSubmit={handleFormSubmit} className="newsletter-form">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            className="newsletter-input"
          />

          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last Name"
            className="newsletter-input"
          />

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email Address"
            className="newsletter-input"
          />

          <button type="submit" className="newsletter-submit-btn">
            SIGN UP
          </button>
        </form>

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      <div className="newsletter-info">
        <h2 className="newsletter-title">Join Our Community Newsletter</h2>
        <p className="newsletter-description">
          Stay informed about the latest news, events, and exclusive offers. Be a part of our community and never miss an update!
        </p>
        <p className="newsletter-description">
          We ask for your first name and last name to personalize your emails. It's not required, but it would be nice to know who you are!
        </p>
      </div>
    </div>
  );
};

export default NewsLetterCTA;

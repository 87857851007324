import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  addItemToCartDB,
  removeItemFromCartDB,
  updateCartQuantityDB,
  fetchCartFromDB,
  clearCartDB,
} from "../utils/cartService";

// Create Cart Context
const CartContext = createContext();

// Cart Reducer to manage cart state
const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return action.payload;
    case "ADD_TO_CART": {
      const existingItem = state.find(
        (item) => item.productId === action.payload.productId
      );
      if (existingItem) {
        return state.map((item) =>
          item.productId === action.payload.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...state, { ...action.payload, quantity: 1 }];
    }
    case "REMOVE_FROM_CART":
      return state.filter(
        (item) => item.productId !== action.payload.productId
      );
    case "UPDATE_QUANTITY":
      return state.map((item) =>
        item.productId === action.payload.productId
          ? { ...item, quantity: action.payload.quantity }
          : item
      );
    case "CLEAR_CART":
      return [];
    default:
      return state;
  }
};

// Cart Provider
export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, [], () => {
    const localCart = localStorage.getItem("cart");
    return localCart ? JSON.parse(localCart) : [];
  });

  // Store cart in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Sync cart with the backend on component mount
  const syncCart = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (userId && token) {
      try {
        const dbCart = await fetchCartFromDB(userId, token);
        if (dbCart) {
          dispatch({ type: "SET_CART", payload: dbCart });
        }
      } catch (error) {
        console.error("Error syncing cart with backend:", error);
      }
    }
  };

  // Add item to cart
  const addToCart = async (product) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const cartItem = {
      productId: product.id,
      quantity: 1,
      price: product.actualPrice, // Use actualPrice as calculated in ProductCard
    };

    // Update local cart state immediately
    dispatch({ type: "ADD_TO_CART", payload: cartItem });

    // Update backend if user is logged in
    if (userId && token) {
      try {
        await addItemToCartDB(
          userId,
          cartItem.productId,
          cartItem.quantity,
          cartItem.price, // Pass actual price to backend
          token
        );
        // Sync backend with the updated cart
        const updatedCart = await fetchCartFromDB(userId, token);
        dispatch({ type: "SET_CART", payload: updatedCart });
      } catch (error) {
        console.error("Error adding item to cart in the backend:", error);
      }
    }
  };

  // Remove item from cart
  const removeFromCart = async (productId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    // Update local cart state
    dispatch({ type: "REMOVE_FROM_CART", payload: { productId } });

    // Update backend if user is logged in
    if (userId && token) {
      try {
        await removeItemFromCartDB(userId, productId, token);
        // Sync backend with the updated cart
        const updatedCart = await fetchCartFromDB(userId, token);
        dispatch({ type: "SET_CART", payload: updatedCart });
      } catch (error) {
        console.error("Error removing item from cart in the backend:", error);
      }
    }
  };

  // Update item quantity in the cart
  const updateQuantity = async (productId, quantity) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    // Update local cart state
    dispatch({ type: "UPDATE_QUANTITY", payload: { productId, quantity } });

    // Update backend if user is logged in
    if (userId && token) {
      try {
        await updateCartQuantityDB(userId, productId, quantity, token);
        // Sync backend with the updated cart
        const updatedCart = await fetchCartFromDB(userId, token);
        dispatch({ type: "SET_CART", payload: updatedCart });
      } catch (error) {
        console.error("Error updating item quantity in the backend:", error);
      }
    }
  };

  // Clear the entire cart
  const clearCart = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    // Clear local cart state
    dispatch({ type: "CLEAR_CART" });

    // Clear cart in the backend if user is logged in
    if (userId && token) {
      try {
        await clearCartDB(userId, token);
        // Sync backend with the cleared cart
        dispatch({ type: "SET_CART", payload: [] });
      } catch (error) {
        console.error("Error clearing cart in the backend:", error);
      }
    }
  };

  // Sync cart on component mount
  useEffect(() => {
    syncCart();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateQuantity, // Ensuring updateQuantity is accessible
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the Cart context
export const useCart = () => useContext(CartContext);

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { fetchEventById } from "../utils/eventService";
import EventSidebar from "../components/EventSidebar/EventSidebar";
import DOMPurify from "dompurify";
import "./Event.css";
import useBackToTop from "../utils/useBackToTop";

const Event = () => {
  useBackToTop(500, true);
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const getEvent = async () => {
      try {
        const data = await fetchEventById(eventId);
        const sanitizedDescription = DOMPurify.sanitize(data.description);
        setEvent({ ...data, description: sanitizedDescription });
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };

    getEvent();
  }, [eventId]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{`${event.title} | TORIS Technologies Events`}</title>
        <meta name="description" content={`Join us at ${event.title}.`} />
        <meta name="author" content={event.organizer} />
        <link rel="canonical" href={`https://toris.co.za/event/${eventId}`} />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content={`${event.title} | TORIS Technologies`}
        />
        <meta
          property="og:description"
          content={`Join us at ${event.title}.`}
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_STATIC_BASE_URL}${event.image_url}`}
        />
        <meta
          property="og:url"
          content={`https://toris.co.za/event/${eventId}`}
        />
        <meta property="og:type" content="event" />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">{event.title}</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Event</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="event-row">
            <div className="content-area col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <article className="event-post post-box">
                <div className="entry-media">
                  <div className="entry-header">
                    <h3 className="entry-title">{event.title}</h3>
                    <div className="event-meta">
                      <span>
                        <em>
                          <strong>Date:</strong>{" "}
                          {new Date(event.event_date).toLocaleDateString()}
                        </em>
                      </span>
                      <br />
                      <span>
                        <em>
                          <strong>Location:</strong> {event.location}
                        </em>
                      </span>
                      <br />
                      <span>
                        <em>
                          <strong>Organizer:</strong> {event.organizer}
                        </em>
                      </span>
                    </div>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_FRONTEND_URL}${event.image_url}`}
                    alt={event.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div className="inner-post">
                  <div
                    className="event-description"
                    dangerouslySetInnerHTML={{ __html: event.description }}
                  />
                </div>
              </article>
            </div>

            {/* Pass event details to EventSidebar */}
            <EventSidebar eventId={event.id} eventDetails={event} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;

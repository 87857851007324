import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  registerForEvent,
  fetchUpcomingEvents,
} from "../../utils/eventService";
import { addSubscriber } from '../../utils/subscriberService';
import "./EventSidebar.css";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa"; 

const EventSidebar = ({ eventId, eventDetails }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const loadUpcomingEvents = async () => {
      try {
        const response = await fetchUpcomingEvents();
        setUpcomingEvents(response);
      } catch (error) {
        console.error("Failed to fetch upcoming events:", error);
      }
    };

    loadUpcomingEvents();
  }, []);

  // Correct the name attribute and handling function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    // Add the 'event' segment to formData
    const subscriberData = { ...formData, segment: 'event' };
  
    try {
      // Register for the event
      await registerForEvent(eventId, formData);
  
      // Add the subscriber with the 'event' segment
      await addSubscriber(subscriberData);
  
      setSuccessMessage("You have successfully registered for the event!");
      setErrorMessage("");
      setFormData({ firstName: "", lastName: "", email: "" });
    } catch (error) {
      console.error("Error registering for event:", error);
      setErrorMessage("Failed to register. Please try again.");
    }
  };

  // Guard for undefined eventDetails
  if (!eventDetails) {
    return <p>Loading event details...</p>;
  }

  // Build dynamic event URL using eventId, while including title in the share content
  const eventUrl = `https://toris.co.za/event/${eventId}`;
  const eventTitle = encodeURIComponent(eventDetails.title);
  const eventDescription = encodeURIComponent(eventDetails.description);

  return (
    <aside className="event-sidebar col-lg-3 col-md-12 col-sm-12 col-xs-12">
      <div className="event-sidebar-card">
        <h3>Sign Up for This Event</h3>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName" // Corrected name attribute
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName" // Corrected name attribute
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email" // Corrected name attribute
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Sign Up
          </button>
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>

      {/* Event Details Section */}
      <div className="event-sidebar-card">
        <h3>Event Details</h3>
        <p>
          <strong>Date:</strong>{" "}
          {new Date(eventDetails.event_date).toLocaleDateString()}
        </p>
        <p>
          <strong>Time:</strong>{" "}
          {new Date(eventDetails.event_date).toLocaleTimeString()}
        </p>
        <p>
          <strong>Location:</strong> {eventDetails.location}
        </p>
        {eventDetails.price && (
          <p>
            <strong>Price:</strong> ${eventDetails.price}
          </p>
        )}
      </div>
      <hr />
      {/* Social Media Sharing */}
      <div className="event-sidebar-card">
        <h3>Share This Event</h3>
        <div className="social-share-links">
          <a
            href={`https://twitter.com/intent/tweet?url=${eventUrl}&text=${eventTitle}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${eventUrl}&quote=${eventTitle}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="social-icon" />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${eventUrl}&title=${eventTitle}&summary=${eventDescription}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="social-icon" />
          </a>
        </div>
      </div>
      <hr />

      {/* Upcoming Events */}
      <div className="event-sidebar-card">
        <h3>Upcoming Events</h3>
        <ul className="upcoming-events-list">
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event) => (
              <Link to={`/event/${event.id}`} key={event.id}>
                <li
                  className="upcoming-event-card"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_FRONTEND_URL}${event.image_url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#fff",
                    position: "relative",
                  }}
                >
                  <div className="upcoming-event-overlay">
                    <h4 className="upcoming-event-title">{event.title}</h4>
                    <p className="upcoming-event-date">
                      {new Date(event.event_date).toLocaleDateString()}
                    </p>
                    <p className="upcoming-event-location">{event.location}</p>
                  </div>
                </li>
              </Link>
            ))
          ) : (
            <p>There are no events to display.</p>
          )}
        </ul>
      </div>
    </aside>
  );
};

export default EventSidebar;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { fetchProjects } from "../utils/projectsService";
import "./Projects.css";
import useBackToTop from "../utils/useBackToTop";

const Projects = () => {
  useBackToTop(500, true);
  const [projects, setProjects] = useState([]);
  const [visibleProjects, setVisibleProjects] = useState(9);
  const [activeFilter, setActiveFilter] = useState("All");

  // Load projects from the backend
  useEffect(() => {
    const loadProjects = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
      } catch (error) {
        console.error("Error loading projects:", error);
      }
    };

    loadProjects();
  }, []);

  // Filter projects based on category
  const filteredProjects =
    activeFilter === "All"
      ? projects
      : projects.filter(
          (project) =>
            project.project_category.toLowerCase() ===
            activeFilter.toLowerCase()
        );

  // Function to handle "Load More" button click
  const loadMoreProjects = () => {
    setVisibleProjects((prevVisible) => prevVisible + 9);
  };

  return (
    <div>
      <Helmet>
        <title>Projects - TORIS Technologies</title>
        <meta
          name="description"
          content="Explore our latest projects in web development, technology, design, and more at TORIS Technologies. Our projects showcase diverse and innovative case studies."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, projects, case studies, web development, design, technology, innovation"
        />
        <link rel="canonical" href="https://toris.co.za/projects" />
        <meta property="og:title" content="Projects - TORIS Technologies" />
        <meta
          property="og:description"
          content="Discover TORIS Technologies' projects showcasing projects in web development, design, technology, and more. See how we deliver innovative solutions."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/portfolio-overview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/projects" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Projects - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Check out the projects of TORIS Technologies, featuring projects in development, design, and technology."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/portfolio-overview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Projects</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Projects</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="unique-projects-section">
          <div className="unique-container">
            <div className="unique-row">
              <div className="unique-col-md-12 text-center">
                <div className="unique-heading">
                  <span>latest case studies</span>
                  <h2 className="unique-main-heading">
                    Introduce Our Projects
                  </h2>
                </div>
                <p>
                  Software development outsourcing is just a tool to achieve
                  business goals. But there is no way to get worthwhile results
                  without cooperation and trust between a client company.
                </p>
              </div>
            </div>

            {/* Category Filter Buttons */}
            <div className="projects-category-filter">
              {["All", "Design", "Development", "Ideas", "Technology"].map(
                (category) => (
                  <button
                    key={category}
                    className={`projects-filter-button ${
                      activeFilter === category ? "active" : ""
                    }`}
                    onClick={() => setActiveFilter(category)}
                  >
                    {category}
                  </button>
                )
              )}
            </div>

            {/* Project Grid */}
            <div className="unique-projects-grid">
              {filteredProjects
                .slice(0, visibleProjects)
                .map((project, index) => (
                  <div
                    className={`unique-project-card ${project.project_category.toLowerCase()}`}
                    key={index}
                  >
                    <div className="unique-projects-box">
                      <div className="unique-projects-thumbnail">
                        <Link to={`/project-details/${project.id}`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/${project.project_image}`}
                            alt={project.project_title}
                          />
                        </Link>
                      </div>
                      <div className="unique-portfolio-info">
                        <div className="unique-portfolio-info-inner">
                          <h5>
                            <Link to={`/project-details/${project.id}`}>
                              {project.project_title}
                            </Link>
                          </h5>
                          <p className="unique-portfolio-cates">
                            <Link to="#">{project.project_category}</Link>
                            <span>/</span>
                            <Link to="#">{project.client}</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Load More Button */}
            {visibleProjects < filteredProjects.length && (
              <div className="unique-load-more-button text-center">
                <button
                  onClick={loadMoreProjects}
                  className="unique-btn-primary"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Projects;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { useAuth } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        password,
      });

      // Destructure token, userId, role, and username from response data
      const { token, userId, role, username } = response.data;

      if (!username) {
        console.warn("Username not returned from backend.");
      }

      // Store user data in the context (including username)
      login(token, userId, role, username);

      // Navigate based on role
      if (
        role === "admin" ||
        role === "moderator" ||
        role === "guest blogger"
      ) {
        navigate("/admin");
      } else {
        navigate("/shop");
      }
    } catch (error) {
      console.error("Error logging in user:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - TORIS Technologies</title>
        <meta
          name="description"
          content="Login to your TORIS Technologies account to access your personalized dashboard, manage your orders, and update your profile. New here? Register today!"
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, user login, account access, login page, user dashboard, register account"
        />
        <link rel="canonical" href="https://toris.co.za/login" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Login - TORIS Technologies" />
        <meta
          property="og:description"
          content="Access your TORIS Technologies account. Manage orders, update your profile, and more. New to TORIS? Register now!"
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/login-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/login" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Login to your TORIS Technologies account to access personalized features. New here? Register today to get started."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/login-preview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <h2 className="login-title">Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
        <div className="register-footer-text">
          <p>Don't have an account? </p>
          <Link to="/register">Register now!</Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;

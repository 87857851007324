import React, { useState } from "react";
import useBackToTop from "../utils/useBackToTop";
import AdminSidebar from "../components/AdminSidebar/AdminSidebar";
import AdminUsersManagement from "../components/AdminUsersManagement/AdminUsersManagement";
import AdminProductCategories from "../components/AdminProductCategories/AdminProductCategories";
import AdminProductTags from "../components/AdminProductTags/AdminProductTags";
import AdminAddProduct from "../components/AdminAddProduct/AdminAddProduct";
import AdminViewEnquiries from "../components/AdminViewEnquiries/AdminViewEnquiries";
import AdminAddBlog from "../components/AdminAddBlog/AdminAddBlog";
import AdminViewBlogs from "../components/AdminViewBlogs/AdminViewBlogs";
import AdminReportedComments from "../components/AdminReportedComments/AdminReportedComments";
import AdminManageEvents from "../components/AdminAddEvent/AdminManageEvents";
import AdminManageSubscribers from "../components/AdminManageSubscribers/AdminManageSubscribers";
import AdminManageEmails from "../components/AdminManageEmails/AdminManageEmails";

const AdminDashboard = () => {
  useBackToTop(500, true);
  const [selectedComponent, setSelectedComponent] = useState("usersManagement");

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "usersManagement":
        return <AdminUsersManagement />;
      case "addProduct":
        return <AdminAddProduct />;
      case "productCategories":
        return <AdminProductCategories />;
      case "productTags":
        return <AdminProductTags />;
      case "viewEnquiries":
        return <AdminViewEnquiries />;
      case "addBlog":
        return <AdminAddBlog />;
      case "viewBlogs":
        return <AdminViewBlogs />;
      case "moderateComments":
        return <AdminReportedComments />;
      case "manageEvents":
        return <AdminManageEvents />;
      case "manageSubscribers":
        return <AdminManageSubscribers />;
      case "manageEmails":
        return <AdminManageEmails />;
      default:
        return <AdminUsersManagement />;
    }
  };

  return (
    <div className="admin-dashboard">
      <AdminSidebar setSelectedComponent={setSelectedComponent} />
      <div className="admin-content">{renderSelectedComponent()}</div>
    </div>
  );
};

export default AdminDashboard;

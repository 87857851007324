// utils/billingInfoService.js

export const getBillingDetails = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/billing`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch billing details');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching billing details:', error);
      throw error;
    }
  };
  
  export const saveBillingDetails = async (userId, billingDetails) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/billing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,  // Add userId here
          ...billingDetails
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to save billing details');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error saving billing details:', error);
      throw error;
    }
  };
  
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  fetchBlogPost,
  fetchBlogs,
  fetchBlogCategories,
  toggleBlogLike,
  checkIfUserLikedPost,
} from "../utils/blogService";
import { useAuth } from "../context/AuthContext";
import DOMPurify from "dompurify";
import Comments from "../components/Comments/Comments";
import SharePost from "../components/SharePost/SharePost";
import BlogSidebar from "../components/BlogSidebar/BlogSidebar";
import useBackToTop from "../utils/useBackToTop";

const Post = () => {
  useBackToTop(500, true);
  const { postId } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hasLiked, setHasLiked] = useState(false);
  const navigate = useNavigate();

  const { auth } = useAuth();
  const { userId } = auth;

  useEffect(() => {
    const getBlogPost = async () => {
      try {
        const data = await fetchBlogPost(postId);
        const sanitizedContent = DOMPurify.sanitize(data.content);
        const sanitizedStyles = DOMPurify.sanitize(data.styles);
        setBlogPost({
          ...data,
          content: sanitizedContent,
          styles: sanitizedStyles,
        });

        if (userId) {
          const liked = await checkIfUserLikedPost(postId, userId);
          setHasLiked(liked);
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    const getRecentPosts = async () => {
      try {
        const data = await fetchBlogs(1, 3);
        setRecentPosts(data.blogs);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    const getCategories = async () => {
      try {
        const data = await fetchBlogCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    getBlogPost();
    getRecentPosts();
    getCategories();
  }, [postId, userId]);

  const handleLike = async () => {
    if (userId) {
      try {
        const updatedBlog = await toggleBlogLike(postId, userId);
        setBlogPost(updatedBlog);
        setHasLiked(!hasLiked);
      } catch (error) {
        console.error("Error toggling like:", error);
      }
    } else {
      console.error("User must be logged in to like/unlike posts.");
    }
  };

  const updateCommentsCount = (newCount) => {
    setBlogPost((prevBlogPost) => ({
      ...prevBlogPost,
      comments_count: newCount,
    }));
  };

  const handleFilterByCategory = (category) => {
    if (!category || category === "All Categories") {
      navigate("/blog");
    } else {
      navigate(`/blog?category=${encodeURIComponent(category)}`);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {blogPost && (
        <Helmet>
          <title>{`${blogPost.title} | TORIS Technologies Blog`}</title>
          <meta
            name="description"
            content={`Read about ${blogPost.title}. ${blogPost.author} discusses ${blogPost.categories} on the TORIS Technologies blog.`}
          />
          <meta
            name="keywords"
            content={`TORIS Technologies, blog, ${blogPost.categories}`}
          />
          <meta name="author" content={blogPost.author} />
          <link rel="canonical" href={`https://toris.co.za/post/${postId}`} />

          {/* Open Graph Tags */}
          <meta
            property="og:title"
            content={`${blogPost.title} | TORIS Technologies Blog`}
          />
          <meta
            property="og:description"
            content={`Read about ${blogPost.title} on the TORIS Technologies blog, by ${blogPost.author}.`}
          />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_STATIC_BASE_URL}${blogPost.image_url}`}
          />
          <meta
            property="og:url"
            content={`https://toris.co.za/post/${postId}`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:article:author" content={blogPost.author} />
          <meta
            property="og:article:published_time"
            content={new Date(blogPost.post_date).toISOString()}
          />
          <meta property="og:article:section" content={blogPost.categories} />

          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${blogPost.title} | TORIS Technologies Blog`}
          />
          <meta
            name="twitter:description"
            content={`Check out this post on ${blogPost.title} by ${blogPost.author} on the TORIS Technologies blog.`}
          />
          <meta
            name="twitter:image"
            content={`${process.env.REACT_APP_STATIC_BASE_URL}${blogPost.image_url}`}
          />
          {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
          <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
        </Helmet>
      )}

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">{blogPost.title}</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Blog Post</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="row">
            <div className="content-area col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <article className="blog-post post-box">
                <div className="entry-media">
                  <div className="post-cat">
                    <span className="posted-in">
                      {blogPost.categories &&
                        blogPost.categories
                          .split(",")
                          .map((category, index) => (
                            <Link
                              key={index}
                              to="#"
                              onClick={() => handleFilterByCategory(category)}
                            >
                              {category}
                            </Link>
                          ))}
                    </span>
                  </div>
                  <div className="entry-header">
                    <div className="entry-meta">
                      <span className="posted-on">
                        <Link to="#">
                          {new Date(blogPost.post_date).toLocaleDateString()}
                        </Link>
                      </span>
                      <span className="byline">
                        |{" "}
                        <Link className="url fn n" to="#">
                          {blogPost.author}
                        </Link>
                      </span>
                      <span className="comment-num">
                        | <Link to="#">{blogPost.comments_count} Comments</Link>
                      </span>
                    </div>
                    <h3 className="entry-title">{blogPost.title}</h3>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_STATIC_BASE_URL}${blogPost.image_url}`}
                    alt={blogPost.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div className="inner-post">
                  <div className="entry-summary">
                    <div
                      dangerouslySetInnerHTML={{ __html: blogPost.content }}
                    />
                  </div>
                </div>
                <div className="entry-footer clearfix">
                  <div className="widget_tag_cloudd tagcloud">
                    {blogPost.tags &&
                      blogPost.tags.split(",").map((tag, index) => (
                        <Link key={index} to="#">
                          {tag}
                        </Link>
                      ))}
                  </div>
                  <span className="sl-wrapper">
                    <Link
                      to="#"
                      className="sl-button sl-button-1263"
                      title="Like"
                      onClick={handleLike}
                    >
                      <span className="sl-icon">
                        <i
                          className={hasLiked ? "fas fa-heart" : "far fa-heart"}
                        ></i>
                      </span>
                      <span className="sl-count">{blogPost.likes}</span>
                    </Link>
                  </span>
                </div>
                <SharePost
                  postUrl={`${process.env.REACT_APP_API_URL}/post/${postId}`}
                  postTitle={blogPost.title}
                  postDescription={blogPost.content}
                  postImageUrl={blogPost.image_url}
                />
                <Comments
                  blogId={postId}
                  updateCommentsCount={updateCommentsCount}
                />
              </article>
            </div>
            <BlogSidebar
              onFilterByCategory={handleFilterByCategory}
              scrollToTop={scrollToTop}
            />
          </div>
        </div>
      </div>
      {blogPost.styles && (
        <style dangerouslySetInnerHTML={{ __html: blogPost.styles }} />
      )}
    </div>
  );
};

export default Post;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import IndustriesSection from "../components/IndustriesSection/IndustriesSection";
import SectionNeedAConsultation from "../components/SectionNeedAConsultation/SectionNeedAConsultation";
import SectionConsultation from "../components/SectionConsultation/SectionConsultation";
import useBackToTop from "../utils/useBackToTop";

const MobileDevelopment = () => {
  useBackToTop(500, true);
  return (
    <div>
      <Helmet>
        <title>Mobile Development - TORIS Technologies</title>
        <meta
          name="description"
          content="Discover TORIS Technologies' mobile development services. Enhance user engagement with custom mobile apps for various industries including SaaS, IoT, Social Media, and more."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, mobile development, custom mobile apps, mobile app benefits, SaaS, Internet of Things, business management, app development services"
        />
        <link rel="canonical" href="https://toris.co.za/mobile-development" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Mobile Development - TORIS Technologies"
        />
        <meta
          property="og:description"
          content="Enhance user experience with TORIS Technologies' mobile app development services, featuring tailored solutions for SaaS, IoT, social media, and more."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/mobile-development.jpg"
        />
        <meta
          property="og:url"
          content="https://toris.co.za/mobile-development"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mobile Development - TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="Explore custom mobile development solutions by TORIS Technologies. Engage users with mobile apps tailored for various industries."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/mobile-development.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Mobile Development</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Mobile Development</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="mobile-app">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="ot-heading">
                  <span>business benefits</span>
                  <h2 className="main-heading">Mobile Apps Benefits</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 align-self-center">
                <div className="icon-box-s2 s3 app-benefits-left">
                  <div className="icon-main">
                    <span className="flaticon-laptop"></span>
                  </div>
                  <div className="content-box">
                    <h5>Software as a Service</h5>
                    <p>
                      51% of smartphone users have discovered a new company or
                      product.
                    </p>
                  </div>
                </div>
                <div className="icon-box-s2 s3 app-benefits-left ml-0 mr-0">
                  <div className="icon-main">
                    <span className="flaticon-seo-and-web"></span>
                  </div>
                  <div className="content-box">
                    <h5>Internet of Things</h5>
                    <p>
                      Move your SaaS products to mobile, Companies with a
                      professional mobile.
                    </p>
                  </div>
                </div>
                <div className="icon-box-s2 s3 app-benefits-left">
                  <div className="icon-main">
                    <span className="flaticon-process"></span>
                  </div>
                  <div className="content-box">
                    <h5>Gambling &amp; Betting</h5>
                    <p>
                      Develop a custom mobile app to thrive in a mobile market
                      worth over $100.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 align-self-center">
                <div className="app-benefits-img text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/app-watch-1.jpg`}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-4 align-self-center">
                <div className="icon-box-s2 s1 app-benefits-right">
                  <div className="icon-main">
                    <span className="flaticon-data-1"></span>
                  </div>
                  <div className="content-box">
                    <h5>Social Media </h5>
                    <p>
                      80% of time users spend in social mediafrom their mobile
                      devices.
                    </p>
                  </div>
                </div>
                <div className="icon-box-s2 s1 app-benefits-right ml-0 mr-0">
                  <div className="icon-main">
                    <span className="flaticon-code-2"></span>
                  </div>
                  <div className="content-box">
                    <h5>Business Management</h5>
                    <p>
                      65% of sales representatives have achieved their quotas by
                      adopting.
                    </p>
                  </div>
                </div>
                <div className="icon-box-s2 s1 app-benefits-right">
                  <div className="icon-main">
                    <span className="flaticon-report"></span>
                  </div>
                  <div className="content-box">
                    <h5>Trading Systems</h5>
                    <p>
                      We provide top-tier mobile app development services for
                      brokers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="counter-v5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-6 no-padding">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/fun-fact1.jpg`}
                  alt=""
                  className="img-full"
                />
              </div>
              <div className="col-xl-3 col-lg-6 no-padding align-self-center">
                <div className="ot-counter2">
                  <div className="s-num">
                    <span className="num" data-to="15" data-time="2000">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <h6>Countries Worldwide</h6>
                  <p>
                    To succeed, every software solution must be deeply
                    integrated into the existing tech environment...
                  </p>
                  <div className="b-num">5+</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 order-lg-last no-padding">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/fun-fact2.jpg`}
                  alt=""
                  className="img-full"
                />
              </div>
              <div className="col-xl-3 col-lg-6 no-padding order-xl-last align-self-center">
                <div className="ot-counter2">
                  <div className="s-num">
                    <span className="num" data-to="23" data-time="2000">
                      0
                    </span>
                    <span>k</span>
                  </div>
                  <h6>Happy Customers</h6>
                  <p>
                    To succeed, every software solution must be deeply
                    integrated into the existing tech environment...
                  </p>
                  <div className="b-num">23k</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="app-offer">
          <div className="overlay overlay-image"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/apple-w.png`}
                  alt=""
                />
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="right-about-v4">
                  <div className="ot-heading">
                    <span>about company</span>
                    <h2 className="main-heading">
                      Your Partner for <br />
                      Software Innovation
                    </h2>
                  </div>
                  <div className="space-5"></div>
                  <p>
                    TORIS Technologies is the partner of choice for many of the
                    world’s leading enterprises, SMEs and technology
                    challengers. We help businesses elevate their value through
                    custom software development, product design, QA and
                    consultancy services.
                  </p>
                  <p>
                    <em className="text-dark">
                      <strong>
                        We can help to maintain and modernize your IT
                        infrastructure and solve various infrastructure-specific
                        issues a business may face.
                      </strong>
                    </em>
                  </p>
                  {/* <div className="video-popup style-2">
                    <div className="btn-inner">
                      <Link
                        className="btn-play"
                        to="https://www.youtube.com/watch?v=lfDZJqSrIuk"
                      >
                        <i className="flaticon-play"></i>
                        <span className="circle-1"></span>
                        <span className="circle-2"></span>
                      </Link>
                    </div>
                    <span>video showcase</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="app-projects">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="ot-heading mb-0">
                  <span>latest case studies</span>
                  <h2 className="main-heading">Introduce Our Projects</h2>
                </div>
              </div>
              <div className="col-md-7 align-self-end">
                <p className="mb-0">
                  Software development outsourcing is just a tool to achieve
                  business goals. But there is no way to get worthwhile results
                  without cooperation and trust between a client company.
                </p>
              </div>
            </div>
          </div>
          <div className="space-40"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="owl-carousel owl-theme project-slider">
                <div className="project-item projects-style-2">
                  <div className="projects-box">
                    <div className="projects-thumbnail">
                      <Link to="/project-details">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/project7-720x520.jpg`}
                          className=""
                          alt=""
                        />
                        <span className="overlay"></span>
                      </Link>
                    </div>
                    <div className="portfolio-info ">
                      <div className="portfolio-info-inner">
                        <Link className="btn-link" to="/project-details">
                          <i className="flaticon-right-arrow-1"></i>
                        </Link>
                        <h5>
                          <Link to="/project-details">Social Media App</Link>
                        </h5>
                        <p className="portfolio-cates">
                          <Link to="#">Design</Link>
                          <span>/</span>
                          <Link to="#">Technology</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-item projects-style-2">
                  <div className="projects-box">
                    <div className="projects-thumbnail">
                      <Link to="/project-details-1">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/project-720x520.jpg`}
                          className=""
                          alt=""
                        />
                        <span className="overlay"></span>
                      </Link>
                    </div>
                    <div className="portfolio-info ">
                      <div className="portfolio-info-inner">
                        <Link className="btn-link" to="/project-details">
                          <i className="flaticon-right-arrow-1"></i>
                        </Link>
                        <h5>
                          <Link to="/project-details">
                            App for Virtual Reality
                          </Link>
                        </h5>
                        <p className="portfolio-cates">
                          <Link to="#">Design</Link>
                          <span>/</span>
                          <Link to="#">Ideas</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-item projects-style-2">
                  <div className="projects-box">
                    <div className="projects-thumbnail">
                      <Link to="/project-details">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/project3-720x520.jpg`}
                          className=""
                          alt=""
                        />
                        <span className="overlay"></span>
                      </Link>
                    </div>
                    <div className="portfolio-info ">
                      <div className="portfolio-info-inner">
                        <Link className="btn-link" to="/project-details">
                          <i className="flaticon-right-arrow-1"></i>
                        </Link>
                        <h5>
                          <Link to="/project-details">Basics Project</Link>
                        </h5>
                        <p className="portfolio-cates">
                          <Link to="#">Design</Link>
                          <span>/</span>
                          <Link to="#">Development</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-item projects-style-2">
                  <div className="projects-box">
                    <div className="projects-thumbnail">
                      <Link to="/project-details">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/project4-720x520.jpg`}
                          className=""
                          alt=""
                        />
                        <span className="overlay"></span>
                      </Link>
                    </div>
                    <div className="portfolio-info ">
                      <div className="portfolio-info-inner">
                        <Link className="btn-link" to="project-details">
                          <i className="flaticon-right-arrow-1"></i>
                        </Link>
                        <h5>
                          <Link to="/project-details">eCommerce Website</Link>
                        </h5>
                        <p className="portfolio-cates">
                          <Link to="#">Design</Link>
                          <span>/</span>
                          <Link to="#">Ideas</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-item projects-style-2">
                  <div className="projects-box">
                    <div className="projects-thumbnail">
                      <Link to="/project-details">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/project8-720x520.jpg`}
                          className=""
                          alt=""
                        />
                        <span className="overlay"></span>
                      </Link>
                    </div>
                    <div className="portfolio-info ">
                      <div className="portfolio-info-inner">
                        <Link className="btn-link" to="/project-details">
                          <i className="flaticon-right-arrow-1"></i>
                        </Link>
                        <h5>
                          <Link to="/project-details">Basics Project</Link>
                        </h5>
                        <p className="portfolio-cates">
                          <Link to="#">Design</Link>
                          <span>/</span>
                          <Link to="#">Development</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="app-industris">
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="s-counter4">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                      <div className="ot-counter text-white">
                        <div>
                          <span className="num" data-to="330" data-time="2000">
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className="text-white">active Clients</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                      <div className="ot-counter text-white">
                        <div>
                          <span className="num" data-to="850" data-time="2000">
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className="text-white">projects done</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-sm-0">
                      <div className="ot-counter text-white">
                        <div>
                          <span className="num" data-to="25" data-time="2000">
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className="text-white">team advisors</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 text-center">
                      <div className="ot-counter text-white">
                        <div>
                          <span className="num" data-to="7" data-time="2000">
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className="text-white">Glorious Years</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <SectionConsultation />
          <IndustriesSection />
        </section>
      </div>
    </div>
  );
};

export default MobileDevelopment;

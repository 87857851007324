import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useCart } from "../context/CartContext";
import { useExchangeRate } from "../context/ExchangeRateContext";
import { fetchProducts } from "../utils/productService";
import useBackToTop from "../utils/useBackToTop";

const Cart = () => {
  useBackToTop(500, true);
  const { cart, removeFromCart, clearCart, updateQuantity } = useCart();
  const { exchangeRates, currencyCode } = useExchangeRate();
  const [fullCart, setFullCart] = useState([]); // To store cart with product details

  // Map currency codes to symbols
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ZAR: "R",
    AUD: "A$",
    CAD: "C$",
    JPY: "¥",
    CNY: "¥",
    CHF: "Fr",
    NZD: "$",
    SGD: "S$",
    HKD: "HK$",
    INR: "₹",
    BRL: "R$",
    MXN: "$",
    SEK: "kr",
    NOK: "kr",
    RUB: "₽",
    KRW: "₩",
    PLN: "zł",
    TRY: "₺",
    THB: "฿",
    IDR: "Rp",
    MYR: "RM",
    PHP: "₱",
    DKK: "kr",
    HUF: "Ft",
    CZK: "Kč",
    ILS: "₪",
    AED: "د.إ",
    SAR: "﷼",
    CLP: "$",
    COP: "$",
    PEN: "S/.",
    PKR: "₨",
    VND: "₫",
    BDT: "৳",
    NGN: "₦",
    EGP: "£",
    QAR: "﷼",
    KWD: "د.ك",
  };

  // Helper function to get currency symbol
  const getCurrencySymbol = (code) => {
    if (currencySymbols[code]) {
      return currencySymbols[code];
    }
    try {
      return new Intl.NumberFormat("en", { style: "currency", currency: code })
        .formatToParts(1)
        .find((part) => part.type === "currency").value;
    } catch (error) {
      console.warn(`Symbol for ${code} not found. Defaulting to code.`);
      return code;
    }
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  // Function to convert ZAR to the selected currency
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    return (amountInZAR * conversionRate).toFixed(2);
  };

  // Fetch product details based on cart items
  const fetchProductDetails = async () => {
    try {
      const productIds = cart.map((item) => item.productId);
      if (productIds.length > 0) {
        const products = await fetchProducts(productIds);
        const updatedCart = cart.map((item) => {
          const product = products.find((p) => p.id === item.productId);
          return {
            ...item,
            name: product?.name || "Unknown",
            // Use salePrice if the product is on sale, otherwise use regular price
            price: product?.sale ? product.salePrice : product.price,
            image: product?.image || "",
            link: `/single-product/${product?.id || item.productId}`,
          };
        });
        setFullCart(updatedCart);
      } else {
        setFullCart([]);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Fetch product details when the cart changes
  useEffect(() => {
    fetchProductDetails();
  }, [cart]);

  // Calculate total amount in the cart
  const cartTotal = fullCart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const convertedCartTotal = convertToCurrency(cartTotal);

  return (
    <div>
      <Helmet>
        <title>Your Cart - TORIS Technologies</title>
        <meta
          name="description"
          content="Review the items in your cart and proceed to checkout. TORIS Technologies offers reliable and scalable software and digital solutions tailored to your needs."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies cart, checkout, software solutions, digital solutions, custom software, technology services"
        />
        <link rel="canonical" href="https://toris.co.za/cart" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Your Cart - TORIS Technologies" />
        <meta
          property="og:description"
          content="Review the items in your cart and proceed to checkout. TORIS Technologies offers reliable and scalable software and digital solutions tailored to your needs."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/toris-cart-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/cart" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Cart - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Review the items in your cart and proceed to checkout. TORIS Technologies offers reliable and scalable software and digital solutions tailored to your needs."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/toris-cart-preview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Your Cart</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Cart</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="shop-cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <table className="shop-cart-table shop_table_responsive">
                  <thead>
                    <tr>
                      <th className="product-image">Image</th>
                      <th className="product-name">Product</th>
                      <th className="product-price-title">Price</th>
                      <th className="product-quantity">Quantity</th>
                      <th className="product-subtotal">Subtotal</th>
                      <th className="product-remove">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fullCart.map((item) => (
                      <tr key={item.productId} className="cart-item">
                        <td className="product-cart-image" data-title="Image">
                          <div className="cart-item-details">
                            <div className="thumb">
                              <Link to={item.link}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td className="product-cart-name" data-title="Product">
                          <div className="entry-header">
                            <h6>
                              <Link to={item.link}>{item.name}</Link>
                            </h6>
                          </div>
                        </td>
                        <td className="product-price" data-title="Price">
                          <span className="amount">
                            {currencySymbol} {convertToCurrency(item.price)}
                          </span>
                        </td>
                        <td className="product-quantity" data-title="Quantity">
                          <div className="quantity">
                            <input
                              type="number"
                              className="input-text qty text"
                              step="1"
                              min="1"
                              name="cart"
                              value={item.quantity}
                              onChange={(e) =>
                                updateQuantity(
                                  item.productId,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="product-subtotal" data-title="Subtotal">
                          <span className="amount">
                            {currencySymbol}{" "}
                            {convertToCurrency(item.price * item.quantity)}
                          </span>
                        </td>
                        <td className="product-remove actions">
                          <button
                            className="remove"
                            onClick={() => removeFromCart(item.productId)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="actions text-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={clearCart}
                        >
                          Clear Cart
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="cart-collaterals">
                  <div className="cart-totals">
                    <h5>Cart Totals</h5>
                    <table className="shop_table">
                      <tbody>
                        <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td data-title="Subtotal">
                            <span className="amount">
                              {currencySymbol} {convertedCartTotal}
                            </span>
                          </td>
                        </tr>
                        <tr className="order-total">
                          <th>Total</th>
                          <td data-title="Total">
                            <strong>
                              <span className="amount">
                                {currencySymbol} {convertedCartTotal}
                              </span>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="proceed-to-checkout text-right">
                      <Link to="/checkout" className="btn btn-primary">
                        Proceed to Checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Cart;

// src/utils/authUtils.js

// Save auth data to localStorage
export const saveAuthData = ({ token, userId, role, username }) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userId', userId);
  localStorage.setItem('role', role);
  localStorage.setItem('username', username);
};

// Clear all authentication-related data from localStorage (used on logout)
export const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('role');
  localStorage.removeItem('username');
};

// Get authentication data from localStorage
export const getAuthData = () => ({
  token: localStorage.getItem('token'),
  userId: localStorage.getItem('userId'),
  role: localStorage.getItem('role'),
  username: localStorage.getItem('username'),
});

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div id="content" className="site-content">
      <div className="container">
        <div className="error-404 not-found text-center">
          <h2>
            <img
              className="error-image"
              src={`${process.env.PUBLIC_URL}/assets/images/404-error.png`}
              alt="404"
            />
          </h2>
          <h1>Sorry! Page Not Found!</h1>
          <div className="content-404">
            <p>
              Oops! The page you are looking for does not exist. Please return
              to the site is homepage.
            </p>
            <form
              role="search"
              method="get"
              id="search-form"
              className="search-form"
            >
              <input
                type="search"
                className="search-field"
                placeholder="Search …"
                value=""
                name="s"
              />
              <button type="submit" className="search-submit">
                <i className="flaticon-search"></i>
              </button>
            </form>
            <Link className="octf-btn" to="/">
              Take Me Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { auth, loading } = useAuth();

  // Show a loading indicator or return null while loading
  if (loading) {
    return <div>Loading...</div>; // Replace with your loading spinner if desired
  }

  // Redirect to login if not authenticated and loading is complete
  if (!auth.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

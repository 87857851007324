// File: ProfileComponents/PurchasedProducts.js
import React, { useEffect, useState } from "react";
import axios from "../../../utils/axiosConfig"; 
import { handleDownloadClick } from '../../../utils/downloadService'; 
import "./PurchasedProducts.css";

const PurchasedProducts = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState({});

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const ordersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/user/${userId}`
        );
        setOrders(ordersResponse.data);

        const productIds = new Set();
        ordersResponse.data.forEach((order) => {
          const items = JSON.parse(order.items);
          items.forEach((item) => productIds.add(item.id));
        });

        const productResponses = await Promise.all(
          [...productIds].map((id) =>
            axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)
          )
        );

        const productsData = {};
        productResponses.forEach((res) => {
          productsData[res.data.id] = res.data;
        });
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching user orders or products:", error);
      }
    };

    fetchUserOrders();
  }, [userId]);

  return (
    <div className="purchased-products-container">
      <h2>Purchased Products</h2>
      <p>Here, you'll find all the products you’ve purchased.</p>

      <div className="orders-list">
        {orders.map((order) => {
          const items = JSON.parse(order.items);

          return items.map((item) => {
            const product = products[item.id];
            if (!product) return null;

            return (
              <div className="product-card" key={`${order.id}-${item.id}`}>
                <img
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <div className="product-details">
                  <h3>{product.name}</h3>
                  <p>Price: ${product.price}</p>
                  <p>Status: {order.status}</p>
                </div>

                <div className="product-actions">
                  {order.status === "COMPLETED" ? (
                    <button
                      className="download-button"
                      onClick={() => handleDownloadClick(order.id, item.id)}
                    >
                      Download
                    </button>
                  ) : (
                    <button className="pending-button" disabled>
                      Pending Payment
                    </button>
                  )}
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};

export default PurchasedProducts;

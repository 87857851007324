import React, { useState, useEffect } from 'react';
import axios from "../../utils/axiosConfig"; 
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminProductCategories.css';

const AdminProductCategories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);

  // Base URL for the API from environment variable
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/categories`);
        setCategories(response.data);
        toast.success('Categories fetched successfully.');
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Error fetching categories.');
      }
    };

    fetchCategories();
  }, [API_BASE_URL]);

  const handleAddOrUpdateCategory = async () => {
    if (newCategory.trim() === '') return;

    try {
      if (isEditing) {
        await axios.put(`${API_BASE_URL}/categories/${editCategoryId}`, { name: newCategory });
        setIsEditing(false);
        setEditCategoryId(null);
        toast.success('Category updated successfully.');
      } else {
        await axios.post(`${API_BASE_URL}/categories`, { name: newCategory });
        toast.success('Category added successfully.');
      }
      setNewCategory('');
      const response = await axios.get(`${API_BASE_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error saving category:', error);
      toast.error('Error saving category.');
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories/${id}`);
      setNewCategory(response.data.name);
      setIsEditing(true);
      setEditCategoryId(id);
    } catch (error) {
      console.error('Error fetching category:', error);
      toast.error('Error fetching category.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/categories/${id}`);
      setCategories(categories.filter(category => category.id !== id));
      toast.success('Category deleted successfully.');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Error deleting category.');
    }
  };

  return (
    <div className="admin-product-categories">
      <h1>Product Categories</h1>
      <div className="add-category">
        <input
          type="text"
          placeholder="Add new category"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button onClick={handleAddOrUpdateCategory}>
          {isEditing ? 'Update Category' : 'Add Category'}
        </button>
      </div>
      <table className="admin-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>
                <FaEdit className="action-icon" onClick={() => handleEdit(category.id)} />
              </td>
              <td>
                <FaTrash className="action-icon" onClick={() => handleDelete(category.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default AdminProductCategories;

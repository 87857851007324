import React, { useEffect, useState } from "react";
import axios from "../../utils/axiosConfig"; 
import { FaEdit, FaTrash } from "react-icons/fa";
import AdminAddBlog from "../AdminAddBlog/AdminAddBlog";
import "./AdminViewBlogs.css";

const AdminViewBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogToEdit, setBlogToEdit] = useState(null); 
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/blogs`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, []);

  const handleEdit = (blog) => {
    setBlogToEdit(blog);
    setIsEditing(true);
  };

  const handleDelete = async (blogId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/blogs/${blogId}`);
      setBlogs(blogs.filter(blog => blog.id !== blogId));
      alert('Blog deleted successfully');
    } catch (error) {
      console.error('Error deleting blog:', error);
      alert('Failed to delete blog');
    }
  };

  return (
    <div className="admin-view-blogs">
      {isEditing ? (
        <AdminAddBlog blogToEdit={blogToEdit} /> // Render the edit form with the blog data
      ) : (
        <>
          <h2>Manage Blogs</h2>
          {blogs.length > 0 ? (
            <table className="admin-blog-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Author</th>
                  <th>Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {blogs.map((blog) => (
                  <tr key={blog.id}>
                    <td>{blog.id}</td>
                    <td>{blog.title}</td>
                    <td>{blog.category}</td>
                    <td>{blog.author}</td>
                    <td>{new Date(blog.post_date).toLocaleDateString()}</td>
                    <td>
                      <button
                        className="action-btn edit-btn"
                        onClick={() => handleEdit(blog)}
                        title="Edit"
                      >
                        <FaEdit />
                      </button>
                    </td>
                    <td>
                      <button
                        className="action-btn delete-btn"
                        onClick={() => handleDelete(blog.id)}
                        title="Delete"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No blogs found</p>
          )}
        </>
      )}
    </div>
  );
};

export default AdminViewBlogs;

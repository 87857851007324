import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SectionFAQAccordions from "../components/SectionFAQAccordions/SectionFAQAccordions";
import useBackToTop from "../utils/useBackToTop";

const Faq = () => {
  useBackToTop(500, true);
  return (
    <div>
      <Helmet>
        <title>Frequently Asked Questions - TORIS Technologies</title>
        <meta
          name="description"
          content="Get answers to common questions about TORIS Technologies' services. Our FAQ section covers everything from choosing QA consultants to understanding SEO best practices."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, FAQs, frequently asked questions, QA consultants, SEO best practices, web design, link building, keyword research"
        />
        <link rel="canonical" href="https://toris.co.za/faqs" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Frequently Asked Questions - TORIS Technologies"
        />
        <meta
          property="og:description"
          content="Find answers to commonly asked questions about TORIS Technologies' services, from choosing QA consultants to understanding SEO and web design best practices."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/faqs-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/faqs" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Frequently Asked Questions - TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="Find answers to commonly asked questions about TORIS Technologies' services, from choosing QA consultants to understanding SEO and web design best practices."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/faqs-preview.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">FAQs</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">frequently Asked Questions</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="faq">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="ot-heading">
                  <span>FAQ</span>
                  <h2 className="main-heading">
                    Read Most <br />
                    Frequent Questions
                  </h2>
                </div>
                <div className="space-25"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <SectionFAQAccordions />
              </div>
            </div>
          </div>
        </section>

        <section className="faq-support">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta-faq">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 mb-4 mb-md-0">
                      <div className="ot-heading">
                        <span>We Carry more Than Just Good Coding Skills</span>
                        <h2 className="main-heading">
                          Let's Build Your Website!
                        </h2>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 align-self-end">
                      <div className="ot-button text-center text-md-right">
                        <Link
                          to="/contact"
                          className="octf-btn octf-btn-primary"
                        >
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-120"></div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="ot-heading">
                  <span>support center</span>
                  <h2 className="main-heading">
                    Our Support Team <br />
                    Will Always Assist You
                  </h2>
                </div>
              </div>
            </div>
            <div className="space-35"></div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                <div className="support-box">
                  <div className="inner-box">
                    <div className="overlay flex-middle">
                      <span className="number-box">01</span>
                      <div className="inner">
                        <p>
                          Entrust full-cycle implementation of your software
                          product to our experienced Business Analists, UI/UX
                          designers, Developers.
                        </p>
                        <Link to="/web-development" className="btn-details">
                          <i className="flaticon-right-arrow-1"></i> LEARN MORE
                        </Link>
                      </div>
                    </div>
                    <div className="content-box">
                      <span className="number-box">01</span>
                      <h3>Web Applications</h3>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/support1.jpg`}
                        alt="For Partners"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                <div className="support-box">
                  <div className="inner-box">
                    <div className="overlay flex-middle">
                      <span className="number-box">02</span>
                      <div className="inner">
                        <p>
                          Mobility has become a need for businesses, be mobile,
                          be efficient!
                        </p>
                        <Link to="/web-development" className="btn-details">
                          <i className="flaticon-right-arrow-1"></i> LEARN MORE
                        </Link>
                      </div>
                    </div>
                    <div className="content-box">
                      <span className="number-box">02</span>
                      <h3>Mobile Applications</h3>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/support2.jpg`}
                        alt="For Customers"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                <div className="support-box">
                  <div className="inner-box">
                    <div className="overlay flex-middle">
                      <span className="number-box">03</span>
                      <div className="inner">
                        <p>
                          You have already found what you are looking for but it
                          needs some small tweaks or speak to another platform.
                          We have the skills you require.
                        </p>
                        <Link to="/mobile-development" className="btn-details">
                          <i className="flaticon-right-arrow-1"></i> LEARN MORE
                        </Link>
                      </div>
                    </div>
                    <div className="content-box">
                      <span className="number-box">03</span>
                      <h3>Software Integrations</h3>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/support3.jpg`}
                        alt="For Startups"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-120"></div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Faq;

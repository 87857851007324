import React from 'react';
import { Link } from 'react-router-dom';
import { useExchangeRate } from '../../context/ExchangeRateContext';
import './CallToActionPricing.css';

const CallToActionPricing = () => {
  const { exchangeRates, currencyCode } = useExchangeRate();

  // Mapping of currency codes to symbols
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    ZAR: 'R',
    AUD: 'A$',
    CAD: 'C$',
    JPY: '¥',
    CNY: '¥',
    CHF: 'Fr',
    NZD: '$',
    SGD: 'S$',
    HKD: 'HK$',
    INR: '₹',
    BRL: 'R$',
    MXN: '$',
    SEK: 'kr',
    NOK: 'kr',
    RUB: '₽'
  };

  // Function to convert ZAR to the selected currency, round, and add ".00"
  const convertToCurrency = (amountInZAR) => {
    const conversionRate = exchangeRates[currencyCode] || 1;
    const amount = parseFloat(amountInZAR) || 0;

    return `${Math.round(amount * conversionRate)}.00`;
  };

  // Get symbol or default to currency code if symbol is not available
  const currencySymbol = currencySymbols[currencyCode] || currencyCode;

  return (
    <div className="cta-pricing">
      <div className="cta-overlay">
        <h2>Start Your Project Today</h2>
        <p>High-quality, scalable web development services tailored to your needs.</p>
        <div className="cta-pricing-info">
          <p>Our pricing starts at <br /><br />
            <span>
              <em>{currencySymbol} {convertToCurrency(3000)}</em>
            </span><br /><br />
            for beautiful, interactive single page websites.
          </p>
          <p>Contact us for a customized quote!</p>
        </div>
        <Link to="/contact" className="cta-button">GET A QUOTE</Link>
      </div>
    </div>
  );
};

export default CallToActionPricing;

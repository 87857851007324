import axios from "./axiosConfig"; 

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

// Fetch all subscribers
export const fetchSubscribers = async () => {
  try {
    const response = await axios.get(`${API_URL}/subscribers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscribers:', error);
    throw error;
  }
};

// Add a new subscriber
export const addSubscriber = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/subscribers`, formData);
    return response.data;
  } catch (error) {
    console.error('Error adding subscriber:', error);
    throw error;
  }
};

// Update an existing subscriber
export const updateSubscriber = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/subscribers/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error('Error updating subscriber:', error);
    throw error;
  }
};

// Delete a subscriber
export const deleteSubscriber = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/subscribers/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting subscriber:', error);
    throw error;
  }
};

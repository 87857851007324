import React, { useState, useEffect } from 'react';
import { fetchEvents, addEvent, updateEvent, deleteEvent } from '../../utils/eventService';
import './AdminManageEvents.css';

const AdminManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [form, setForm] = useState({
    title: '',
    description: '',
    event_date: '',
    location: '',
    organizer: '',
    image: null,
  });
  const [editingEventId, setEditingEventId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Load events when the component mounts
  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    setLoading(true);
    try {
      const data = await fetchEvents();
      setEvents(data.events);
    } catch (error) {
      console.error('Failed to load events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editingEventId) {
        await updateEvent(editingEventId, form);
      } else {
        await addEvent(form);
      }
      setForm({
        title: '',
        description: '',
        event_date: '',
        location: '',
        organizer: '',
        image: null,
      });
      setEditingEventId(null);
      loadEvents();
    } catch (error) {
      console.error('Failed to submit event:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditEvent = (event) => {
    setForm({
      title: event.title,
      description: event.description,
      event_date: event.event_date,
      location: event.location,
      organizer: event.organizer,
      image: null,
    });
    setEditingEventId(event.id);
  };

  const handleDeleteEvent = async (eventId) => {
    setLoading(true);
    try {
      await deleteEvent(eventId);
      loadEvents();
    } catch (error) {
      console.error('Failed to delete event:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-manage-events">
      <h1 className="admin-manage-events-title">Manage Events</h1>

      {/* Event Form */}
      <div className="admin-manage-events-form-card">
        <h2>{editingEventId ? 'Edit Event' : 'Add New Event'}</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="admin-manage-events-form-group">
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={form.title}
              onChange={handleInputChange}
              required
              className="admin-manage-events-input"
            />
          </div>
          <div className="admin-manage-events-form-group">
            <label>Description:</label>
            <textarea
              name="description"
              value={form.description}
              onChange={handleInputChange}
              required
              className="admin-manage-events-input"
            ></textarea>
          </div>
          <div className="admin-manage-events-form-group">
            <label>Date:</label>
            <input
              type="date"
              name="event_date"
              value={form.event_date}
              onChange={handleInputChange}
              required
              className="admin-manage-events-input"
            />
          </div>
          <div className="admin-manage-events-form-group">
            <label>Location:</label>
            <input
              type="text"
              name="location"
              value={form.location}
              onChange={handleInputChange}
              required
              className="admin-manage-events-input"
            />
          </div>
          <div className="admin-manage-events-form-group">
            <label>Organizer:</label>
            <input
              type="text"
              name="organizer"
              value={form.organizer}
              onChange={handleInputChange}
              required
              className="admin-manage-events-input"
            />
          </div>
          <div className="admin-manage-events-form-group">
            <label>Image:</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="admin-manage-events-input"
            />
          </div>
          <button type="submit" className="admin-manage-events-button" disabled={loading}>
            {editingEventId ? 'Update Event' : 'Add Event'}
          </button>
        </form>
      </div>

      {/* Event List */}
      <h2 className="admin-manage-events-list-title">Event List</h2>
      {loading ? (
        <p>Loading events...</p>
      ) : (
        <table className="admin-manage-events-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Date</th>
              <th>Location</th>
              <th>Organizer</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.title}</td>
                <td>{event.description}</td>
                <td>{new Date(event.event_date).toLocaleDateString()}</td>
                <td>{event.location}</td>
                <td>{event.organizer}</td>
                <td>
                  <button
                    className="admin-manage-events-edit-button"
                    onClick={() => handleEditEvent(event)}
                  >
                    Edit
                  </button>
                  <button
                    className="admin-manage-events-delete-button"
                    onClick={() => handleDeleteEvent(event.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminManageEvents;

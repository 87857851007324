import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchBlogs } from "../../utils/blogService";

const LatestBlogs = ({ numPosts = 3 }) => {
  const [latestBlogs, setLatestBlogs] = useState([]);

  useEffect(() => {
    const loadLatestBlogs = async () => {
      try {
        const data = await fetchBlogs(1, numPosts); // Fetch the latest posts
        setLatestBlogs(data.blogs);
      } catch (error) {
        console.error("Error fetching latest blogs:", error);
      }
    };

    loadLatestBlogs();
  }, [numPosts]);

  return (
    <div className="post-grid pgrid">
      <div className="row justify-content-center">
        {latestBlogs.map((blog) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={blog.id}>
            <article className="post-box blog-item">
              <div className="post-inner">
                <div className="entry-media">
                  <div className="post-cat">
                    <span className="posted-in">
                      <Link to="#" rel="category tag">
                        {blog.category}
                      </Link>
                    </span>
                  </div>
                  <Link to={`/post/${blog.id}`}>
                    <img
                      src={`${process.env.REACT_APP_STATIC_BASE_URL}${blog.image_url}`}
                      alt={blog.title}
                    />
                  </Link>
                </div>
                <div className="inner-post">
                  <div className="entry-header">
                    <div className="entry-meta">
                      <span className="posted-on">
                        <Link to="#">
                          {new Date(blog.post_date).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </Link>
                      </span>
                      <span className="byline"> | {blog.author}</span>
                      <span className="comment-num">
                        | {blog.comments_count} Comments
                      </span>
                    </div>
                    <h3 className="entry-title">
                      <Link to={`/post/${blog.id}`}>{blog.title}</Link>
                    </h3>
                  </div>
                  <div className="btn-readmore">
                    <Link to={`/post/${blog.id}`}>
                      <i className="flaticon-right-arrow-1"></i> LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestBlogs;

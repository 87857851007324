import React from "react";
import { Link } from "react-router-dom";
import "./SectionServiceCards.css";

const SectionServiceCards = () => {
  return (
    <div className="spacing-50 display-row gap-30">
      <div className="support-box">
        <div className="inner-box">
          <div className="overlay flex-middle">
            <span className="number-box">01</span>
            <div className="inner card-text">
              <p className="card-text-margin">
                Entrust full-cycle implementation of your software product to
                our experienced Business Analists, UI/UX designers, Developers.
              </p>
              <Link to="/web-development" className="service-learn-more-button">
                MORE ABOUT CUSTOM WEB APPS
              </Link>
            </div>
          </div>
          <div className="content-box">
            <span className="number-box">01</span>
            <h3 className="service-card-title">Custom Software</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flipbox1.webp`}
              alt="Custom Software"
            />
          </div>
        </div>
      </div>
      <div className="support-box">
        <div className="inner-box">
          <div className="overlay flex-middle">
            <span className="number-box">02</span>
            <div className="inner card-text">
              <p className="card-text-margin">
                We take your business seriously! Let us take care of your
                website visitors user journey and turn them from visitors to
                leads.
              </p>
              <Link to="/web-development" className="service-learn-more-button">
                MORE ABOUT BUSINESS WEBSITES
              </Link>
            </div>
          </div>
          <div className="content-box">
            <span className="number-box">02</span>
            <h3 className="service-card-title">Business Websites</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flipbox5.webp`}
              alt="Business Websites"
            />
          </div>
        </div>
      </div>
      <div className="support-box">
        <div className="inner-box">
          <div className="overlay flex-middle">
            <span className="number-box">03</span>
            <div className="inner card-text">
              <p className="card-text-margin">
                Mobility has become a need for businesses, be mobile, be
                efficient!
              </p>
              <Link to="/mobile-development" className="service-learn-more-button">
                 MORE ABOUT MOBILE APPS
              </Link>
            </div>
          </div>
          <div className="content-box">
            <span className="number-box">03</span>
            <h3 className="service-card-title">Mobile Apps</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flipbox3.webp`}
              alt="Mobile Application Development"
            />
          </div>
        </div>
      </div>
      <div className="support-box">
        <div className="inner-box">
          <div className="overlay flex-middle">
            <span className="number-box">04</span>
            <div className="inner card-text">
              <p className="card-text-margin">
                Stop using unreliable plugins to automate your business logic.
                We can customise every step to cater for your business needs.
              </p>
              <Link to="/software-integrations" className="service-learn-more-button">
                MORE ABOUT INTEGRATIONS
              </Link>
            </div>
          </div>
          <div className="content-box">
            <span className="number-box">04</span>
            <h3 className="service-card-title">Software Integrations</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flipbox4.webp`}
              alt="Software Integrations"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionServiceCards;

import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerified = () => {
  return (
    <div className="email-verified-container">
      <h2>Email Verified Successfully!</h2>
      <p>Your email has been verified. You can now log in.</p>
      <Link to="/login" className="btn btn-primary">Go to Login</Link>
    </div>
  );
};

export default EmailVerified;

// src/utils/useBackToTop.js
import { useEffect } from 'react';

const useBackToTop = (triggerHeight = 500, scrollOnRender = false) => {
  useEffect(() => {
    const backToTopButton = document.getElementById('back-to-top');

    // Only proceed if the element exists
    if (!backToTopButton) return;

    // Scroll to the top on component render if scrollOnRender is true
    if (scrollOnRender) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    // Show or hide the button based on scroll position
    const toggleBackToTop = () => {
      if (window.scrollY > triggerHeight) {
        backToTopButton.classList.add('show');
      } else {
        backToTopButton.classList.remove('show');
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', toggleBackToTop);

    // Smooth scroll to top on button click
    const handleBackToTopClick = (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    backToTopButton.addEventListener('click', handleBackToTopClick);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', toggleBackToTop);
      backToTopButton.removeEventListener('click', handleBackToTopClick);
    };
  }, [triggerHeight, scrollOnRender]);
};

export default useBackToTop;

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { useCart } from "../../context/CartContext";
import { useAuth } from "../../context/AuthContext";
import { useExchangeRate } from "../../context/ExchangeRateContext";
import { fetchProducts } from "../../utils/productService";
import debounce from "lodash.debounce";
import "./Navbar.css";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [fullCart, setFullCart] = useState([]);
  const { cart, removeFromCart } = useCart();
  const { auth, logout } = useAuth();
  const { currencyCode, exchangeRates, updateCurrencyCode } = useExchangeRate();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const prevCartRef = useRef([]);

  // Map of currency codes to symbols
  const currencySymbols = {
    USD: "$", EUR: "€", GBP: "£", ZAR: "R", AUD: "A$", CAD: "C$", 
    JPY: "¥", CNY: "¥", CHF: "Fr", NZD: "$", SGD: "S$", HKD: "HK$", 
    INR: "₹", BRL: "R$", MXN: "$", SEK: "kr", NOK: "kr", RUB: "₽", 
    KRW: "₩", PLN: "zł", TRY: "₺", THB: "฿", IDR: "Rp", MYR: "RM", 
    PHP: "₱", DKK: "kr", HUF: "Ft", CZK: "Kč", ILS: "₪", AED: "د.إ", 
    SAR: "﷼", CLP: "$", COP: "$", PEN: "S/.", PKR: "₨", VND: "₫", 
    BDT: "৳", NGN: "₦", EGP: "£", QAR: "﷼", KWD: "د.ك"
  };

  const getCurrencySymbol = (code) => currencySymbols[code] || code;
  const currencySymbol = getCurrencySymbol(currencyCode);

  // Convert ZAR amount to selected currency
  const convertToCurrency = (amountInZAR) => {
    const rate = exchangeRates[currencyCode] || 1;
    return (amountInZAR * rate).toFixed(2);
  };

  // Debounced function for fetching cart details
  const debouncedFetchProductDetails = debounce(async (cart) => {
    try {
      setIsFetching(true);
      const productIds = cart.map((item) => item.productId);
      if (productIds.length > 0) {
        const products = await fetchProducts(productIds);
        const updatedCart = cart.map((item) => {
          const product = products.find((p) => p.id === item.productId);
          return {
            ...item,
            name: product?.name || "Unknown",
            price: product?.sale ? product.salePrice : product.price,
            image: product?.image || "",
            link: `/single-product/${product?.id || item.productId}`,
          };
        });
        setFullCart(updatedCart);
      } else {
        setFullCart([]);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setIsFetching(false);
    }
  }, 300);

  useEffect(() => {
    if (JSON.stringify(prevCartRef.current) !== JSON.stringify(cart)) {
      prevCartRef.current = cart;
      debouncedFetchProductDetails(cart);
    }
  }, [cart]);

  const handleMenuToggle = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);
  const cartItemCount = fullCart.reduce((total, item) => total + item.quantity, 0);

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const handleCurrencyChange = (e) => {
    updateCurrencyCode(e.target.value);
  };

  const handleDashboardNavigation = () => {
    if (
      auth.role === "admin" ||
      auth.role === "moderator" ||
      auth.role === "guest blogger"
    ) {
      navigate("/admin");
    } else {
      navigate("/profile");
    }
  };

  const navigateToCart = () => {
    navigate("/cart");
  };

  return (
    <div>
      <header
        id="site-header"
        className="site-header header-style-2 header-fullwidth sticky-header header-static"
      >
        <div className="header-topbar">
          <div className="octf-area-wrap">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <ul className="topbar-info">
                    <li>
                      <i className="fas fa-envelope"></i>
                      <Link to="mailto:info@toris.co.za">
                        {" "}
                        info@toris.co.za
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i> Mon - Fri: 8:00 am - 5:00
                      pm
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-right">
                  <div className="topbar-right">
                    <ul className="extra-text">
                      <li className="hire-us-link">
                        We are creative, ambitious and ready for challenges!{" "}
                        <Link to="/contact">Hire Us</Link>
                      </li>
                    </ul>
                    <ul className="social-list">
                      <li>
                        <Link
                          to="https://www.facebook.com/TORIS.Tech"
                          target="_self"
                          aria-label="Facebook"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/42849416/"
                          target="_self"
                          aria-label="LinkedIn"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/toris.technologies/"
                          target="_self"
                          aria-label="Instagram"
                        >
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="octf-main-header">
          <div className="octf-area-wrap">
            <div className="container-fluid octf-mainbar-container">
              <div className="octf-mainbar">
                <div className="octf-mainbar-row octf-row">
                  <div className="octf-col logo-col">
                    <div id="site-logo" className="site-logo">
                      <Link to="/">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/resized-logo-250x250.webp`}
                          alt="TORIS Technologies"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="octf-col menu-col">
                    <nav id="site-navigation" className="main-navigation">
                      <ul className="menu">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item-has-children">
                          Company
                          <ul className="sub-menu">
                            <li>
                              <Link to="/about">About us</Link>
                            </li>
                            <li>
                              <Link to="/why-choose-us">Why Choose Us</Link>
                            </li>
                            <li>
                              <Link to="/projects">Projects</Link>
                            </li>
                            <li>
                              <Link to="/team">Our Team</Link>
                            </li>
                            <li>
                              <Link to="/faq">FAQS</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          Services
                          <ul className="sub-menu">
                            <li>
                              <Link to="/software-integrations">
                                Software Integrations
                              </Link>
                            </li>
                            <li>
                              <Link to="/web-development">Web Development</Link>
                            </li>
                            <li>
                              <Link to="/mobile-development">
                                Mobile Development
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contacts</Link>
                        </li>
                        <li>
                          <Link to="/events">Events</Link>
                        </li>
                        <li>
                          <Link to="/shop">Shop</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="octf-col cta-col text-right">
                    <div className="octf-btn-cta">
                      <div className="octf-header-module">
                        <div className="currency-selector">
                          <label htmlFor="currency">Currency:</label>
                          <select
                            id="currency"
                            value={currencyCode}
                            onChange={handleCurrencyChange}
                          >
                            <option value="USD">USD - $</option>
                            <option value="EUR">EUR - €</option>
                            <option value="GBP">GBP - £</option>
                            <option value="ZAR">ZAR - R</option>
                            <option value="AUD">AUD - A$</option>
                            <option value="CAD">CAD - C$</option>
                            <option value="JPY">JPY - ¥</option>
                            <option value="CNY">CNY - ¥</option>
                            <option value="CHF">CHF - Fr</option>
                            <option value="NZD">NZD - $</option>
                            <option value="SGD">SGD - S$</option>
                            <option value="HKD">HKD - HK$</option>
                            <option value="INR">INR - ₹</option>
                            <option value="BRL">BRL - R$</option>
                            <option value="MXN">MXN - $</option>
                            <option value="SEK">SEK - kr</option>
                            <option value="NOK">NOK - kr</option>
                            <option value="RUB">RUB - ₽</option>
                            <option value="KRW">KRW - ₩</option>
                            <option value="PLN">PLN - zł</option>
                            <option value="TRY">TRY - ₺</option>
                            <option value="THB">THB - ฿</option>
                            <option value="IDR">IDR - Rp</option>
                            <option value="MYR">MYR - RM</option>
                            <option value="PHP">PHP - ₱</option>
                            <option value="DKK">DKK - kr</option>
                            <option value="HUF">HUF - Ft</option>
                            <option value="CZK">CZK - Kč</option>
                            <option value="ILS">ILS - ₪</option>
                            <option value="AED">AED - د.إ</option>
                            <option value="SAR">SAR - ﷼</option>
                            <option value="CLP">CLP - $</option>
                            <option value="COP">COP - $</option>
                            <option value="PEN">PEN - S/.</option>
                            <option value="PKR">PKR - ₨</option>
                            <option value="VND">VND - ₫</option>
                            <option value="BDT">BDT - ৳</option>
                            <option value="NGN">NGN - ₦</option>
                            <option value="EGP">EGP - £</option>
                            <option value="QAR">QAR - ﷼</option>
                            <option value="KWD">KWD - د.ك</option>
                          </select>
                        </div>
                      </div>
                      {auth.isAuthenticated && (
                        <div className="octf-header-module cart-btn-hover">
                          <div className="h-cart-btn octf-cta-icons">
                            <button
                              onClick={navigateToCart}
                              className="cart-icon-button"
                            >
                              <i className="flaticon-shopper"></i>
                              <span className="cart-count">
                                {cartItemCount}
                              </span>
                            </button>
                          </div>
                          <div className="site-header-cart">
                            <div className="widget woocommerce widget_shopping_cart">
                              <div className="widget_shopping_cart_content">
                                {fullCart.length === 0 ? (
                                  <p className="woocommerce-mini-cart__empty-message">
                                    No products in the cart.
                                  </p>
                                ) : (
                                  <ul className="cart_list">
                                    {fullCart.map((item) => (
                                      <li
                                        key={item.productId}
                                        className="clearfix"
                                      >
                                        <button
                                          onClick={() =>
                                            handleRemoveFromCart(item.productId)
                                          }
                                          className="remove modern-remove-button"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                        <div className="thumb">
                                          <Link to={item.link}>
                                            <img
                                              src={item.image}
                                              alt={item.name}
                                            />
                                          </Link>
                                        </div>
                                        <div className="entry-header">
                                          <h6>
                                            <Link to={item.link}>
                                              {item.name}
                                            </Link>
                                          </h6>
                                          <span className="price-product">
                                            <span className="quantity">
                                              {item.quantity} ×{" "}
                                            </span>
                                            <span className="amount">
                                              <span>{currencySymbol}</span>
                                              {convertToCurrency(item.price)}
                                            </span>
                                          </span>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                                {fullCart.length > 0 && (
                                  <p className="woocommerce-mini-cart__total total">
                                    <strong>Total:</strong>{" "}
                                    <span className="amount">
                                      {currencySymbol}{" "}
                                      {convertToCurrency(
                                        fullCart.reduce(
                                          (total, item) => total + item.price * item.quantity,
                                          0
                                        )
                                      )}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="octf-header-module auth-icon-module">
                        <div className="auth-icon-container">
                          {auth.isAuthenticated ? (
                            <>
                              <span className="username">
                                Welcome, {auth.username || "User"}!
                              </span>
                              <button
                                onClick={handleDashboardNavigation}
                                className="dashboard-button"
                                aria-label="Go to Dashboard"
                              >
                                <MdDashboard className="dashboard-icon" />
                              </button>
                              <button
                                onClick={logout}
                                className="auth-button"
                                aria-label="Logout"
                              >
                                <FaSignOutAlt className="auth-icon" />
                              </button>
                            </>
                          ) : (
                            <>
                              <span className="login-text">Login</span>
                              <button
                                onClick={() => navigate("/login")}
                                className="auth-button"
                                aria-label="Login"
                              >
                                <FaSignInAlt className="auth-icon" />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-header">
          <div className="mobile-header-container">
            <div className="mobile-logo">
              <Link to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/toris-logo-transparent-250x250.webp`}
                  alt="TORIS Technologies"
                />
              </Link>
            </div>
            <div className="mobile-menu-toggle" onClick={handleMenuToggle}>
              <button
                className="menu-toggle-icon"
                aria-label={menuOpen ? "Close menu" : "Open menu"}
              >
                <span className="menu-bar top-bar"></span>
                <span className="menu-bar middle-bar"></span>
                <span className="menu-bar bottom-bar"></span>
              </button>
            </div>
          </div>

          <div className={`mobile-menu-wrapper ${menuOpen ? "open" : ""}`}>
            <div className="mobile-nav">
              <ul className="mobile-menu">
                {/* Conditional rendering for Login/Logout in mobile menu */}
                <li onClick={closeMenu} className="menu-item">
                  {auth.isAuthenticated ? (
                    <>
                      <Link
                        onClick={logout}
                        className="auth-button-mobile"
                        aria-label="Logout"
                      >
                        Logout
                      </Link>
                    </>
                  ) : (
                    <Link
                      to="/login"
                      className="auth-button-mobile"
                      aria-label="Login"
                    >
                      Login
                    </Link>
                  )}
                </li>

                <li className="menu-item" onClick={closeMenu}>
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item-has-children menu-item">
                  Company
                  <ul className="sub-menu">
                    <li onClick={closeMenu}>
                      <Link to="/about">About us</Link>
                    </li>
                    <li onClick={closeMenu}>
                      <Link to="/why-choose-us">Why Choose Us</Link>
                    </li>
                    <li onClick={closeMenu}>
                      <Link to="/team">Team</Link>
                    </li>
                    <li onClick={closeMenu}>
                      <Link to="/faq">FAQS</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children menu-item">
                  Services
                  <ul className="sub-menu">
                    <li onClick={closeMenu}>
                      <Link to="/web-development">Web Development</Link>
                    </li>
                    <li onClick={closeMenu}>
                      <Link to="/mobile-development">Mobile Development</Link>
                    </li>
                    <li onClick={closeMenu}>
                      <Link to="/software-integrations">
                        Software Integrations
                      </Link>
                    </li>
                  </ul>
                </li>
                <li onClick={closeMenu} className="menu-item">
                  <Link to="/projects">Projects</Link>
                </li>
                <li onClick={closeMenu} className="menu-item">
                  <Link to="/blog">Blog</Link>
                </li>
                <li onClick={closeMenu} className="menu-item">
                  <Link to="/contact">Contacts</Link>
                </li>
                <li onClick={closeMenu} className="menu-item">
                  <Link to="/shop">Shop</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

import axios from "./axiosConfig"; 

const API_URL = process.env.REACT_APP_API_URL;

// Fetch all marketing emails
export const fetchMarketingEmails = async () => {
  try {
    const response = await axios.get(`${API_URL}/marketing-emails`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching marketing emails');
  }
};

// Add a new marketing email
export const addMarketingEmail = async (emailData) => {
  try {
    const response = await axios.post(`${API_URL}/marketing-emails`, emailData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding marketing email');
  }
};

// Update an existing marketing email
export const updateMarketingEmail = async (id, emailData) => {
  try {
    const response = await axios.put(`${API_URL}/marketing-emails/${id}`, emailData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating marketing email');
  }
};

// Delete a marketing email
export const deleteMarketingEmail = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/marketing-emails/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting marketing email');
  }
};

export const sendTestEmail = async (emailData, recipientEmail) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/send-test-email`,
      {
        emailData: emailData, // Send the emailData object
        recipientEmail: recipientEmail,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in sendTestEmail:", error);
    throw new Error("Failed to send test email");
  }
};

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { fetchEvents } from "../utils/eventService";
import "./Events.css";
import useBackToTop from "../utils/useBackToTop";

const Events = () => {
  useBackToTop(500, true);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const eventsPerPage = 10;

  // Fetch all events
  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchEvents();
        setEvents(data.events);
        setFilteredEvents(data.events); // Initially, show all events
        setTotalPages(Math.ceil(data.events.length / eventsPerPage));
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    loadEvents();
  }, []);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  // Format date function
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <Helmet>
        <title>Events - TORIS Technologies</title>
        <meta
          name="description"
          content="Stay updated with the latest events from TORIS Technologies. Explore our upcoming events, workshops, and seminars."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies Events, workshops, seminars, tech events"
        />
        <link rel="canonical" href="https://toris.co.za/events" />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Events</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Events</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="events-grid pgrid">
                <div className="row">
                  {filteredEvents
                    .slice(
                      (currentPage - 1) * eventsPerPage,
                      currentPage * eventsPerPage
                    )
                    .map((event) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        key={event.id}
                      >
                        <article className="post-box event-item">
                          <div className="post-inner">
                            <div className="entry-media">
                              <div className="post-cat">
                                <span className="posted-in">
                                  <Link to="#" rel="category tag">
                                    {event.category}
                                  </Link>
                                </span>
                              </div>
                              <Link to={`/event/${event.id}`}>
                                <img
                                  src={`${process.env.REACT_APP_FRONTEND_URL}${event.image_url}`}
                                  alt={event.title}
                                />
                              </Link>
                            </div>
                            <div className="inner-post">
                              <div className="entry-header">
                                <div className="entry-meta">
                                  <span className="posted-on">
                                    <Link to="#">
                                      {formatDate(event.event_date)}
                                    </Link>
                                  </span>
                                  <span className="byline">
                                    {" "}
                                    |{" "}
                                    <Link className="url fn n" to="#">
                                      {event.organizer}
                                    </Link>
                                  </span>
                                </div>
                                <h3 className="entry-title">
                                  <Link to={`/event/${event.id}`}>
                                    {event.title}
                                  </Link>
                                </h3>
                              </div>
                              <div className="btn-readmore">
                                <Link to={`/event/${event.id}`}>
                                  <i className="flaticon-right-arrow-1"></i>
                                  LEARN MORE
                                </Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    ))}
                </div>
              </div>
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={index + 1 === currentPage ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

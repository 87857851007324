import React from 'react';
import { Link } from 'react-router-dom';

const SharePost = ({ postUrl, postTitle, postDescription, postImageUrl }) => {
  return (
    <div className="share-post">
      {/* Twitter */}
      <Link
        className="twit"
        target="_blank"
        to={`https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(postTitle)}`}
        title="Share on Twitter"
      >
        <i className="fab fa-twitter"></i>
      </Link>

      {/* Facebook */}
      <Link
        className="face"
        target="_blank"
        to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`}
        title="Share on Facebook"
      >
        <i className="fab fa-facebook-f"></i>
      </Link>

      {/* Pinterest */}
      <Link
        className="pint"
        target="_blank"
        to={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(postUrl)}&description=${encodeURIComponent(postDescription)}&media=${encodeURIComponent(postImageUrl)}`}
        title="Share on Pinterest"
      >
        <i className="fab fa-pinterest-p"></i>
      </Link>

      {/* LinkedIn */}
      <Link
        className="linked"
        target="_blank"
        to={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(postUrl)}&title=${encodeURIComponent(postTitle)}&summary=${encodeURIComponent(postDescription)}`}
        title="Share on LinkedIn"
      >
        <i className="fab fa-linkedin-in"></i>
      </Link>
    </div>
  );
};

export default SharePost;

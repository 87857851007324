import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import LatestBlogs from "../components/LatestBlogs/LatestBlogs";
import "./WhyChooseUs.css";
import SectionProjects from "../components/SectionProjects/SectionProjects";
import useBackToTop from "../utils/useBackToTop";

const WhyChooseUs = () => {
  useBackToTop(500, true);
  return (
    <div id="content" className="site-content">
      <Helmet>
        <title>Why Choose Us | TORIS Technologies</title>
        <meta
          name="description"
          content="Discover why TORIS Technologies is the preferred choice for businesses worldwide. Our expertise in custom software development, product design, QA, and consulting services sets us apart."
        />
        <meta
          name="keywords"
          content="why choose us, TORIS Technologies, software development, product design, IT consulting, QA services"
        />
        <meta name="author" content="TORIS Technologies" />
        <link rel="canonical" href="https://toris.co.za/why-choose-us" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Why Choose Us | TORIS Technologies"
        />
        <meta
          property="og:description"
          content="Explore why leading enterprises choose TORIS Technologies for software innovation, from custom development to comprehensive consultancy services."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/about/why-choose-us.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/why-choose-us" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Why Choose Us | TORIS Technologies"
        />
        <meta
          name="twitter:description"
          content="Find out why TORIS Technologies is trusted by businesses worldwide for innovative software solutions, IT consultancy, and more."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/about/why-choose-us.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <div className="page-header flex-middle">
        <div className="container">
          <div className="inner flex-middle">
            <h1 className="page-title">Why Choose Us</h1>
            <ul id="breadcrumbs" className="breadcrumbs none-style">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Why Choose Us</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="why-choose-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/man1.png`}
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="why-right">
                <div className="ot-heading">
                  <span>About company</span>
                  <h2 className="main-heading">
                    Your Partner for <br /> Software Innovation
                  </h2>
                </div>
                <p className="mb-15">
                  Toris Technologies is the partner of choice for many of the
                  world’s leading enterprises, SMEs and technology challengers.
                  We help businesses elevate their value through custom software
                  development, product design, QA and consultancy services.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="icon-box-s1">
                      <div className="icon-main">
                        <span className="flaticon-medal"></span>
                      </div>
                      <h5>Experience</h5>
                      <div className="line-box"></div>
                      <p>Our team has written millions of lines of code!</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="icon-box-s1">
                      <div className="icon-main">
                        <span className="flaticon-gear"></span>
                      </div>
                      <h5>Innovative Ideas</h5>
                      <div className="line-box"></div>
                      <p>We’ll help you test bold new ideas.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="s-counter4 why">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                    <div className="ot-counter text-white">
                      <div>
                        <span className="num" data-to="1500" data-time="2000">
                          0
                        </span>
                        <span>+</span>
                      </div>
                      <h6 className="text-white">Consultations Provided</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                    <div className="ot-counter text-white">
                      <div>
                        <span className="num" data-to="24000" data-time="2000">
                          0
                        </span>
                        <span>+</span>
                      </div>
                      <h6 className="text-white">Development Hours</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-sm-0">
                    <div className="ot-counter text-white">
                      <div>
                        <span className="num" data-to="120" data-time="2000">
                          0
                        </span>
                        <span>+</span>
                      </div>
                      <h6 className="text-white">Industries Served</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 text-center">
                    <div className="ot-counter text-white">
                      <div>
                        <span className="num" data-to="15" data-time="2000">
                          0
                        </span>
                        <span>+</span>
                      </div>
                      <h6 className="text-white">Glorious Years</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-110"></div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="ot-heading">
                <span>Our Services</span>
                <h2 className="main-heading">
                  We Offer a Wide <br />
                  Variety of development services
                </h2>
              </div>
            </div>
          </div>
          <div className="space-30"></div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="icon-box-s2 s2 border-s1 bg bg1 text-center">
                <div className="icon-main">
                  <span className="flaticon-code"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="/web-development">Web Development</Link>
                  </h5>
                  <p>
                    Our web development team excels in crafting solutions
                    tailored to your business needs, ensuring your online
                    presence is both powerful and user-friendly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="icon-box-s2 s2 border-s1 bg bg2 text-center">
                <div className="icon-main">
                  <span className="flaticon-app"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="/mobile-development">Mobile Development</Link>
                  </h5>
                  <p>
                    Develop cutting-edge mobile applications that deliver
                    seamless user experiences across all platforms, enhancing
                    your business’s mobile presence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="icon-box-s2 s2 border-s1 bg bg3 text-center">
                <div className="icon-main">
                  <span className="flaticon-monitor"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="ui-ux-design.html">UI/UX Design</Link>
                  </h5>
                  <p>
                    Design the future of user experiences with our UI/UX team
                    that prioritizes accessibility, intuitiveness, and aesthetic
                    appeal in digital products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mb-md-0">
              <div className="icon-box-s2 s2 border-s1 bg bg4 text-center">
                <div className="icon-main">
                  <span className="flaticon-browser"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="qa-testing.html">QA & Testing</Link>
                  </h5>
                  <p>
                    Ensure the highest quality of software with our
                    comprehensive QA and testing services that detect and
                    rectify any potential issues before they affect your users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mb-md-0">
              <div className="icon-box-s2 s2 border-s1 bg bg5 text-center">
                <div className="icon-main">
                  <span className="flaticon-report-1"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="consultancy.html">Consultancy</Link>
                  </h5>
                  <p>
                    Benefit from our expert consultancy to enhance your
                    technology strategy, optimize processes, and transform your
                    business operations. Use technology to your advantage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box-s2 s2 border-s1 bg bg6 text-center">
                <div className="icon-main">
                  <span className="flaticon-best"></span>
                </div>
                <div className="content-box">
                  <h5>
                    <Link to="process-analysis.html">Process Analysis</Link>
                  </h5>
                  <p>
                    Leverage our decade-long expertise to refine and optimize
                    your business processes, ensuring efficiency and improved
                    performance across operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="technology-v1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ot-heading text-center text-white">
                <span>Technology Index</span>
                <h2 className="main-heading">
                  We Deliver Solution with <br />
                  the Goal of Trusting Relationships
                </h2>
              </div>
            </div>
          </div>
          <div className="space-15"></div>
          <div className="row technology-index-row">
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/web-development">
                <div className="icon-main">
                  <span className="flaticon-code-1"></span>
                </div>
                <h5>WEB</h5>
              </Link>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/mobile-development">
                <div className="icon-main">
                  <span className="flaticon-android"></span>
                </div>
                <h5>Android</h5>
              </Link>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/mobile-development">
                <div className="icon-main">
                  <span className="flaticon-apple"></span>
                </div>
                <h5>IOS</h5>
              </Link>
            </div>
            {/* <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/software-integrations">
                <div className="icon-main">
                  <span className="flaticon-iot"></span>
                </div>
                <h5>IOT</h5>
              </Link>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/software-integrations">
                <div className="icon-main">
                  <span className="flaticon-time-and-date"></span>
                </div>
                <h5>Wearalables</h5>
              </Link>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              <Link className="tech-box text-center" to="/software-integrations">
                <div className="icon-main">
                  <span className="flaticon-tv"></span>
                </div>
                <h5>TV</h5>
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      <section className="news-why">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ot-heading mb-0">
                <span>Our Recent News</span>
                <h2 className="main-heading">Read Our Latest News</h2>
              </div>
            </div>
            <div className="col-md-6 text-right sm-text-left sm-mt-30 align-self-end">
              <div className="ot-button">
                <Link to="/blog" className="octf-btn octf-btn-primary">
                  All News
                </Link>
              </div>
              <div className="space-10"></div>
            </div>
          </div>
          <div className="space-40"></div>

          {/* Display the LatestBlogs component with 3 posts */}
          <LatestBlogs numPosts={3} />
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUs;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import useBackToTop from "../utils/useBackToTop";

const ComingSoon = () => {
  useBackToTop(500, true);
  return (
    <div>
      <Helmet>
        <title>Coming Soon - TORIS Technologies</title>
        <meta
          name="description"
          content="Stay tuned! TORIS Technologies is launching soon. Sign up to receive updates and be the first to know when we go live."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies coming soon, software development, web development, custom software, technology services"
        />
        <link rel="canonical" href="https://toris.co.za/coming-soon" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Coming Soon - TORIS Technologies" />
        <meta
          property="og:description"
          content="Stay tuned! TORIS Technologies is launching soon. Sign up to receive updates and be the first to know when we go live."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/toris-coming-soon-preview.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/coming-soon" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Coming Soon - TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Stay tuned! TORIS Technologies is launching soon. Sign up to receive updates and be the first to know when we go live."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/toris-coming-soon-preview.jpg"
        />
      </Helmet>

      <div id="content" className="site-content">
        <section className="coming-soon">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="cs-content">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo-cs.png`}
                    className="attachment-full size-full"
                    alt=""
                  />
                  <h2>Toris Technologies is in the Works!</h2>
                  <ul
                    className="ot-countdown unstyle"
                    data-zone="0"
                    data-date="11/13/2022"
                    data-day="Day"
                    data-days="Days"
                    data-hour="Hour"
                    data-hours="Hours"
                    data-min="Minute"
                    data-mins="Minutes"
                    data-second="Second"
                    data-seconds="Seconds"
                  >
                    <li>
                      <span className="days">59</span>
                      <p className="days_text">Days</p>
                    </li>
                    <li className="seperator">:</li>
                    <li>
                      <span className="hours">14</span>
                      <p className="hours_text">Hours</p>
                    </li>
                    <li className="seperator">:</li>
                    <li>
                      <span className="minutes">46</span>
                      <p className="minutes_text">Minutes</p>
                    </li>
                    <li className="seperator">:</li>
                    <li>
                      <span className="seconds">21</span>
                      <p className="seconds_text">Seconds</p>
                    </li>
                  </ul>
                  <p>We are about to go live so watch this space!</p>
                  <form method="post" className="wpcf7-form init">
                    <div className="cs-form">
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          name="your-email"
                          value=""
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Your Email *"
                        />
                      </span>
                      <button className="octf-btn">Sign Up</button>
                    </div>
                  </form>
                  <div className="ft-list-icon">
                    <Link
                      className="facebook"
                      to="https://www.facebook.com/TORIS.Tech"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link
                      className="linkedin"
                      to="https://www.linkedin.com/company/42849416/"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                    <Link
                      className="instagram"
                      to="https://www.instagram.com/toris.technologies/"
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ComingSoon;

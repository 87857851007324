// File: ProfileComponents/ProfileSidebar.js
import React, { useState } from 'react';
import { FaShoppingBag, FaUserCircle } from 'react-icons/fa'; // Import profile-specific icons
import './ProfileSidebar.css';

const ProfileSidebar = ({ setSelectedComponent }) => {
  const [activeItem, setActiveItem] = useState('purchasedProducts');

  const handleItemClick = (component) => {
    setActiveItem(component);
    setSelectedComponent(component);
  };

  return (
    <div className="profile-sidebar">
      <ul>
        <li
          className={activeItem === 'purchasedProducts' ? 'active' : ''}
          onClick={() => handleItemClick('purchasedProducts')}
          data-tooltip-id="tooltip-purchased"
          data-tooltip-content="Purchased Products"
        >
          <FaShoppingBag />
        </li>
        <li
          className={activeItem === 'accountInfo' ? 'active' : ''}
          onClick={() => handleItemClick('accountInfo')}
          data-tooltip-id="tooltip-account"
          data-tooltip-content="Account Information"
        >
          <FaUserCircle />
        </li>
      </ul>
    </div>
  );
};

export default ProfileSidebar;

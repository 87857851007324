// ProfileComponents/AccountInfo.js
import React, { useEffect, useState } from 'react';
import axios from "../../../utils/axiosConfig"; 
import { useAuth } from '../../../context/AuthContext';
import './AccountInfo.css';
import { toast } from 'react-toastify';

const AccountInfo = () => {
  const { auth } = useAuth();
  const [user, setUser] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    company_name: '',
    country: '',
    street_address: '',
    postcode: '',
    city: '',
    phone: '',
    accounts_email: '',
  });

  useEffect(() => {
    if (auth.userId) {
      const fetchUserData = async () => {
        try {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${auth.userId}`);
          setUser(userResponse.data);
          setFormData((prevData) => ({
            ...prevData,
            username: userResponse.data.username,
            email: userResponse.data.email,
          }));

          const billingResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${auth.userId}/billing`);
          if (billingResponse.data) {
            setBillingDetails(billingResponse.data);
            setFormData((prevData) => ({
              ...prevData,
              ...billingResponse.data,
            }));
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchUserData();
    }
  }, [auth.userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitBillingInfo = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/${auth.userId}/billing`, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        company_name: formData.company_name,
        country: formData.country,
        street_address: formData.street_address,
        postcode: formData.postcode,
        city: formData.city,
        phone: formData.phone,
        accounts_email: formData.accounts_email,
      });
      alert("Billing information updated successfully");
    } catch (error) {
      console.error("Error updating billing information:", error);
      alert("Failed to update billing information");
    }
  };

  const handleSubmitUserInfo = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/${auth.userId}`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      });
      toast.success("User information updated successfully");
    } catch (error) {
      toast.error("User information failed to update")
      console.error("Error updating user information:", error);
    }
  };

  return (
    <div className="account-info-container">
      <div className="account-card user-card">
        <h2>User Details</h2>
        <form onSubmit={handleSubmitUserInfo}>
          <div className="form-group">
            <label>Username:</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Update Account</button>
          {user && (
            <>
              <p>Account Created: {new Date(user.createdAt).toLocaleDateString()}</p>
              <p>Last Updated: {new Date(user.updatedAt).toLocaleDateString()}</p>
            </>
          )}
        </form>
      </div>

      <div className="account-card billing-card">
        <h2>Billing Details</h2>
        <form onSubmit={handleSubmitBillingInfo} className="billing-form">
          <div className="form-column">
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Company Name:</label>
              <input
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-column">
            <div className="form-group">
              <label>Country:</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Street Address:</label>
              <input
                type="text"
                name="street_address"
                value={formData.street_address}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Postcode:</label>
              <input
                type="text"
                name="postcode"
                value={formData.postcode}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-column">
            <div className="form-group">
              <label>City:</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Accounts Email:</label>
              <input
                type="email"
                name="accounts_email"
                value={formData.accounts_email}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit">Update Billing</button>
          {billingDetails && (
            <>
              <p>Billing Created: {new Date(billingDetails.createdAt).toLocaleDateString()}</p>
              <p>Billing Last Updated: {new Date(billingDetails.updatedAt).toLocaleDateString()}</p>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AccountInfo;

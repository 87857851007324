// utils/formatDateInput.js
export const formatDateForInput = (dateString) => {
  if (!dateString) return ''; // Return empty string if no date provided
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Return in 'yyyy-MM-ddThh:mm:ss' format
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import { Link } from "react-router-dom";
import useBackToTop from "../utils/useBackToTop";

const Register = () => {
  useBackToTop(500, true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, {
        username,
        email,
        password,
      });
      setError("");
      setSuccess("Registration successful! Please verify your email.");
      navigate("/login");
    } catch (error) {
      console.error("Error registering user:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="register-container">
      <Helmet>
        <title>Register | TORIS Technologies</title>
        <meta
          name="description"
          content="Create your TORIS Technologies account to access exclusive services and manage your profile securely."
        />
        <meta name="author" content="TORIS Technologies" />
        <meta
          name="keywords"
          content="TORIS Technologies, register, sign up, account creation, user profile"
        />
        <link rel="canonical" href="https://toris.co.za/register" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Register | TORIS Technologies" />
        <meta
          property="og:description"
          content="Sign up for a new account with TORIS Technologies and start managing your profile today."
        />
        <meta
          property="og:image"
          content="https://toris.co.za/assets/images/register/register-og-image.jpg"
        />
        <meta property="og:url" content="https://toris.co.za/register" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Register | TORIS Technologies" />
        <meta
          name="twitter:description"
          content="Join TORIS Technologies and gain access to exclusive services by creating your account today."
        />
        <meta
          name="twitter:image"
          content="https://toris.co.za/assets/images/register/register-twitter-image.jpg"
        />
        {/* Global site tag (gtag.js) - Google Ads and Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TL4Z93ZY7D"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}
          gtag('js', new Date());

          // Google Ads Measurement ID
          gtag('config', 'G-TL4Z93ZY7D');

          // Google Analytics Measurement ID
          gtag('config', 'G-0Q1R420JPC');
        `}
        </script>
      </Helmet>

      <h2 className="register-title">Register</h2>
      <form className="register-form" onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        <div className="form-group">
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Register
        </button>
        <div className="register-footer-text">
          <p>Already have an account? </p>
          <Link to="/login">Login</Link>
        </div>
      </form>
    </div>
  );
};

export default Register;
